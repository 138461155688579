import axios from 'axios';
import { IDonation } from '../donation/DonationService';
import { zps_vi_url } from '../zps';

const EVENT_DON_BASE_URL = `${zps_vi_url}/eventdonation`;

export type IEventDonationId = {
  eventId: number;
  donation: IDonation;
};

export type IEventDonation = {
  eventDonationId: IEventDonationId;
};

class EventDonationService {
  async getEventDonationEntities() {
    const { data } = await axios.get(EVENT_DON_BASE_URL + 's');
    return data;
  }

  getEventDonationEntity(eventId: number, donationId: number) {
    return axios.get(EVENT_DON_BASE_URL + '/' + eventId + '/' + donationId);
  }

  getEventDonationEntityByEventId(eventId: number) {
    return axios.get(EVENT_DON_BASE_URL + '/' + eventId).then(response => response.data);
  }

  createEventDonationEntity(eventDonation: IEventDonation) {
    return axios.post(EVENT_DON_BASE_URL + '/new', eventDonation);
  }

  deleteEventDonationEntity(id: IEventDonationId) {
    return axios.delete(EVENT_DON_BASE_URL + '/delete/' + id.eventId + '/' + id.donation.donationId);
  }
}

const ExportedEventDonationService = new EventDonationService();

export default ExportedEventDonationService;
