import { set } from 'lodash';
import React, { FormEvent, useEffect, useState } from 'react';
import { Button, Col, Container, Form, InputGroup, Row, Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { fetchLanguages, ILanguage } from '../../../libraries/language';
import { LoginBody } from '../../../libraries/login';
import { fetchRegions } from '../../../libraries/region';
import { fetchPermissions, fetchUser, IUser, PermissionOption } from '../../../libraries/users';
import { AppState } from '../../client-redux/reducers';
import { Language } from '../../languages/languageHandler';
import { preparePermissions } from '../users/permissionsAux';
import './styles.scss';
import UpdateUserConfirmation from './UpdateUserConfirmation';
import * as Icon from 'react-bootstrap-icons';
import Header from '../ui/Header';

const Profile = () => {
  const login = useSelector<AppState, LoginBody | undefined>(state => state.login.user);
  const lang = useSelector<AppState, Language | undefined>(state => state.lang.lang);

  const [openPassword, setOpenPassword] = useState<boolean>(false);
  const [firstPassword, setFirstPassword] = useState<string>();
  const [isInvalidPassword, setIsInvalidPassword] = useState<boolean>(false);
  const [openPin, setOpenPin] = useState<boolean>(false);
  const [firstPin, setFirstPin] = useState<string>();
  const [isInvalidPin, setIsInvalidPin] = useState<boolean>(false);
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
  const [currentUser, setCurrentUser] = useState<IUser>();
  const [currentLang, setCurrentLang] = useState<number>();
  const [currentRegion, setCurrentRegion] = useState<string>();
  const [regions, setRegions] = useState<string[]>([]);
  const [permissions, setPermissions] = useState<PermissionOption[]>([]);
  const [languages, setLanguages] = useState<ILanguage[]>([]);

  useEffect(() => {
    fetchUser(login?.entid, login?.uid).then(userResponse => {
      setCurrentUser(userResponse.users[0]);
      setCurrentLang(userResponse.users[0].lang);
      setCurrentRegion(userResponse.users[0].region);
      fetchPermissions().then(permissionsResponse => {
        preparePermissions(permissionsResponse.list, userResponse.users[0]);
        setPermissions(permissionsResponse.list);
      });
    });

    fetchRegions().then(response => {
      setRegions(response.regions);
    });
    fetchLanguages().then(response => {
      setLanguages(response.languages);
    });
  }, [login?.entid, login?.uid]);

  const handleChange = (fieldPath: string) => (event: any) => {
    if (!currentUser) return;
    if (fieldPath === 'lang') {
      setCurrentLang(event?.target?.value);
      set(currentUser, fieldPath, event?.target?.value);
    }

    if (fieldPath === 'region') {
      setCurrentRegion(event?.target?.value);
      set(currentUser, fieldPath, event?.target?.value);
    }

    if (fieldPath === 'password') {
      setFirstPassword(event?.target?.value);
    }

    if (fieldPath === 'passwordConfirm') {
      if (firstPassword === event.target.value) {
        console.log(firstPassword);
        setIsInvalidPassword(false);
        set(currentUser, 'password', firstPassword);
      } else {
        setIsInvalidPassword(true);
      }
    }

    if (fieldPath === 'pin') {
      setFirstPin(event?.target?.value);
    }

    if (fieldPath === 'pinConfirm') {
      if (firstPin === event.target.value) {
        setIsInvalidPin(false);
        set(currentUser, 'pin', firstPin);
      } else {
        setIsInvalidPin(true);
      }
    }

    set(currentUser, fieldPath, event?.target?.value);

    setCurrentUser(currentUser);
  };

  const togglePassword = () => {
    setOpenPassword(!openPassword);
  };

  const togglePin = () => {
    setOpenPin(!openPin);
  };

  const closeModal = () => {
    setOpenConfirmation(false);
  };

  function submitEditForm(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    setOpenConfirmation(true);
  }

  return (
    <Container fluid>
      {openConfirmation && currentUser && lang && (
        <>
          <UpdateUserConfirmation
            lang={lang}
            userData={currentUser}
            toggleModal={closeModal}
            openModal={openConfirmation}
          />
        </>
      )}
      <Header pageTitle={lang?.USER_MY_PROFILE} />
      <Form className="profile-page-form" onSubmit={submitEditForm}>
        {/* <div className="profile-page-title-section">
          <SidebarButton/>
          <h4 className="profile-page-title">
            <strong>{lang?.USER_MY_PROFILE}</strong>
          </h4>
        </div> */}
        <Row xs={1}>
          <Col
            xl={{ span: 8, offset: 2 }}
            lg={{ span: 10, offset: 1 }}
            md={{ span: 10, offset: 1 }}
            sm={{ span: 10, offset: 1 }}
            xs="auto"
            className="profile-page-content-section mb-4"
          >
            <Row fluid className="profile-page-left-info-section d-flex justify-content-center mb-4">
              <Row xs={1} className="profile-page-title w-100">
                <h5>{lang?.USER_INFO}</h5>
              </Row>
              <Row xs={1} lg={2} className="mb-4">
                <Col xs={12} sm={4} lg={2} className="mt-4 mb-2">
                  <Form.Label>{lang?.USER_ENTITY}</Form.Label>
                </Col>
                <Col xs={12} lg={4} className="mb-2 d-flex align-items-end">
                  <Form.Control value={currentUser?.entid} name="entid" disabled />
                </Col>
                <Col xs={12} lg={1} className="mb-2 d-flex align-items-end">
                  <Form.Label>ID</Form.Label>
                </Col>
                <Col xs={12} lg={5} className="mb-2 d-flex align-items-end">
                  <Form.Control value={currentUser?.uid} name="uid" disabled />
                </Col>
              </Row>
              <Row xs={1} lg={2} className="mb-2">
                <Col xs={12} lg={2} className="mb-2">
                  <Form.Label>{lang?.GLOBAL_NAME}</Form.Label>
                </Col>
                <Col xs={12} lg={10} className="mb-2">
                  <Form.Control defaultValue={currentUser?.name} name="name" onChange={handleChange('name')} />
                </Col>
              </Row>
              <Row xs={12} lg={2} className="mt-2">
                <Col lg={2} className="mb-2 d-flex align-items-end">
                  <Form.Label>{lang?.USER_MOBILE}</Form.Label>
                </Col>
                <Col xs={6} lg={4} className="mb-2 d-flex align-items-end">
                  <Form.Control defaultValue={currentUser?.mobile} name="mobile" onChange={handleChange('mobile')} />
                </Col>
                <Col xs={12} lg={1} className="mb-2 d-flex align-items-end">
                  <Form.Label>{lang?.USER_EMAIL}</Form.Label>
                </Col>
                <Col xs={12} lg={5} className="mb-2 d-flex align-items-end">
                  <Form.Control
                    defaultValue={currentUser?.email}
                    type="email"
                    name="email"
                    onChange={handleChange('email')}
                  />
                </Col>
              </Row>
              <Row xs={1} lg={2} className="mt-2">
                <Col xs={12} lg={2} className="mb-2 d-flex align-items-end">
                  <Form.Label>{lang?.USER_LANG}</Form.Label>
                </Col>
                <Col xs={12} lg={4} className="mb-2 d-flex align-items-end">
                  <Form.Select name="lang" value={currentLang} onChange={handleChange('lang')}>
                    {languages?.map(language => (
                      <option value={String(language.langid)}>{language.nativeName}</option>
                    ))}
                  </Form.Select>
                </Col>
                <Col xs={12} lg={1} className="mb-2 d-flex align-items-end">
                  <Form.Label>{lang?.USER_REGION}</Form.Label>
                </Col>
                <Col xs={12} lg={5} className="mb-2 d-flex align-items-end">
                  <Form.Select name="region" value={currentRegion} onChange={handleChange('region')}>
                    {regions?.map(region => (
                      <option value={region}>{region}</option>
                    ))}
                  </Form.Select>
                </Col>
              </Row>
            </Row>
            <Row md={2}>
              <Col xs={12} md={5} lg={5} className="profile-page-left-password-section mb-4">
                <Row className="profile-page-title">
                  <h5>Password</h5>
                </Row>
                <Row className="mt-4">
                  {!openPassword && (
                    <Col lg={8}>
                      <Button className="change-password-btn" onClick={togglePassword}>
                        {lang?.USER_CHANGE_PASSWORD}
                      </Button>
                    </Col>
                  )}
                  {openPassword && (
                    <>
                      <Col lg={8}>
                        <Form.Control
                          type="password"
                          name="password"
                          placeholder={lang?.USER_NEW_PASSWORD}
                          onChange={handleChange('password')}
                        />
                        <InputGroup hasValidation>
                          <Form.Control
                            className="password-input"
                            type="password"
                            name="passwordConfirm"
                            placeholder={lang?.USER_CONFIRM_PASSWORD}
                            isInvalid={isInvalidPassword}
                            onChange={handleChange('passwordConfirm')}
                          />
                          <Form.Control.Feedback type="invalid">Passwords devem ser idênticas</Form.Control.Feedback>
                        </InputGroup>
                      </Col>
                      <Col className="password-section-close">
                        <Button className="password-section-close-btn" onClick={togglePassword}>
                          <Icon.ArrowBarUp />
                        </Button>
                      </Col>
                    </>
                  )}
                </Row>
              </Col>
              <Col
                xs={12}
                md={{ span: 5, offset: 2 }}
                lg={{ span: 5, offset: 2 }}
                className="profile-page-left-pin-section mb-4"
              >
                <Row className="profile-page-title">
                  <h5>PIN</h5>
                </Row>
                <Row className="mt-4">
                  {!openPin && (
                    <Col lg={8}>
                      <Button className="change-password-btn" onClick={togglePin}>
                        {lang?.USER_CHANGE_PIN}
                      </Button>
                    </Col>
                  )}
                  {openPin && (
                    <>
                      <Col lg={8}>
                        <Form.Control
                          type="password"
                          placeholder={lang?.USER_NEW_PIN}
                          name="pin"
                          onChange={handleChange('pin')}
                        />
                        <InputGroup hasValidation>
                          <Form.Control
                            className="password-input"
                            type="password"
                            name="pinConfirm"
                            placeholder={lang?.USER_CONFIRM_PIN}
                            onChange={handleChange('pinConfirm')}
                            isInvalid={isInvalidPin}
                          />
                          <Form.Control.Feedback type="invalid">Códigos devem ser idênticos</Form.Control.Feedback>
                        </InputGroup>
                      </Col>
                      <Col className="password-section-close">
                        <Button className="password-section-close-btn" onClick={togglePin}>
                          <Icon.ArrowBarUp />
                        </Button>
                      </Col>
                    </>
                  )}
                </Row>
              </Col>
            </Row>
            <Row className="profile-page-left-permissions-section">
              <div className="profile-page-title">
                <h5>{lang?.USER_PERMISSIONS}</h5>
              </div>
              <div className="permissionsRow mt-4">
                {permissions &&
                  lang &&
                  permissions
                    .filter(option => option.selected)
                    .map(po => {
                      return (
                        <Form.Group as={Col} controlId="formGridPermissions" key={po.langKey}>
                          <Form.Check type="checkbox" checked={po.selected} label={lang[po.langKey]} readOnly />
                        </Form.Group>
                      );
                    })}
              </div>
            </Row>
          </Col>
        </Row>
        <div className="profile-page-right" hidden>
          <div className="profile-page-right-delegation-section">
            <div className="profile-page-title">
              <h5>{lang?.USER_DELEGATIONS}</h5>
            </div>
            <Table className="mt-4">
              <thead className="delegation-table-header">
                <tr className="table-row">
                  <th>SI</th>
                  <th>{lang?.GLOBAL_NAME}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={12} style={{ textAlign: 'center' }}>
                    {lang?.GLOBAL_ALL}
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
        <div className="profile-page-button-section">
          <div className="left-button">
            <Button type="submit" variant="success">
              {lang?.GLOBAL_SAVE_BUTTON}
            </Button>
          </div>
          <div className="right-button">
            <Button variant="danger">{lang?.GLOBAL_DELETE_BUTTON}</Button>
          </div>
        </div>
      </Form>
    </Container>
  );
};

export default Profile;
