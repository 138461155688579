import React, { useState } from 'react';
import EventAdService, { IEventAd, IEventAdId } from '../../../libraries/eventadvertising/EventAdService';
import { Container, Table, Row, Col, Modal, Button, Ratio, Image } from 'react-bootstrap';
import './styles.scss';
import { useMutation, useQueryClient } from 'react-query';
import { Language } from '../../languages/languageHandler';

interface EventAdProps {
  adsList: IEventAd[] | undefined;
  eventId: number;
  hideBtn: () => void;
  addBtn: () => void;
  lang: Language;
}

const EventAd = ({ adsList, eventId, hideBtn, addBtn, lang }: EventAdProps) => {
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation(EventAdService.deleteEventAd);

  const [activeRow, setActiveRow] = useState<IEventAdId | null>(null);
  const [isSelected, setIsSelected] = useState<boolean>(false);

  const handleToggle =
    (eventAdId: IEventAdId): (() => void) =>
    () => {
      setActiveRow(eventAdId);
      setIsSelected(!isSelected);
    };

  const isActive = (eventAdId: IEventAdId): boolean => {
    if (!activeRow) return false;

    return eventAdId.eventId === activeRow.eventId && eventAdId.ad.adId === activeRow.ad.adId;
  };

  function mediaHandler(filename: string, file: string) {
    const extension: string = filename.split('.')[1];

    if (extension === 'jpeg' || extension === 'jpg' || extension === 'png') {
      return (
        <Ratio aspectRatio="16x9">
          <Image id="table-video" className="align-middle" src={`data:image/${extension};base64,${file}`} />
        </Ratio>
      );
    } else if (extension === 'mp4') {
      return (
        <Ratio aspectRatio="16x9">
          <video id="table-video" src={`data:video/webm;base64,${file}`} controls muted />
        </Ratio>
      );
    }
  }

  const deleteEventAd = async (activeRow: IEventAdId | null) => {
    if (activeRow !== null && window.confirm('Are you certain you wish to remove this advertisement?')) {
      await mutateAsync({
        eventId: eventId,
        ad: activeRow.ad
      });

      queryClient.invalidateQueries('eventAds');

      setActiveRow(null);
    }
  };

  return (
    <>
      <Container>
        <Row className="mb-2">
          <Col>
            <div className="event-modal-heading">
              <h5>{lang.ADVERTISEMENTS}</h5>
            </div>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <div className="event-details-table">
              <Table size="sm">
                <thead>
                  <tr>
                    <th>{lang.ADVERTISING}</th>
                    <th>{lang.EVENT_AD_ORDER}</th>
                  </tr>
                </thead>
                <tbody>
                  {adsList?.map(eventAd => (
                    <tr
                      className={`table-row ${isActive(eventAd.eventAdId) ? 'activeRow' : ''}`}
                      onClick={handleToggle(eventAd.eventAdId)}
                    >
                      <td>
                        <div className="eventAd-container">
                          {mediaHandler(eventAd.eventAdId.ad.fileName, eventAd.eventAdId.ad.file)}
                        </div>
                      </td>
                      <td className="align-middle">{eventAd.adOrder}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
      </Container>
      <Modal.Footer>
        <Button variant="success" onClick={addBtn}>
          {lang.GLOBAL_ADD_BUTTON}
        </Button>
        <Button variant="danger" onClick={() => deleteEventAd(activeRow)}>
          {lang.GLOBAL_DELETE_BUTTON}
        </Button>
        <Button variant="secondary" onClick={hideBtn}>
          {lang.GLOBAL_RETURN_BUTTON}
        </Button>
      </Modal.Footer>
    </>
  );
};
export default EventAd;
