import { ApiResponse, StatusOper, zps_ea_war } from '../zps';
import axios from 'axios';

const USER_BASE_URL = `${zps_ea_war}/user`;

export interface PermissionOption {
  appId: number;
  mask: number;
  langKey: string; //To be used on lang[option.langKey]
  selected?: boolean; //Aux field used on UI
}

export interface PermissionOptionResponse extends ApiResponse {
  list: PermissionOption[];
}

export interface Permission {
  appId: number;
  permissionCode: number;
}

export interface IUser {
  gid?: number;
  entid: number;
  uid: number;
  extentid?: number;
  type?: number;
  name: string;
  permissionCode?: number;
  printType?: number;
  reportType?: number;
  inUse?: number;
  region: string;
  lang: number;
  permissions?: Permission[];
  email: string;
  mobile: string;
  card: string;
  password?: string;
  pin?: string;
}

export interface IUserUpdate {
  user: IUser;
  currentPassword?: string; //TODO: validar nome campo
  deletePin?: boolean;
}

export interface IUserResponse {
  statusOper: ApiResponse;
  totalRows: number;
  type: string;
  users: IUser[];
}

export interface IEntityResponse extends StatusOper {
  entities: IEntity[];
  type: string;
}

export interface IEntity {
  entid: number;
  gid: number;
  lang: number;
  name: string;
  ownerid: number;
  printType: number;
  region: string;
  reportType: number;
  type: number;
  usePos: number;
  useShifts: number;
}

export const fetchUser = (entid?: string, uid?: string) => {
  return axios.get(USER_BASE_URL + '?entid=' + entid + '&uid=' + uid).then(response => response.data);
};

export const fetchUsers = (
  entid?: string,
  limit?: number,
  offset?: number,
  totalRows?: string,
  sid?: string,
  name?: string,
  uid?: string
) => {
  return axios
    .get(
      USER_BASE_URL +
        '?entid=' +
        entid +
        '&offset=' +
        offset +
        '&limit=' +
        limit +
        '&totalRows=' +
        totalRows +
        (sid ? '&sid=' + sid : '') +
        (name ? '&name=' + name : '') +
        (uid ? '&uid=' + uid : '')
    )
    .then(response => response.data);
};

export const createUser = (user: IUser) => {
  return axios.post(USER_BASE_URL, user);
};

export const deleteUser = (uid: number) => {
  return axios.delete(`${USER_BASE_URL}/?uid=${uid}`);
};

export const updateUser = (user: IUserUpdate) => {
  return axios.put(USER_BASE_URL, user);
};

export const fetchPermissions = () => {
  return axios.get(`${USER_BASE_URL}/permission/options`).then(response => response.data);
};

export const fetchEntities = async () => {
  const { data } = await axios.get(`${zps_ea_war}/entity/`);

  return data;
};
