import React, { FormEvent, useEffect, useState } from 'react';
import { Modal, Form, Col, Button } from 'react-bootstrap';
import { useMutation, useQueryClient } from 'react-query';
import { fetchDonationWithoutImageList, IDonation } from '../../../libraries/donation/DonationService';
import EventDonationService from '../../../libraries/eventdonation/EventDonationService';
import { Language } from '../../languages/languageHandler';
import './styles.scss';

interface FormProps {
  cancelBtn: () => void;
  eventId: number;
  lang: Language;
}

const EventDonationForm = ({ cancelBtn, eventId, lang }: FormProps) => {
  const [donationEntities, setDonationEntities] = useState<IDonation[]>([]);

  useEffect(() => {
    fetchDonationWithoutImageList().then(response => {
      setDonationEntities(response.list);
    });
  }, []);

  const queryClient = useQueryClient();

  const submition = useMutation(EventDonationService.createEventDonationEntity);

  async function submitEventDonation(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (donationEntities.length === 0) return;

    const { eventId, donationId } = e.target as typeof e.target & {
      eventId: { value: number };
      donationId: { value: number };
      name: { value: string };
      logo: { value: string };
    };

    const donation = donationEntities.find(donation => Number(donation.donationId) === Number(donationId.value));

    if (!!donation) {
      await submition.mutateAsync({
        eventDonationId: {
          eventId: eventId.value,
          donation: {
            donationId: donation.donationId,
            logo: donation.logo,
            name: donation.name
          }
        }
      });
    }

    queryClient.invalidateQueries('eventDonations');

    cancelBtn();
  }

  return (
    <div>
      <Form onSubmit={e => submitEventDonation(e)}>
        <div className="modal-heading">
          <h5>{lang.EVENT_DON_FORM_TITLE}</h5>
        </div>
        <Form.Control value={eventId} name="eventId" hidden />
        <Form.Group className="mb-2" as={Col} controlId="formGridDonation">
          <Form.Label>{lang.EVENT_DON_SELECT}:</Form.Label>
          <Form.Control as="select" name="donationId">
            {donationEntities?.map(entity => (
              <option value={entity.donationId}>{entity.name}</option>
            ))}
          </Form.Control>
        </Form.Group>
        <Modal.Footer>
          <Button variant="success" type="submit">
            {lang.GLOBAL_SAVE_BUTTON}
          </Button>
          <Button variant="secondary" onClick={cancelBtn}>
            {lang.GLOBAL_CANCEL_BUTTON}
          </Button>
        </Modal.Footer>
      </Form>
    </div>
  );
};

export default EventDonationForm;
