import React, { useState } from 'react';
import GlassService, { IGlass } from '../../../libraries/glass/GlassService';
import DetailsContent from './DetailsContent';
import ModalComponent from '../modal/ModalComponent';
import './styles.scss';
import UpdateContent from './UpdateContent';
import { useQuery, UseQueryResult } from 'react-query';
import { Language } from '../../languages/languageHandler';
import { SingleRecordResponse } from '../../../libraries/zps';

interface ModalProps {
  glassId: number;
  openDetails: boolean;
  hideBtn: () => void;
  deleteBtn: () => void;
  lang: Language;
}

const ShowGlass = ({ glassId, openDetails, hideBtn, deleteBtn, lang }: ModalProps) => {
  const { data }: UseQueryResult<SingleRecordResponse<IGlass>> = useQuery(['glass', glassId], () =>
    GlassService.getGlassById(glassId)
  );

  const [showDetails, setShowDetails] = useState<boolean>(true);
  const [showUpdate, setShowUpdate] = useState<boolean>(false);

  function toggleUpdate() {
    setShowDetails(!showDetails);
    setShowUpdate(!showUpdate);
  }

  return (
    <>
      <ModalComponent
        show={openDetails}
        handleClick={hideBtn}
        animation={true}
        title={lang.GLASS_DETAILS_TITLE}
        content={
          <>
            {showDetails && data !== undefined && (
              <DetailsContent
                lang={lang}
                glass={data.record}
                toggleUpdate={() => toggleUpdate()}
                hideBtn={hideBtn}
                deleteGlass={deleteBtn}
              />
            )}
            {showUpdate && data !== undefined && (
              <UpdateContent lang={lang} glass={data.record} toggleUpdate={() => toggleUpdate()} />
            )}
          </>
        }
        size={'lg'}
      />
    </>
  );
};

export default ShowGlass;
