import axios from 'axios';
import { ICashIn } from '../transactions';
import { ApiResponse, zps_ea_war } from '../zps';

const BANK_TRANS_BASE_URL = `${zps_ea_war}/tb420/transaction/`;

export type IRejects = {
  denominationType: string;
  position: number;
  type: string;
};

export type IRefId = {
  code: number;
  eid: number;
  period: number;
};

export type IBankTransaction = {
  account: string;
  bankid: number;
  billsInDoubt: number;
  cashIn: ICashIn[];
  cid: number;
  code: number;
  coinsInDoubt: number;
  dateTime: string;
  dateTimeSent: string;
  depositor: string;
  eid: number;
  entid: number;
  envTotals: number;
  etvid: number;
  gid: number;
  hostStatusCode: number;
  hostTransactionID: string;
  msid: number;
  nseq: number;
  period: number;
  reason: string;
  refID?: IRefId;
  rejects: IRejects[];
  shift: number;
  sid: number;
  status: string;
  type: string;
  value: number;
  valueDis: number;
  valueRec: number;
};

export interface IBankTransactionResponse extends ApiResponse {
  transactions: IBankTransaction[];
  payment: boolean;
}

export const fetchBankTransactionList = async (begin: string, end: string, entid: number, sid: number) => {
  const { data } = await axios.get(`${BANK_TRANS_BASE_URL}?begin=${begin}&end=${end}&entid=${entid}&sid=${sid}`);

  return data;
};
