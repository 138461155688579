import React, { useState } from 'react';
import EventEquipmentService, {
  IEventEquipment,
  IEventEquipmentId
} from '../../../libraries/eventequipment/EventEquipmentService';
import { Container, Table, Row, Col, Modal, Button } from 'react-bootstrap';
import './styles.scss';
import { useMutation, useQueryClient } from 'react-query';
import { Language } from '../../languages/languageHandler';

interface EventEquipmentProps {
  equipmentList: IEventEquipment[] | undefined;
  eventId: number;
  hideBtn: () => void;
  addBtn: () => void;
  lang: Language;
}

const EventEquipment = ({ equipmentList, eventId, hideBtn, addBtn, lang }: EventEquipmentProps) => {
  const { mutateAsync } = useMutation(EventEquipmentService.deleteEventEquipment);

  const queryClient = useQueryClient();

  const [activeRow, setActiveRow] = useState<IEventEquipmentId | null>(null);
  const [isSelected, setIsSelected] = useState<boolean>(false);

  const handleToggle =
    (eventEquipmentId: IEventEquipmentId): (() => void) =>
    () => {
      setActiveRow(eventEquipmentId);
      setIsSelected(!isSelected);
    };

  const isActive = (eventEquipmentId: IEventEquipmentId): boolean => {
    if (!activeRow) return false;

    return eventEquipmentId.eventId === activeRow.eventId && eventEquipmentId.equipment.eid === activeRow.equipment.eid;
  };

  const deleteEventEquipment = async (activeRow: IEventEquipmentId | null) => {
    if (activeRow !== null && window.confirm('Are you certain you wish to remove this equipment?')) {
      await mutateAsync({
        eventId: eventId,
        equipment: activeRow.equipment
      });

      queryClient.invalidateQueries('eventEquipment');

      setActiveRow(null);
    }
  };

  return (
    <>
      <Container>
        <Row className="mb-2">
          <Col>
            <div className="event-modal-heading">
              <h5>{lang.GLOBAL_EQUIPMENT}</h5>
            </div>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <div className="event-details-table">
              <Table size="sm">
                <thead>
                  <tr>
                    <th>{lang.EQUIPMENT_ID}</th>
                    <th>{lang?.EQUIPMENT_DESCRIPTION}</th>
                  </tr>
                </thead>
                <tbody>
                  {equipmentList?.map(equipment => (
                    <tr
                      className={`table-row ${isActive(equipment.eventEquipmentId) ? 'activeRow' : ''}`}
                      onClick={handleToggle(equipment.eventEquipmentId)}
                    >
                      <td>{equipment.eventEquipmentId.equipment.eid}</td>
                      <td>{equipment.eventEquipmentId.equipment.description}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
      </Container>
      <Modal.Footer>
        <Button variant="success" onClick={addBtn}>
          {lang.GLOBAL_ADD_BUTTON}
        </Button>
        <Button variant="danger" onClick={() => deleteEventEquipment(activeRow)}>
          {lang.GLOBAL_DELETE_BUTTON}
        </Button>
        <Button variant="secondary" onClick={hideBtn}>
          {lang.GLOBAL_RETURN_BUTTON}
        </Button>
      </Modal.Footer>
    </>
  );
};

export default EventEquipment;
