import axios from 'axios';
import { zps_vi_url } from '../zps';

const EVENTS_BASE_URL = `${zps_vi_url}/event`;

export type IEvent = {
  eventId: number;
  name: string;
  address: string;
  zipCode: string;
  lat: number;
  longitude: number;
  installationDate: Date;
  beginDate: Date;
  endDate: Date;
  uninstallationDate: Date;
};

export const fetchFilteredEvents = async (
  pageNumber: number,
  pageSize: number,
  keyword?: string,
  beginDate?: string,
  endDate?: string
) => {
  const response = await axios.get(
    `${EVENTS_BASE_URL}s?pageNumber=${pageNumber}&pageSize=${pageSize}${
      keyword === undefined ? '' : `&keyword=${keyword}`
    }${beginDate === undefined ? '' : `&beginDate=${beginDate}`}${endDate === undefined ? '' : `&endDate=${endDate}`}`
  );

  return response.data;
};

class EventService {
  async getEventById(eventId: number) {
    return axios.get(EVENTS_BASE_URL + '/' + eventId).then(response => response.data);
  }

  createEvent(event: IEvent) {
    return axios.post(EVENTS_BASE_URL + '/new', event);
  }

  updateEvent(event: IEvent) {
    return axios.put(EVENTS_BASE_URL + '/update', event);
  }

  deleteEvent(eventId: number) {
    return axios.delete(EVENTS_BASE_URL + '/delete/' + eventId);
  }
}

const ExportedEventService = new EventService();

export default ExportedEventService;
