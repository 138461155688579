import axios from 'axios';
import { ApiResponse, zps_ea_war } from '../zps';

const TRANS_BASE_URL = `${zps_ea_war}/tb401/transaction/`;

export type ITransaction = {
  bankid: number;
  billsInDoubt: number;
  cashIn: ICashIn[];
  cid: number;
  code: number;
  coinsInDoubt: number;
  dateTime: Date;
  docCode: string;
  docType: number;
  eid: number;
  entid: number;
  envTotals: number;
  etvid: number;
  gid: number;
  mc: IMC[];
  msid: number;
  nseq: number;
  period: number;
  shift: number;
  sid: number;
  status: string;
  type: string;
  value: number;
  valueDis: number;
  valueRec: number;
  valueType: string;
  uid: number;
};

export type ICashIn = {
  destiny: number;
  index: number;
  quantity: number;
};

export type IMC = {
  destiny: number;
  index: number;
  origin: number;
  quantity: number;
};

export interface ITransactionResponse extends ApiResponse {
  payment: boolean;
  totalRows: number;
  transactions: ITransaction[];
  type: string;
}

export const fetchTransactionList = async (
  begin: string,
  end: string,
  entid: number,
  sid: number,
  eid: number,
  offset: number,
  limit: number,
  totalRows: boolean
) => {
  const { data } = await axios.get(
    `${TRANS_BASE_URL}?begin=${begin}&end=${end}&entid=${entid}&sid=${sid}&eid=${eid}&offset=${offset}&limit=${limit}&totalRows=${totalRows}`
  );

  return data;
};
