import { set } from 'lodash';
import React, { useState } from 'react';
import { Button, Form, Modal, Row } from 'react-bootstrap';
import { IGlassCategory } from '../../../libraries/glass/GlassService';
import { Language } from '../../languages/languageHandler';
import './styles.scss';

interface FormProps {
  isNew: boolean;
  categoryAttributes: IGlassCategory | null;
  submitForm: (e: any) => void;
  cancelBtn: () => void;
  lang: Language;
}

const CategoryForm = ({ isNew, categoryAttributes, submitForm, cancelBtn, lang }: FormProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [updatedAttributes, setUpdatedAttributes] = useState<IGlassCategory | null>(categoryAttributes);

  const handleChange = (fieldpath: string) => (event: any) => {
    if (!updatedAttributes) return;

    set(updatedAttributes, fieldpath, event?.target?.value);
  };
  return (
    <div>
      <Form onSubmit={submitForm}>
        <Row>
          <Form.Group>
            <Form.Label>{lang?.CATEGORY_DESCRIPTION}</Form.Label>
            {isNew ? (
              <Form.Control size="sm" required name="description" />
            ) : (
              <Form.Control
                size="sm"
                name="description"
                defaultValue={updatedAttributes?.description}
                onChange={handleChange('description')}
              />
            )}
          </Form.Group>
        </Row>
        <Modal.Footer>
          <Button variant="success" type="submit">
            {lang.GLOBAL_SAVE_BUTTON}
          </Button>
          <Button variant="secondary" onClick={cancelBtn}>
            {lang.GLOBAL_CANCEL_BUTTON}
          </Button>
        </Modal.Footer>
      </Form>
    </div>
  );
};

export default CategoryForm;
