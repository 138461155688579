import axios from 'axios';
import { IUser, PermissionOption } from '../users';
import { ApiResponse, zps_ea_war } from '../zps';

export interface IDistribution {
  emails: string[];
  entid?: number;
  eventCode: number;
  eventDescription: string;
  id: number;
  permissions: PermissionOption[];
  users: IUser[];
}

export interface IDistributionPayload {
  emails: string[];
  eventCode: number;
  id: number;
  permissions?: PermissionOption[];
  users: IUser[];
}

export interface IDistributionListResponse extends ApiResponse {
  list: IDistribution[];
  totalRows: number;
  type: string;
}

export interface IDistributionEventAct {
  destDesisor: boolean;
  destDispacter: boolean;
  destDistributionList: boolean;
  destMonitor: boolean;
}

export interface IDistributionEvent {
  act: IDistributionEventAct;
  actCounter: number;
  description: string;
  eventCode: number;
  eventType: number;
}

export interface IDistributionEventResponse extends ApiResponse {
  events: IDistributionEvent[];
  type: string;
}

export const fetchDistributionList = async (offset: number, limit: number, totalRows: boolean) => {
  const { data } = await axios.get(
    `${zps_ea_war}/distributionlist/?offset=${offset}&limit=${limit}&totalRows=${totalRows}`
  );

  return data;
};

export const postDistribution = async (newDistribution: IDistributionPayload) => {
  return await axios.post(`${zps_ea_war}/distributionlist/`, newDistribution);
};

export const updateDistribution = async (updatedDistribution: IDistributionPayload) => {
  return await axios.put(`${zps_ea_war}/distributionlist/`, updatedDistribution);
};

export const fetchDistributionListById = async (id: number) => {
  const { data } = await axios.get(`${zps_ea_war}/distributionlist/?id=${id}`);

  return data;
};

export const deleteDistributionById = async (id: number) => {
  return await axios.delete(`${zps_ea_war}/distributionlist/?id=${id}`);
};

export const fetchDistributionEvents = async () => {
  const { data } = await axios.get(`${zps_ea_war}/event/`);

  return data;
};
