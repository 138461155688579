import { ActionTypes } from './actionTypes';
import { AppDispatch, GetState } from '../store';
import { createAction } from '../actions';
import { fetchLanguages } from '../../../libraries/language';

export const selectedLang = (selectedLang: number) => async (dispatch: AppDispatch) => {
  dispatch(createAction(ActionTypes.LANG_UPDATE, selectedLang));
};

export const langClearInfo = () => async (dispatch: AppDispatch) => {
  dispatch(createAction(ActionTypes.LANG_CLEAR));
};

export const loadLanguageSettings = () => async (dispatch: AppDispatch, getState: GetState) => {
  if(!getState().lang.langSettings){
    let response : any = await fetchLanguages();
    console.log(response);
    let langSettings: any[] = response?.languages;
    console.log("loaded language settings: ", langSettings);
    dispatch(createAction(ActionTypes.LANG_SETTINGS, langSettings ));
  }
};

