import moment from 'moment';
import React, { FormEvent, useEffect, useState } from 'react';
import * as Icon from 'react-bootstrap-icons';
import { Button, Col, Container, Form, Row, Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { fetchEquipmentPeriodsSummaryResult, IPeriodResponse } from '../../../libraries/enterpriseEquipPeriods';
import { AppState } from '../../client-redux/reducers';
import { Language } from '../../languages/languageHandler';
import Header from '../ui/Header';

const EnterpriseEquipPeriods = () => {
  const lang = useSelector<AppState, Language | undefined>(state => state.lang.lang);

  const [beginDate, setBeginDate] = useState<string>(moment().startOf('day').format('YYYY-MM-DDT00:00:00.000'));
  const [endDate, setEndDate] = useState<string>(moment().format('YYYY-MM-DDT23:59:59.000'));
  const [entid, setEntid] = useState<number>(0);
  const [sid, setSid] = useState<number>(0);

  const [message, setMessage] = useState<IPeriodResponse>();
  const [noResults, setNoResults] = useState<boolean>(false);

  useEffect(() => {
    fetchEquipmentPeriodsSummaryResult(`${beginDate}Z`, `${endDate}Z`, entid, sid).then(response => {
      if (response.statusOper.code === 0) {
        setNoResults(false);
        setMessage(response);
      } else {
        setNoResults(true);
      }
    });
  }, [beginDate, endDate, entid, sid]);

  const getPeriodsList = () => {
    fetchEquipmentPeriodsSummaryResult(`${beginDate}Z`, `${endDate}Z`, entid, sid).then(response => {
      if (response.statusOper.code === 0) {
        setNoResults(false);
        setMessage(response);
      } else {
        setNoResults(true);
      }
    });
  };

  const submitSearch = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    getPeriodsList();
  };

  const displayResults = () => {
    if (noResults) {
      return (
        <>
          <tr>
            <td colSpan={12} style={{ textAlign: 'center' }}>
              Sem resultados
            </td>
          </tr>
        </>
      );
    }
    if (message) {
      return (
        <>
          {message.periods.map((period, index) => (
            <tr key={index}>
              <td>{period.entid}</td>
              <td>{period.eid}</td>
              <td>{period.period}</td>
              <td>{period.dtOpen}</td>
              <td>{period.dtClose}</td>
              <td>{period.periodAmounts[0].amount / 100}</td>
              <td>{period.periodAmounts[0].amountBilled / 100}</td>
              <td>
                <Icon.FilePdf />
              </td>
            </tr>
          ))}
        </>
      );
    }
  };

  return (
    <Container fluid>
      <Header pageTitle={lang?.ENTERPRISE_EQUIP_PERIODS} />
      <Form onSubmit={e => submitSearch(e)}>
        <Row className="mt-2 align-items-center">
          <Col lg="auto" md={2} sm="auto" xs={12}>
            <Form.Label className="equipmentSearchLabel">Entidade</Form.Label>
          </Col>
          <Col lg="auto" md={4} sm="auto" xs={12}>
            <Form.Select size="sm" name="entid" onChange={e => setEntid(Number(e.target.value))}>
              <option value={9999}>Zarph Test</option>
            </Form.Select>
          </Col>
          <Col lg="auto" md={2} sm="auto" xs={12} className="mt-2 mt-sm-0 ml-auto">
            <Form.Label className="equipmentSearchLabel">Delegação</Form.Label>
          </Col>
          <Col lg="auto" md={4} sm="auto" xs={12}>
            <Form.Select size="sm" name="sid" onChange={e => setSid(Number(e.target.value))}>
              <option value={0}>Todas</option>
              <option value={1}>HQ</option>
              <option value={2}>Demo 1</option>
              <option value={10}>Odivelas</option>
            </Form.Select>
          </Col>
          <Col lg="auto" md={2} sm="auto" xs={12} className="mt-2 mt-sm-0 ml-auto">
            <Form.Label className="equipmentSearchLabel">{lang?.EVENT_BEGIN_DATE_PLACEHOLDER}</Form.Label>
          </Col>
          <Col lg="auto" md={4} sm="auto" xs={12} className="mt-md-2">
            <Form.Control
              size="sm"
              type="datetime-local"
              name="beginning"
              defaultValue={moment().startOf('day').format('YYYY-MM-DDT00:00:00')}
              onChange={e => setBeginDate(e.target.value)}
            />
          </Col>
          <Col lg="auto" md={2} sm="auto" xs={12} className="mt-2 mt-md-2 mt-sm-0 ml-auto">
            <Form.Label className="equipmentSearchLabel">{lang?.EVENT_END_DATE_PLACEHOLDER}</Form.Label>
          </Col>
          <Col lg="auto" md={4} sm="auto" xs={12} className="mt-md-2 ml-auto">
            <Form.Control
              size="sm"
              type="datetime-local"
              name="end"
              defaultValue={moment().format('YYYY-MM-DDT23:59')}
              onChange={e => setEndDate(e.target.value)}
            />
          </Col>
          <Col lg="auto" xs={12} md="auto" className="mt-4 mt-md-2 mt-sm-0 ml-auto">
            <Button type="submit" className="w-100" size="sm">
              {lang?.GLOBAL_SEARCH}
            </Button>
          </Col>
        </Row>
      </Form>
      <Row className="mt-e">
        <Col>
          <Table responsive striped hover size="md" className="data-table">
            <thead className="table-head">
              <tr className="table-head-row">
                <th className="enterEquipPeriod-column">Delegação</th>
                <th className="enterEquipPeriod-column">{lang?.EQUIPMENT_ID}</th>
                <th className="enterEquipPeriod-column">{lang?.PROCESSED_GLASS_PERIOD}</th>
                <th className="enterEquipPeriod-column">{`${lang?.PROCESSED_GLASS_DATE_TIME} abertura`}</th>
                <th className="enterEquipPeriod-column">{`${lang?.PROCESSED_GLASS_DATE_TIME} fecho`}</th>
                <th className="enterEquipPeriod-column">Montante</th>
                <th className="enterEquipPeriod-column">Mont. Pagamentos</th>
                <th className="enterEquipPeriod-column"></th>
              </tr>
            </thead>
            <tbody>{displayResults()}</tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};

export default EnterpriseEquipPeriods;
