import React, { FormEvent, useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import EventService, { fetchFilteredEvents, IEvent } from '../../../libraries/events/EventService';
import NewEvent from './NewEvent';
import ShowEvent from './EventDetails';
import PaginationComponent from '../pagination/PaginationComponent';
import { Form, Table, Row, Col, Button } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import moment from 'moment';
import './styles.scss';
import { useSelector } from 'react-redux';
import { AppState } from '../../client-redux/reducers';
import { Language } from '../../languages/languageHandler';
import { Page } from '../../../libraries/zps';
import Header from '../ui/Header';

function Events() {
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation(EventService.deleteEvent);

  const lang = useSelector<AppState, Language | undefined>(state => state.lang.lang);

  const [page, setPage] = useState<Page<IEvent>>();
  const [pageNumber, setPageNumber] = useState<number>(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pageSize, setPageSize] = useState<number>(10);
  const [keyword, setKeyword] = useState<string>();
  const [selectedBeginDate, setSelectedBeginDate] = useState<string | undefined>();
  const [selectedEndDate, setSelectedEndDate] = useState<string | undefined>();

  const [activeRow, setActiveRow] = useState<number | null>(null);
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [openNew, setOpenNew] = useState<boolean>(false);

  useEffect(() => {
    fetchFilteredEvents(pageNumber, pageSize, keyword, selectedBeginDate, selectedEndDate).then(response => {
      setPage(response.page);
    });
  }, [keyword, pageNumber, pageSize, selectedBeginDate, selectedEndDate]);

  const handleToggle =
    (eventId: number): (() => void) =>
    () => {
      setActiveRow(eventId);
      setIsSelected(!isSelected);
      setIsOpen(!isOpen);
    };

  function toggleModal() {
    setIsOpen(!isOpen);
    setIsSelected(!isSelected);

    queryClient.fetchQuery('events');
  }

  function toggleNewEventModal() {
    setOpenNew(!openNew);
  }

  const isActive = (eventId: number): boolean => {
    if (!activeRow) return false;

    return eventId === activeRow;
  };

  //DisableBtn function - a avaliar utilidade

  /* function disableBtn(activeRow: number | null): string {
    if (activeRow === null) {
      return 'disabled';
    } else {
      return '';
    }
  } */

  const submitSearch = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const { keyword, beginDate, endDate } = e.target as typeof e.target & {
      keyword: { value: string };
      beginDate: { value: string };
      endDate: { value: string };
    };

    keyword.value ? setKeyword(keyword.value) : setKeyword(undefined);

    beginDate.value ? setSelectedBeginDate(beginDate.value) : setSelectedBeginDate(undefined);

    endDate.value ? setSelectedEndDate(endDate.value) : setSelectedEndDate(undefined);

    setPageNumber(0);
  };

  const deleteEvent = async (activeRow: number | null) => {
    if (
      activeRow !== null &&
      window.confirm('Are you certain you wish to delete this event? This action is irreversible')
    ) {
      await mutateAsync(activeRow);
      queryClient.invalidateQueries('events');
      setActiveRow(null);

      setIsOpen(false);
    }
  };

  const changePage = (n: number) => {
    setPageNumber(n);
  };

  const resultsSelect = (e: any) => {
    setPageSize(e.target.value);
  };

  return (
    <div className="index-container">
      <div>{lang && <NewEvent lang={lang} isNew={openNew} hideBtn={() => toggleNewEventModal()} />}</div>
      <div>
        {activeRow && lang && (
          <ShowEvent lang={lang} isOpen={isOpen} hideBtn={() => toggleModal()} eventId={activeRow} />
        )}
      </div>
      <div className="top-index-section">
        <div className="top-left">
          <Header pageTitle={lang?.EVENTS} />
        </div>
        <div className="top-right">
          <button className="btn btn-md btn-success btn-table add-btn" onClick={() => toggleNewEventModal()}>
            <Icon.Plus />
            &nbsp;{lang?.GLOBAL_ADD_BUTTON}&nbsp;
          </button>
        </div>
      </div>
      <div className="content-section">
        <div className="top-content-section">
          <div className="search-section">
            <Form className="search-form" onSubmit={e => submitSearch(e)}>
              <Row>
                <Col lg={1}>
                  <Form.Label>{lang?.GLOBAL_NAME}</Form.Label>
                </Col>
                <Col lg={3}>
                  <Form.Control size="sm" name="keyword" />
                </Col>
                <Col lg={1}>
                  <Form.Label>{lang?.EVENT_BEGIN_DATE_PLACEHOLDER}</Form.Label>
                </Col>
                <Col>
                  <Form.Control size="sm" type="date" name="beginDate" />
                </Col>
                <Col lg={1}>
                  <Form.Label>{lang?.EVENT_END_DATE_PLACEHOLDER}</Form.Label>
                </Col>
                <Col>
                  <Form.Control size="sm" type="date" name="endDate" />
                </Col>
                <Col>
                  <Button size="sm" type="submit">
                    {lang?.GLOBAL_SEARCH}
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
          <div className="results-section">
            <span>Mostrando </span>
            <Form.Select className="results-select" size="sm" onChange={e => resultsSelect(e)}>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </Form.Select>
            <span> resultados</span>
          </div>
        </div>
        <div className="index-table">
          <Table size="md" className="data-table">
            <thead className="table-head">
              <tr className="table-head-row">
                <th id="eventName">{lang?.GLOBAL_NAME}</th>
                <th id="eventAddress">{lang?.EVENT_ADDRESS}</th>
                <th id="eventZip">{lang?.EVENT_ZIP_CODE}</th>
                <th id="eventBeginDate">{lang?.EVENT_BEGIN_DATE}</th>
                <th className="w-25" id="eventEndDate">
                  {lang?.EVENT_END_DATE}
                </th>
                <th className="w-auto"></th>
              </tr>
            </thead>
            <tbody>
              {page?.content.map((event: IEvent) => {
                return (
                  <tr
                    key={event.eventId}
                    className={`table-row ${isActive(event.eventId) ? 'activeRow' : ''}`}
                    onClick={handleToggle(event.eventId)}
                  >
                    <td>{event.name}</td>
                    <td>{event.address}</td>
                    <td>{event.zipCode}</td>
                    <td>{moment(event.beginDate).format('DD/MM/YYYY')}</td>
                    <td>{moment(event.endDate).format('DD/MM/YYYY')}</td>
                    <td className="align-middle">
                      <button className="delete-btn float-end" onClick={() => deleteEvent(event.eventId)}>
                        <Icon.Trash />
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <div className="pagination-section">
            <PaginationComponent
              total={page?.totalElements}
              itemsPerPage={pageSize}
              currentPage={pageNumber}
              onPageChange={changePage}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Events;
