import React, { useState } from 'react';
import DonationService, { IDonation } from '../../../libraries/donation/DonationService';
import DetailsContent from './DetailsContent';
import UpdateContent from './UpdateContent';
import ModalComponent from '../modal/ModalComponent';
import './styles.scss';
import { useQuery, UseQueryResult } from 'react-query';
import { Language } from '../../languages/languageHandler';
import { SingleRecordResponse } from '../../../libraries/zps';

interface ModalProps {
  donationId: number;
  openDetails: boolean;
  hideBtn: () => void;
  deleteBtn: () => void;
  lang: Language;
}

const ShowDonation = ({ donationId, openDetails, hideBtn, deleteBtn, lang }: ModalProps) => {
  const { data }: UseQueryResult<SingleRecordResponse<IDonation>> = useQuery(['donation', donationId], () =>
    DonationService.getDonationEntityById(donationId)
  );

  const [showDetails, setShowDetails] = useState<boolean>(true);
  const [showUpdate, setShowUpdate] = useState<boolean>(false);

  function toggleUpdate() {
    setShowDetails(!showDetails);
    setShowUpdate(!showUpdate);
  }

  return (
    <>
      <ModalComponent
        show={openDetails}
        handleClick={hideBtn}
        animation={true}
        title={data?.record.name}
        size={'lg'}
        content={
          <>
            {showDetails && data !== undefined && (
              <DetailsContent
                lang={lang}
                entity={data.record}
                toggleUpdate={() => toggleUpdate()}
                hideBtn={hideBtn}
                deleteEntity={deleteBtn}
              />
            )}
            {showUpdate && data !== undefined && (
              <UpdateContent lang={lang} entity={data.record} toggleUpdate={() => toggleUpdate()} />
            )}
          </>
        }
      />
    </>
  );
};

export default ShowDonation;
