import React, { FormEvent, useRef, useState } from 'react';
import DonationService from '../../../libraries/donation/DonationService';
import DonationForm from './DonationForm';
import ModalComponent from '../modal/ModalComponent';
import './styles.scss';
import { useMutation, useQueryClient } from 'react-query';
import { Language } from '../../languages/languageHandler';

interface ModalProps {
  isNew: boolean;
  hideBtn: () => void;
  lang: Language;
}

const NewDonation = ({ isNew, hideBtn, lang }: ModalProps) => {
  const queryClient = useQueryClient();

  const mutation = useMutation(DonationService.createDonationEntity);

  const [loadedImage, setLoadedImage] = useState<string>();

  const uploadedImage = useRef<any>(null);
  const imageUploader = useRef<any>(null);

  const handleImageUpload = (e: any) => {
    const [file] = e.target.files;

    if (file !== null || file !== undefined) {
      const reader = new FileReader();

      const { current } = uploadedImage;

      current.file = file;

      reader.onload = (e: ProgressEvent<FileReader>) => {
        if (e.target) {
          current.src = e.target.result;

          if (reader.result) {
            const base64String: string = reader.result.toString().replace('data:', '').replace(/^.+,/, '');

            setLoadedImage(base64String);
          }
        }
      };

      reader.readAsDataURL(file);
    }
  };

  async function submitEntity(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const { donationId, name } = e.target as typeof e.target & {
      donationId: { value: number };
      name: { value: string };
    };

    if (loadedImage !== undefined) {
      await mutation.mutateAsync({
        donationId: donationId.value,
        name: name.value,
        logo: loadedImage
      });
    }

    queryClient.invalidateQueries('donations');

    hideBtn();
  }

  return (
    <>
      <ModalComponent
        show={isNew}
        handleClick={hideBtn}
        animation={true}
        size={'lg'}
        title={lang.DONATION_ENTITY_FORM_TITLE}
        content={
          <>
            <DonationForm
              lang={lang}
              isNew={true}
              donationAttributes={null}
              submitForm={e => submitEntity(e)}
              handleImageUpload={handleImageUpload}
              imageUploader={imageUploader}
              uploadedImage={uploadedImage}
              cancelBtn={hideBtn}
            />
          </>
        }
      />
    </>
  );
};

export default NewDonation;
