import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import { Language } from '../../languages/languageHandler';
import * as Icon from 'react-bootstrap-icons';

interface EmailFormProps {
  lang: Language;
  addToEmailList: (e: any) => void;
  deleteFromEmailList: (e: any) => void;
  emailList: string[];
}

const EmailSectionForm = ({ addToEmailList, deleteFromEmailList, emailList }: EmailFormProps) => {
  const [input, setInput] = useState<string>('');

  const [emailData, setEmailData] = useState<string[]>([]);

  const handleInputChange = (e: any) => {
    setInput(e.target.value);
  };

  useEffect(() => {
    setEmailData(emailList);
  }, [emailList]);

  return (
    <>
      <Row className="mb-4">
        <Col lg={1}>
          <Form.Label>Email</Form.Label>
        </Col>
        <Col>
          <Form.Control type="email" size="sm" onChange={handleInputChange} />
        </Col>
        <Col>
          <Button size="sm" onClick={() => addToEmailList(input)}>
            <Icon.PlusLg />
          </Button>
        </Col>
      </Row>
      <div className="distribution-details-table">
        <Table size="md">
          <thead>
            <tr>
              <th id="emailColumn">Email</th>
              <th id="deleteColumn"></th>
            </tr>
          </thead>
          <tbody>
            {emailData.map((email, index) => (
              <tr key={index} className="distribution-sections-table-body-row">
                <td>{email}</td>
                <td>
                  <Button className="distribution-sections-table-delet-btn" onClick={() => deleteFromEmailList(index)}>
                    <Icon.Trash />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default EmailSectionForm;
