import axios from 'axios';
import { IEquipment } from '../equipment/EquipmentService';
import { zps_vi_url } from '../zps';

const EVENT_EQUIP_BASE_URL = `${zps_vi_url}/eventequipment`;

export type IEventEquipmentId = {
  eventId: number;
  equipment: IEquipment;
};

export type IEventEquipment = {
  eventEquipmentId: IEventEquipmentId;
};

class EventEquipmentService {
  getEventEquipment() {
    return axios.get(EVENT_EQUIP_BASE_URL);
  }

  getEventEquipmentByEventEquipmentId(eventId: number, eid: number) {
    return axios.get(EVENT_EQUIP_BASE_URL + '/' + eventId + '/' + eid);
  }

  getEventEquipmentByEventId(eventId: number) {
    return axios.get(EVENT_EQUIP_BASE_URL + '/' + eventId).then(response => response.data);
  }

  createEventEquipment(eventEquipment: IEventEquipment) {
    return axios.post(EVENT_EQUIP_BASE_URL + '/new', eventEquipment);
  }

  deleteEventEquipment(eventEquipmentId: IEventEquipmentId) {
    return axios.delete(
      EVENT_EQUIP_BASE_URL + '/delete/' + eventEquipmentId.eventId + '/' + eventEquipmentId.equipment.eid
    );
  }
}

const ExportedEventEquipmentService = new EventEquipmentService();

export default ExportedEventEquipmentService;
