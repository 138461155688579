import React from 'react';
import { Button, Container, Modal, Tab, Tabs } from 'react-bootstrap';
import { IDistribution } from '../../../libraries/distributionList';
import { PermissionOptionResponse } from '../../../libraries/users';
import { Language } from '../../languages/languageHandler';
import EmailSection from './EmailSection';
import PermissionSection from './PermissionsSection';
import './styles.scss';
import UserSection from './UserSection';

interface DetailsProps {
  distribution: IDistribution;
  permissionOptions: PermissionOptionResponse;
  hideBtn: () => void;
  toggleUpdate: () => void;
  lang: Language;
}

const DistributionDetails = ({ distribution, permissionOptions, hideBtn, toggleUpdate, lang }: DetailsProps) => {
  return (
    <>
      <Container>
        <Tabs id="controlled-tab-example" defaultActiveKey="distributionPermissions" className="mb-3">
          <Tab eventKey="distributionPermissions" title={lang.USER_PERMISSIONS}>
            {permissionOptions && (
              <PermissionSection permissionOptionsData={permissionOptions} lang={lang} distribution={distribution} />
            )}
          </Tab>
          <Tab eventKey="distributionUsers" title={lang.GLOBAL_USERS}>
            <UserSection users={distribution.users} lang={lang} />
          </Tab>
          <Tab eventKey="distributionEmails" title="Email">
            <EmailSection emails={distribution.emails} />
          </Tab>
        </Tabs>
      </Container>
      <Modal.Footer>
        <Button className="updateBtn" onClick={toggleUpdate}>
          {lang.GLOBAL_UPDATE_BUTTON}
        </Button>
        <Button variant="secondary" onClick={hideBtn}>
          {lang.GLOBAL_RETURN_BUTTON}
        </Button>
      </Modal.Footer>
    </>
  );
};

export default DistributionDetails;
