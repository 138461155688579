import React, { FormEvent, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import DonationService, { IDonation } from '../../../libraries/donation/DonationService';
import { Language } from '../../languages/languageHandler';
import DonationForm from './DonationForm';
import './styles.scss';

interface UpdateProps {
  entity: IDonation;
  toggleUpdate: () => void;
  lang: Language;
}

const UpdateContent = ({ entity, toggleUpdate, lang }: UpdateProps) => {
  const queryClient = useQueryClient();

  const mutation = useMutation(DonationService.updateDonationEntity);

  const [entityAttributes] = useState<IDonation>(entity);

  function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (entityAttributes !== null) {
      mutation.mutateAsync({
        donationId: entityAttributes.donationId,
        name: entityAttributes.name,
        logo: entityAttributes.logo
      });

      queryClient.getQueryData('donation');

      queryClient.fetchQuery('donations');

      toggleUpdate();
    }
  }

  return (
    <>
      <DonationForm
        lang={lang}
        isNew={false}
        donationAttributes={entityAttributes}
        submitForm={e => handleSubmit(e)}
        cancelBtn={toggleUpdate}
      />
    </>
  );
};

export default UpdateContent;
