import axios from 'axios';
import { ApiResponse, Page, zps_vi_url } from '../zps';

const EQUIP_BASE_URL = `${zps_vi_url}/equipment`;

export type IEquipment = {
  eid: number;
  etid: number;
  serialNumber: string;
  description: string;
};

export type ICycleId = {
  cycle: number;
};

export type ICycle = {
  id: ICycleId;
  begin: Date;
  end: Date;
  occupData: string;
  comStatus: number;
};

export type IEquipmentDetails = {
  equipment: IEquipment;
  cycle: ICycle;
  tb602: any;
  event: any;
};

export interface IEquipmentDetailsResponse extends ApiResponse {
  page: Page<IEquipmentDetails>;
}

export const fetchVIEquipmentList = async () => {
  const { data } = await axios.get(`${EQUIP_BASE_URL}/details`);

  return data;
};

export const searchByEquipmentStatus = async (status: string) => {
  const { data } = await axios.get(`${EQUIP_BASE_URL}/details?status=${status}`);

  return data;
};

class EquipmentService {
  getEquipmentById(eid: number) {
    return axios.get(EQUIP_BASE_URL + '/' + eid).then(response => response.data);
  }

  createEquipment(equipment: IEquipment) {
    return axios.post(EQUIP_BASE_URL + '/new', equipment);
  }

  updateEquipment(equipment: IEquipment) {
    return axios.put(EQUIP_BASE_URL + '/update', equipment);
  }

  deleteEquipment(eid: number) {
    return axios.delete(EQUIP_BASE_URL + '/delete/' + eid);
  }

  async getAvailableEquipment(installationDate: Date, uninstallationDate: Date) {
    const { data } = await axios.get(
      EQUIP_BASE_URL + '/available/' + String(installationDate) + '/' + String(uninstallationDate)
    );

    return data;
  }
}

const ExportedEquipmentService = new EquipmentService();

export default ExportedEquipmentService;
