import React, { useRef, useState } from 'react';
import { Modal, Form, Col, Row, Button, Ratio } from 'react-bootstrap';
import { IAd } from '../../../libraries/advertising/AdvertisingService';
import './styles.scss';
import { set } from 'lodash';
import { Language } from '../../languages/languageHandler';

interface FormProps {
  isNew: boolean;
  adAttributes: IAd | null;
  submitForm: (e: any) => void;
  handleFileUpload?: (e: any) => void;
  fileUploader?: any;
  uploadedFile?: any;
  cancelBtn: () => void;
  lang: Language;
}

const AdsForm = ({
  isNew,
  adAttributes,
  submitForm,
  handleFileUpload,
  fileUploader,
  uploadedFile,
  cancelBtn,
  lang
}: FormProps) => {
  const [updatedAttributes, setUpdatedAttributes] = useState<IAd | null>(adAttributes);

  const updatedFile = useRef<any>(`data:video/webm;base64,${adAttributes?.file}`);
  const fileUpdater = useRef<any>(null);

  // function mediaHandler(filename: any, file: string) {
  //   const extension: string = filename.split('.')[1];

  //   if (extension === 'jpeg' || extension === 'jpg' || extension === 'png') {
  //     return (
  //       <Ratio aspectRatio="16x9">
  //         <Image className="align-middle" src={`data:image/${extension};base64,${file}`} />
  //       </Ratio>
  //     );
  //   } else {
  //     <Ratio aspectRatio="16x9">
  //       {isNew ? <video ref={uploadedFile} controls /> : <video ref={updatedFile} controls />}
  //     </Ratio>
  //   }
  // }

  const handleChange = (fieldPath: string) => (event: any) => {
    if (!updatedAttributes) return;

    switch (fieldPath) {
      case 'file':
        const [file] = event.target.files;

        if (file !== null || file !== undefined) {
          const reader = new FileReader();

          const { current } = updatedFile;

          current.file = file;

          reader.onload = (e: ProgressEvent<FileReader>) => {
            if (e.target) {
              current.src = e.target.result;

              const name: string = current.file.name;

              if (reader.result) {
                const base64String: string = reader.result.toString().replace('data:', '').replace(/^.+,/, '');

                set(updatedAttributes, fieldPath, base64String);

                set(updatedAttributes, 'fileName', name);

                setUpdatedAttributes({
                  adId: updatedAttributes.adId,
                  time: updatedAttributes.time,
                  fileName: name,
                  file: base64String
                });
              }
            }
          };

          reader.readAsDataURL(file);
        }
        break;
      default:
        set(updatedAttributes, fieldPath, event?.target?.value);
    }
  };

  return (
    <div>
      <Form onSubmit={submitForm}>
        <Row className="mb-2">
          <Form.Group as={Col} controlId="formGridAdId">
            <Form.Label>{lang.ADVERTISING_ID}:</Form.Label>
            {isNew ? (
              <Form.Control type="number" placeholder={lang.ADVERTISING_ID_PLACEHOLDER} name="adId" />
            ) : (
              <Form.Control readOnly value={adAttributes?.adId} name="adId" />
            )}
          </Form.Group>
          <Form.Group as={Col} controlId="formGridTime">
            <Form.Label>{lang.ADVERTISING_DURATION}</Form.Label>
            {isNew ? (
              <Form.Control type="number" name="time" />
            ) : (
              <Form.Control
                type="number"
                name="time"
                defaultValue={adAttributes?.time}
                onChange={handleChange('time')}
              />
            )}

            <Form.Text className="text-muted">{lang.ADVERTISING_SECONDS}</Form.Text>
          </Form.Group>
        </Row>

        <Row className="mb-2">
          <Form.Group as={Col} controlId="formFile">
            <Form.Label>{lang.ADVERTISING_FILE}</Form.Label>
            {isNew ? (
              <Form.Control
                type="file"
                accept=".jpeg, .png, .jpg, .mp4, .mov"
                placeholder="Enter file link"
                name="file"
                ref={fileUploader}
                onChange={handleFileUpload}
              />
            ) : (
              <Form.Control
                type="file"
                accept=".jpeg, .png, .jpg, .mp4, .mov"
                name="file"
                ref={fileUpdater}
                onChange={handleChange('file')}
              />
            )}
          </Form.Group>
        </Row>

        <Row className="mb-2">
          <Ratio aspectRatio="16x9">
            {isNew ? <video ref={uploadedFile} controls /> : <video ref={updatedFile} controls />}
          </Ratio>
          {/* {mediaHandler(fileUploader)} */}
        </Row>

        <Modal.Footer>
          <Button variant="success" type="submit">
            {lang.GLOBAL_SAVE_BUTTON}
          </Button>
          <Button variant="secondary" onClick={cancelBtn}>
            {lang.GLOBAL_CANCEL_BUTTON}
          </Button>
        </Modal.Footer>
      </Form>
    </div>
  );
};

export default AdsForm;
