import React, { FormEvent, useEffect, useState } from 'react';
import { getCategoryById, IGlassCategory, updateCategory } from '../../../libraries/glass/GlassService';
import { Language } from '../../languages/languageHandler';
import ModalComponent from '../modal/ModalComponent';
import CategoryForm from './CategoryForm';

interface ModalProps {
  openUpdate: boolean;
  categoryId: number;
  hideBtn: () => void;
  lang: Language;
  selectedCategory: IGlassCategory;
}

const UpdateCategory = ({ openUpdate, categoryId, hideBtn, lang, selectedCategory }: ModalProps) => {
  const [categoryAttributes, setCategoryAttributes] = useState<IGlassCategory | undefined>(selectedCategory);

  useEffect(() => {
    setCategoryAttributes(undefined);
    getCategoryById(categoryId).then(response => {
      console.log('getCategoryById', response);
      setCategoryAttributes(response.record);
    });
  }, [categoryId]);

  async function submitCategoryUpdate(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const { description } = e.target as typeof e.target & {
      description: { value: string };
    };

    if (categoryAttributes) {
      updateCategory({
        categoryId: categoryAttributes.categoryId,
        description: description.value
      });
    }

    hideBtn();
  }

  return (
    <>
      <ModalComponent
        show={openUpdate}
        handleClick={hideBtn}
        animation={true}
        size={'sm'}
        title={categoryAttributes?.description}
        content={
          <>
            {console.log('UpdateForm categoryAttributes: ', categoryAttributes)}
            {categoryAttributes && (
              <CategoryForm
                lang={lang}
                isNew={false}
                categoryAttributes={categoryAttributes}
                submitForm={e => submitCategoryUpdate(e)}
                cancelBtn={hideBtn}
              />
            )}
          </>
        }
      />
    </>
  );
};

export default UpdateCategory;
