export const viLang_pt = {
  GLOBAL_LANGUAGE_NAME: 'Português',
  GLOBAL_LANGUAGE_EN: 'pt-PT',
  GLOBAL_LANGUAGE_PT: 'en-GB',
  //Common Wording
  GLOBAL_ADD_BUTTON: 'Adicionar',
  GLOBAL_DELETE_BUTTON: 'Eliminar',
  GLOBAL_UPDATE_BUTTON: 'Atualizar',
  GLOBAL_CONTENT: 'Conteúdo',
  GLOBAL_SAVE_BUTTON: 'Guardar',
  GLOBAL_CANCEL_BUTTON: 'Cancelar',
  GLOBAL_RETURN_BUTTON: 'Voltar',
  GLOBAL_EQUIPMENT: 'Equipamentos',
  GLOBAL_GLASSES: 'Copos',
  GLOBAL_DONATION: 'Instituições Caridade',
  GLOBAL_SECTION_TABLE_TITLE: 'Gerir',
  GLOBAL_NAME: 'Nome',
  GLOBAL_SEARCH: 'Pesquisar',
  GLOBAL_PROFILE: 'Perfil',
  GLOBAL_USERS: 'Utilizadores',
  GLOBAL_PASSWORD: 'Password',
  GLOBAL_EVENT: 'Evento',
  GLOBAL_LOGOUT: 'Sair',
  GLOBAL_ALL: 'Todas',
  GLOBAL_SHOWING: 'Mostrando',
  GLOBAL_RESULTS: 'resultados',
  GLOBAL_LANGUAGE: 'Língua',
  GLOBAL_REBOOT: 'Reiniciar',
  GLOBAL_SHUT_DOWN: 'Desligar',
  GLOBAL_OPEN_ESCROW: 'Abrir escrow',
  GLOBAL_CLOSE_ESCROW: 'Fechar escrow',

  //Events Section
  EVENTS: 'Eventos',
  EVENTS_NEW: 'Adicionar Novo Evento',
  EVENT_ADDRESS: 'Endereço',
  EVENT_ZIP_CODE: 'Código Postal',
  EVENT_BEGIN_DATE: 'Data de Início',
  EVENT_BEGIN_DATE_PLACEHOLDER: 'Início',
  EVENT_END_DATE: 'Data de Fim',
  EVENT_END_DATE_PLACEHOLDER: 'Fim',
  EVENT_LAT: 'Lat',
  EVENT_LONG: 'Long',
  EVENT_ID: 'Id do Evento',
  EVENT_DATE_INFORMATION: 'Informações de Data',
  EVENT_INSTALLATION: 'Instalação',
  EVENT_UNINSTALLATION: 'Desinstalação',
  EVENT_DETAILS_GENERAL: 'Informações Gerais',
  EVENT_DETAILS_GENERAL_EVENT_DATES: 'Datas do Evento',
  EVENT_DETAILS_GENERAL_EVENT_DATES_FROM: 'De ',
  EVENT_DETAILS_GENERAL_EVENT_DATES_TO: 'até',
  EVENT_DETAILS_GENERAL_DIRECTIONS: 'Informação Geográfica',
  EVENT_GLASS_RECEPTACLE: 'Receptáculo',
  EVENT_GLASS_DELIVERY_ANIMATION: 'Animação de Entrega',
  EVENT_AD_ORDER: 'Ordem',
  EVENT_ID_PLACEHOLDER: 'Introduza Id do Evento',
  EVENT_NAME_PLACEHOLDER: 'Introduza Nome do Evento',
  EVENT_ZIP_CODE_PLACEHOLDER: '0000-000 Cidade',
  EVENT_LAT_PLACEHOLDER: '0.000',
  EVENT_LONG_PLACEHOLDER: '0.000',
  EVENT_EQUIPMENT_FORM_TITLE: 'Adicionar Equipamento ao Evento',
  EVENT_EQUIPMENT_FORM_SELECT: 'Selecione o Equipamento',
  EVENT_GLASS_FORM_TITLE: 'Adicionar Copo ao Evento',
  EVENT_GLASS_FORM_GLASS: 'Selecione o Copo',
  EVENT_GLASS_FORM_SELECT_RECEPTACLE: 'Selectione o Receptáculo',
  EVENT_GLASS_FORM_RECEPTACLE_1: 'Receptáculo 1',
  EVENT_GLASS_FORM_RECEPTACLE_2: 'Receptáculo 2',
  EVENT_GLASS_FORM_DELIVERY: 'Selecione a Animação de Entrega',
  EVENT_GLASS_FORM_RIGHT: 'Direccionar para a direita',
  EVENT_GLASS_FORM_LEFT: 'Direccionar para a esquerda',
  EVENT_AD_FORM_TITLE: 'Adicionar Anúncio ao Evento',
  EVENT_AD_FORM_SELECT: 'Selecione o Anúncio',
  EVENT_AD_FORM_ORDER: 'Escolha a ordem',
  EVENT_DON_FORM_TITLE: 'Adicionar Instituição de Caridade ao Evento',
  EVENT_DON_SELECT: 'Selecione a Instituição de Caridade',

  //Equipment Section
  EQUIPMENT_ID: 'EID',
  EQUIPMENT_SERIAL_NUMBER: 'Número de Série',
  EQUIPMENT_DESCRIPTION: 'Descrição',
  EQUIPMENT_IDENTIFICATION: 'Identificação',
  EQUIPMENT_SOFTWARE_VERSION: 'Versão',
  EQUIPMENT_CYCLE: 'Ciclo',
  EQUIPMENT_OCCUPATION: 'Ocupação',
  EQUIPMENT_COM_STATUS: 'Estado',
  EQUIPMENT_STATE: 'Estado Operacional',
  EQUIPMENT_STATE_IN_SERVICE: 'Em serviço',
  EQUIPMENT_STATE_OUT_OF_SERVICE: 'Fora de Serviço',
  EQUIPMENT_LAST_COM_DATE: 'U. Comun.',
  EQUIPMENT_LAST_OPERATION: 'Últ. Operação',
  EQUIPMENT_LAST_OPERATION_DATE: 'Data Últ. Operação',
  EQUIPMENT_OCCUPATION_PER: '% Ocupação',
  EQUIPMENT_EXISTENCES: 'Montante',
  EQUIPMENT_LOCAL: 'Local',
  EQUIPMENT_DETAILS_TITLE: 'Detalhes do Equipamento',
  EQUIPMENT_DETAILS_SUBTITLE: 'Informações do Equipamento',
  EQUIPMENT_ID_PLACEHOLDER: 'Por favor introduza o Id do Equipamento',
  EQUIPMENT_FORM_TITLE: 'Adicionar Novo Equipamento',

  //Glass Section
  GLASS: 'Copo',
  GLASS_NEW: 'Adicionar Novo Copo',
  GLASS_IMAGE: 'Imagem',
  GLASS_DESCRIPTION: 'Descrição',
  GLASS_DETAILS_TITLE: 'Detalhes do Copo',
  GLASS_ID: 'ID do Copo',
  GLASS_ID_PLACEHOLDER: 'Introduza ID do Copo',
  GLASS_CODE: 'Código do Copo',
  GLASS_CODE_PLACEHOLDER: 'Código do Copo',
  GLASS_SELECT_IMAGE: 'Selecione uma imagem',
  GLASS_DESCRIPTION_PLACEHOLDER: 'Descrição do copo',
  GLASS_SIZE_TYPE: 'Categoria de Tamanho',
  GLASS_SIZE_TYPE_PLACEHOLDER: 'Categoria de tamanho',
  GLASS_READ: 'Ler Copo',

  //Glass Category
  CATEGORY: 'Categoria',
  CATEGORIES: 'Categorias',
  CATEGORY_DESCRIPTION: 'Descrição',
  CATEGORY_NEW: 'Nova Categoria',

  //Advertising Section
  ADVERTISEMENTS: 'Anúncios',
  ADVERTISING_NEW: 'Adicionar Novo Anúncio',
  ADVERTISING_FILE: 'Ficheiro',
  ADVERTISING_DURATION: 'Duração',
  ADVERTISING_SECONDS: 'segundos',
  ADVERTISING_SECS: 'segs',
  ADVERTISING_DETAILS_TITLE: 'Detalhes do Anúncio',
  ADVERTISING: 'Anúncio',
  ADVERTISING_ID: 'Id do Anúncio',
  ADVERTISING_ID_PLACEHOLDER: 'Introduza Id do Anúncio',

  //Donation Entities Section
  DONATION_ENTITY: 'Instituição',
  DONATION_ENTITIES: 'Instituições',
  DONATION_ENTITIES_LOGO: 'Logo',
  DONATION_ENTITY_ID: 'Id da Instituição',
  DONATION_ENTITY_ID_PLACEHOLDER: 'Introduza o Id da Instituição',
  DONATION_ENTITY_NAME_PLACEHOLDER: 'Introduza o nome da Instituição',
  DONATION_ENTITY_SELECT_LOGO: 'Selecione o logo',
  DONATION_ENTITY_FORM_TITLE: 'Adicionar Nova Insituição',

  //Login
  LOGIN_ENTITY: 'Entidade',
  LOGIN_USER: 'Utilizador',
  LOGIN_BUTTON: 'Entrar',

  //Alert
  ALERT_ERRORCODE: 'Código Erro',
  ALERT_ERRORMESSAGE: 'Mensagem',

  //Users
  USER_EMAIL: 'Email',
  USER_EMAIL_PLACEHOLDER: 'exemplo@email.com',
  USER_CARD: 'Cartão',
  USER_CARD_PLACEHOLDER: 'Indique cartão do Utilizador',
  USER_REGION: 'Região',
  USER_LANG: 'Idioma',
  USER_LANG_EN: 'Inglês',
  USER_LANG_PT: 'Português',
  USER_NEW: 'Adicionar Novo Utilizador',
  USER_INFO: 'Detalhes Pessoais',
  USER_ENTITY: 'Entidade',
  USER_ENTITY_PLACEHOLDER: 'Selecione a Entidade',
  USER_ID: 'Id do Utilizador',
  USER_ID_PLACEHOLDER: 'Selecione o Id do Utilizador',
  USER_NAME_PLACEHOLDER: 'Indique o nome do Utilizador',
  USER_MOBILE: 'Telefone',
  USER_MOBILE_PLACEHOLDER: 'Indique o número de telefone do Utilizador',
  USER_PERMISSIONS: 'Permissões',
  USER_CONFIRM_PASSWORD: 'Confirmar Password',
  USER_CONFIRM_CURRENT_PASSWORD: 'Confirme a sua Password atual',
  USER_CHANGE_PASSWORD: 'Alterar Password',
  USER_NEW_PASSWORD: 'Nova Password',
  USER_PIN: 'Código PIN',
  USER_CHANGE_PIN: 'Alterar PIN',
  USER_NEW_PIN: 'Novo PIN',
  USER_CONFIRM_PIN: 'Confirmar Código PIN',
  USER_DELETE_CONFIRM: 'Tem a certeza que pretende eliminar este utilizador? Esta ação é irreversível',
  USER_CONTACT_INFO: 'Contactos',
  EDIT_USERS_LABEL_SYSTEM_MANAGEMENT_PERMISSION_1: 'Gestão de utilizadores e permissões',
  EDIT_USERS_LABEL_SYSTEM_MANAGEMENT_PERMISSION_2: 'Configurações de sistema',
  EDIT_USERS_LABEL_SYSTEM_MANAGEMENT_PERMISSION_3: 'Operações remotas não financeiras a equipamentos',
  EDIT_USERS_LABEL_SYSTEM_MANAGEMENT_PERMISSION_4: 'Técnico',
  EDIT_USERS_LABEL_BUSINESS_DATA_MANAGEMENT_PERMISSION_1: 'Dados financeiros',
  EDIT_USERS_LABEL_BUSINESS_DATA_MANAGEMENT_PERMISSION_2: 'CIT',
  EDIT_USERS_LABEL_BUSINESS_DATA_MANAGEMENT_PERMISSION_3: 'Operações remotas financeiras a equipamentos',
  EDIT_USERS_LABEL_BUSINESS_DATA_MANAGEMENT_PERMISSION_4: 'Operador de Depósito',
  EDIT_USERS_LABEL_BUSINESS_DATA_MANAGEMENT_PERMISSION_5: 'APM',
  USER_DELEGATIONS: 'Delegações',
  USER_MY_PROFILE: 'O meu Perfil',
  USER_PROFILE: 'Perfil',

  //Movements
  MOVEMENTS: 'Movimentos',
  PROCESSED_GLASS_PERIOD: 'Período',
  PROCESSED_GLASS_ID: 'ID',
  PROCESSED_GLASS_DATE_TIME: 'Data Hora',
  PROCESSED_GLASS_STATUS: 'Estado',
  SUCCESS: 'Recolhido',
  CANCELLED: 'Cancelado',
  ERROR: 'Erro',
  PROCESSED_GLASS_ERROR: 'Erro',
  NONE: ' ',
  INTERNAL: 'Erro Interno',
  NO_GLASS_ID: 'Copo Não Reconhecido',
  PROCESSED_GLASS_REFUND_TYPE: 'Tipo Devolução',
  CASH: 'Dinheiro',
  DONATION: 'Doação',
  PROCESSED_GLASS_CODE: 'Código',
  PROCESSED_GLASS_TUBE: 'Tubo',
  PROCESSED_GLASS_INITIAL_DATE: 'Data Inicial',
  PROCESSED_GLASS_END_DATE: 'Data Final',
  PROCESSED_GLASS_SEARCH_PLACEHOLER: 'Seleccione uma opção',
  TRANSACTION_DETAILS: 'Detalhes Transação',
  TRANSACTION_AMOUNT: 'Montante',
  TRANSACTION_RECEIVED: 'Recebido',
  TRANSACTION_DISPENSED: 'Dispensado',
  TRANSACTION_PAYMENT_METHOD: 'Método de Pagamento',
  TRANSACTION_TYPE: 'Type',

  //Enterprise Equipment Periods
  ENTERPRISE_EQUIP_PERIODS: 'Períodos de Equipamentos Empresariais',
  ENTERPRISE_EQUIP_PERIODS_SHORT: 'Períodos Equip. Emp.',

  //Bank Transactions
  BANK_TRANSACTIONS: 'Transações Bancárias',

  //Dashboard
  DASHBOARD: 'Dashboard'
};
