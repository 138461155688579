import axios from 'axios';
import { ApiResponse, zps_ea_war } from '../zps';

const EQUIP_PERIOD_BASE_URL = `${zps_ea_war}/tb401/period/`;

export type IPeriodAmount = {
  amount: number;
  amountBilled: number;
  cid: number;
  envTotals: number;
};

export type IPeriod = {
  bankid: number;
  billsInDoubt: number;
  coinsInDoubt: number;
  dtClose: string;
  dtOpen: string;
  eid: number;
  entid: number;
  etvid: number;
  gid: number;
  period: number;
  periodAmounts: IPeriodAmount[];
  periodType: string;
  sid: number;
  state: string;
  uid: number;
  uidClose: number;
  useReport: boolean;
};

export interface IPeriodResponse extends ApiResponse {
  periods: IPeriod[];
}

export const fetchEquipmentPeriodsSummaryResult = async (begin: string, end: string, entid: number, sid: number) => {
  const { data } = await axios.get(`${EQUIP_PERIOD_BASE_URL}?begin=${begin}&end=${end}&entid=${entid}&sid=${sid}`);

  return data;
};
