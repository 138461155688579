import React from 'react';
import { ToastContainer, Toast, Fade } from 'react-bootstrap';
import { ToastPosition } from 'react-bootstrap/esm/ToastContainer';
import './styles.scss';

interface AlertProps {
  position: ToastPosition;
  variant: string;
  visible: boolean;
  toggleAlert: () => void;
  errorCode: number;
  errorMessage: string;
}

export const Alert = ({ position, variant, visible, toggleAlert, errorCode, errorMessage }: AlertProps) => {
  return (
    <ToastContainer position={position}>
      <Toast
        animation={true}
        transition={Fade}
        bg={variant}
        show={visible}
        onClose={toggleAlert}
        delay={5000}
        autohide
      >
        <Toast.Header>
          <strong className="me-auto">Error: {errorCode}</strong>
        </Toast.Header>
        <Toast.Body className="alertBody">Message: {errorMessage}</Toast.Body>
      </Toast>
    </ToastContainer>
  );
};
