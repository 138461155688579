import React, { useState } from 'react';
import { getAdById, IAd } from '../../../libraries/advertising/AdvertisingService';
import DetailsContent from './DetailsContent';
import ModalComponent from '../modal/ModalComponent';
import './styles.scss';
import UpdateContent from './UpdateContent';
import { UseBaseQueryResult, useQuery } from 'react-query';
import { Language } from '../../languages/languageHandler';
import { SingleRecordResponse } from '../../../libraries/zps';

interface ModalProps {
  adId: number;
  openDetails: boolean;
  hideBtn: () => void;
  deleteBtn: () => void;
  lang: Language;
}

const ShowAd = ({ adId, openDetails, hideBtn, deleteBtn, lang }: ModalProps) => {
  const { data }: UseBaseQueryResult<SingleRecordResponse<IAd>> = useQuery(['ad', adId], () => getAdById(adId));

  const [showDetails, setShowDetails] = useState<boolean>(true);
  const [showUpdate, setShowUpdate] = useState<boolean>(false);

  function toggleUpdate() {
    setShowDetails(!showDetails);
    setShowUpdate(!showUpdate);
  }

  return (
    <>
      <ModalComponent
        show={openDetails}
        handleClick={hideBtn}
        animation={true}
        title={lang.ADVERTISING_DETAILS_TITLE}
        content={
          <>
            {showDetails && data !== undefined && (
              <DetailsContent
                lang={lang}
                ad={data.record}
                toggleUpdate={() => toggleUpdate()}
                hideBtn={hideBtn}
                deleteAd={deleteBtn}
              />
            )}
            {showUpdate && data !== undefined && (
              <UpdateContent lang={lang} ad={data.record} toggleUpdate={() => toggleUpdate()} />
            )}
          </>
        }
        size={'lg'}
      />
    </>
  );
};

export default ShowAd;
