import React, { FormEvent, useEffect, useState } from 'react';
import { IDistributionPayload, postDistribution } from '../../../libraries/distributionList';
import { IUser, PermissionOption, PermissionOptionResponse } from '../../../libraries/users';
import { Language } from '../../languages/languageHandler';
import ModalComponent from '../modal/ModalComponent';
import { permissionsToUser, preparePermissions } from '../users/permissionsAux';
import DistributionForm from './DistributionForm';
import './styles.scss';

interface ModalProps {
  isNew: boolean;
  hideBtn: () => void;
  lang: Language;
  permissionOptions: PermissionOptionResponse;
  haveChanged: () => void;
}

const NewDistribution = ({ isNew, hideBtn, lang, permissionOptions, haveChanged }: ModalProps) => {
  const [emailList, setEmailList] = useState<string[]>([]);
  const [usersList, setUsersList] = useState<IUser[]>([]);

  const [openUserForm, setOpenUserForm] = useState<boolean>(false);

  useEffect(() => {
    preparePermissions(permissionOptions.list, {});
    setPreparedPermissions(permissionOptions.list);
  }, [permissionOptions.list]);

  const [preparedPermissions, setPreparedPermissions] = useState<PermissionOption[]>([]);

  const addToEmailList = (newEmail: string) => {
    const newEmailList: string[] = [...emailList];
    newEmailList.push(newEmail);
    setEmailList(newEmailList);
  };

  const deleteFromEmailList = (index: number) => {
    const reducedEmailList: string[] = [...emailList];
    reducedEmailList.splice(index, 1);
    setEmailList(reducedEmailList);
  };

  const addToUserList = (newUser: IUser) => {
    const newUserList: IUser[] = [...usersList];
    newUserList.push(newUser);
    setUsersList(newUserList);
    setOpenUserForm(false);
  };

  const deleteFromUserList = (index: number) => {
    const reducedUsersList: IUser[] = [...usersList];
    reducedUsersList.splice(index, 1);
    setUsersList(reducedUsersList);
  };

  const toggleOpenUserForm = (b: boolean) => {
    setOpenUserForm(b);
  };

  const submitDistribution = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const { eventCode } = e.target as typeof e.target & {
      eventCode: { value: number };
    };

    let distributionPayload: IDistributionPayload = {
      emails: emailList,
      eventCode: eventCode.value,
      id: 0,
      users: usersList
    };

    permissionsToUser(preparedPermissions, distributionPayload);

    postDistribution(distributionPayload).then(response => {
      if (response.data.statusOper.code === 0) {
        haveChanged();
      }
    });

    hideBtn();
  };

  return (
    <ModalComponent
      show={isNew}
      handleClick={hideBtn}
      animation={true}
      title={lang?.DISTRIBUTION_NEW}
      content={
        <>
          <DistributionForm
            isNew={true}
            distributionAttributes={null}
            submitForm={e => submitDistribution(e)}
            cancelBtn={hideBtn}
            lang={lang}
            permissionOptions={preparedPermissions}
            addToUserList={addToUserList}
            deleteFromUserList={deleteFromUserList}
            userList={usersList}
            addToEmailList={addToEmailList}
            deleteFromEmailList={deleteFromEmailList}
            emailList={emailList}
            openUserForm={openUserForm}
            toggleOpenUserForm={toggleOpenUserForm}
          />
        </>
      }
      size={'lg'}
    />
  );
};

export default NewDistribution;
