import axios from 'axios';
import { IGlass } from '../glass/GlassService';
import { zps_vi_url } from '../zps';

const EVENT_GLASS_BASE_URL = `${zps_vi_url}/eventglass`;

export type IEventGlassId = {
  eventId: number;
  glass: IGlass;
};

export type IEventGlass = {
  eventGlassId: IEventGlassId;
  receptacle: number;
  deliveryAnimation: number;
};

class EventGlassService {
  getEventGlasses() {
    return axios.get(EVENT_GLASS_BASE_URL + 'es');
  }

  getEventGlassByEventGlassId(eventId: number, glassId: number) {
    return axios.get(EVENT_GLASS_BASE_URL + '/' + eventId + '/' + glassId);
  }

  getEventGlassByEventId(eventId: number) {
    return axios.get(EVENT_GLASS_BASE_URL + '/' + eventId).then(response => response.data);
  }

  createEventGlass(eventGlass: IEventGlass) {
    return axios.post(EVENT_GLASS_BASE_URL + '/new', eventGlass);
  }

  deleteEventGlass(eventGlassId: IEventGlassId) {
    return axios.delete(EVENT_GLASS_BASE_URL + '/delete/' + eventGlassId.eventId + '/' + eventGlassId.glass.glassId);
  }
}

const ExportedGlassService = new EventGlassService();

export default ExportedGlassService;
