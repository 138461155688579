import axios from 'axios';
import { IAd } from '../advertising/AdvertisingService';
import { zps_vi_url } from '../zps';

const EVENT_AD_BASE_URL = `${zps_vi_url}/eventad`;

export type IEventAdId = {
  eventId: number;
  ad: IAd;
};

export type IEventAd = {
  eventAdId: IEventAdId;
  adOrder: number;
};

class EventAdService {
  getEventAds() {
    return axios.get(EVENT_AD_BASE_URL + 's');
  }

  getEventAd(eventId: number, adId: number) {
    return axios.get(EVENT_AD_BASE_URL + '/' + eventId + '/' + adId);
  }

  getEventAdByEventId(eventId: number) {
    return axios.get(EVENT_AD_BASE_URL + '/' + eventId).then(response => response.data);
  }

  createEventAd(eventAd: IEventAd) {
    return axios.post(EVENT_AD_BASE_URL + '/new', eventAd);
  }

  deleteEventAd(id: IEventAdId) {
    return axios.delete(EVENT_AD_BASE_URL + '/delete/' + id.eventId + '/' + id.ad.adId);
  }
}

const ExportedEventAdService = new EventAdService();

export default ExportedEventAdService;
