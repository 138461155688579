import React, { useEffect, useState } from 'react';
import { fetchDistributionListById, IDistribution } from '../../../libraries/distributionList';
import { PermissionOption, PermissionOptionResponse } from '../../../libraries/users';
import { Language } from '../../languages/languageHandler';
import ModalComponent from '../modal/ModalComponent';
import { preparePermissions } from '../users/permissionsAux';
import DistributionDetails from './DistributionDetails';
import UpdateDistribution from './UpdateDistribution';

interface ModalProps {
  distributionId: number;
  permissionOptions: PermissionOptionResponse;
  openDetails: boolean;
  hideBtn: () => void;
  lang: Language;
}

const ShowDistributionList = ({ distributionId, permissionOptions, openDetails, hideBtn, lang }: ModalProps) => {
  const [distribution, setDistribution] = useState<IDistribution>();
  const [showDetails, setShowDetails] = useState<boolean>(true);
  const [showUpdate, setShowUpdate] = useState<boolean>(false);

  const [permissions, setPermissions] = useState<PermissionOption[]>();

  useEffect(() => {
    fetchDistributionListById(distributionId).then(response => {
      setDistribution(response.list[0]);
      if (permissionOptions) {
        let permObj = response.list[0];
        preparePermissions(permissionOptions.list, permObj);
        setPermissions(permissionOptions.list);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [distributionId]);

  const loadDistribution = () => {
    fetchDistributionListById(distributionId).then(response => {
      setDistribution(response.list[0]);
      if (permissionOptions) {
        let permObj = response.list[0];
        preparePermissions(permissionOptions.list, permObj);
        setPermissions(permissionOptions.list);
      }
    });
  };

  useEffect(() => {}, [permissionOptions]);

  const toggleUpdate = () => {
    setShowDetails(!showDetails);
    setShowUpdate(!showUpdate);

    if (showDetails) {
      loadDistribution();
    }
  };

  return (
    <>
      <ModalComponent
        show={openDetails}
        handleClick={hideBtn}
        animation={true}
        title={`${distribution?.id} - ${distribution?.eventDescription}`}
        content={
          <>
            {showDetails && distribution && (
              <DistributionDetails
                distribution={distribution}
                toggleUpdate={toggleUpdate}
                hideBtn={hideBtn}
                lang={lang}
                permissionOptions={permissionOptions}
              />
            )}
            {showUpdate && distribution && permissions && (
              <UpdateDistribution
                distribution={distribution}
                toggleUpdate={toggleUpdate}
                lang={lang}
                permissionOptions={permissions}
                haveChanged={loadDistribution}
              />
            )}
          </>
        }
        size={'lg'}
      />
    </>
  );
};

export default ShowDistributionList;
