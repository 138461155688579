import React, { useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { Login } from './app/components/login';
import Equipment from './app/components/equipment';
import Transactions from './app/components/transactions';
import Users from './app/components/users';
import Profile from './app/components/profile';
import './app.scss';
import { useSelector } from 'react-redux';
import { AppState } from './app/client-redux/reducers';
import axiosConfig from './libraries/axiosConfig';
import { WithNavBar } from './app/components/sidebar/with-nav-bar';
import EnterpriseEquipPeriods from './app/components/enterpriseEquipPeriods';
import BankTransactions from './app/components/bankTransactions';
import Dashboard from './app/components/dashboard';
import Ads from './app/components/advertising';
import Events from './app/components/events';
import Glasses from './app/components/glass';
import Movements from './app/components/processedGlass';
import DonationEntities from './app/components/donation';
import DistributionList from './app/components/distributionList';
import Categories from './app/components/categories';

const App = () => {
  const token = useSelector<AppState, String | undefined>(state => state.login.token);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false
      }
    }
  });

  useEffect(() => {
    console.log('refreshed');
    axiosConfig();
  }, []);

  const protectedRoute = (element: JSX.Element) => {
    return token ? element : <Navigate to="/" />;
  };

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes>
          <Route
            path="/dashboard"
            element={protectedRoute(
              <WithNavBar>
                <Dashboard />
              </WithNavBar>
            )}
          />
          <Route
            path="/equipment"
            element={protectedRoute(
              <WithNavBar>
                <Equipment />
              </WithNavBar>
            )}
          />
          <Route
            path="/transactions"
            element={protectedRoute(
              <WithNavBar>
                <Transactions />
              </WithNavBar>
            )}
          />
          <Route
            path="/users"
            element={protectedRoute(
              <WithNavBar>
                <Users />
              </WithNavBar>
            )}
          />
          <Route
            path="/enterpriseEquiPeriods"
            element={protectedRoute(
              <WithNavBar>
                <EnterpriseEquipPeriods />
              </WithNavBar>
            )}
          />
          <Route
            path="/bankTransactions"
            element={protectedRoute(
              <WithNavBar>
                <BankTransactions />
              </WithNavBar>
            )}
          />
          <Route
            path="/profile"
            element={protectedRoute(
              <WithNavBar>
                <Profile />
              </WithNavBar>
            )}
          />
          <Route
            path="/vi/events"
            element={protectedRoute(
              <WithNavBar>
                <Events />
              </WithNavBar>
            )}
          />
          <Route
            path="/vi/advertising"
            element={protectedRoute(
              <WithNavBar>
                <Ads />
              </WithNavBar>
            )}
          />
          <Route
            path="/vi/glasses"
            element={protectedRoute(
              <WithNavBar>
                <Glasses />
              </WithNavBar>
            )}
          />
          <Route
            path="/vi/glasses/categories"
            element={protectedRoute(
              <WithNavBar>
                <Categories />
              </WithNavBar>
            )}
          />
          <Route
            path="/vi/movements"
            element={protectedRoute(
              <WithNavBar>
                <Movements />
              </WithNavBar>
            )}
          />
          <Route
            path="/vi/donations"
            element={protectedRoute(
              <WithNavBar>
                <DonationEntities />
              </WithNavBar>
            )}
          />
          <Route
            path="/vi/distributionList"
            element={protectedRoute(
              <WithNavBar>
                <DistributionList />
              </WithNavBar>
            )}
          />
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Login />} />
        </Routes>
      </BrowserRouter>
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>
  );
};

export default App;
