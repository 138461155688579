export const zps_ea_war = process.env.REACT_APP_ZPS_EA_WAR;
export const zps_vi_url = process.env.REACT_APP_ZPS_VI_URL;

export interface StatusOper {
  code: number;
  message: string;
}

export interface ApiResponse {
  statusOper: StatusOper;
}

export interface Page<T> {
  content: T[];
  pageable: {
    offset: number;
    pageSize: number;
    pageNumber: number;
  };
  last: boolean;
  totalPages: number;
  totalElements: number;
  first: boolean;
  numberOfElements: number;
}

export interface PageResponse<T> {
  statusOper: StatusOper;
  page: Page<T>;
}

export interface ListResponse<T> {
  statusOper: StatusOper;
  list: T[];
}

export interface SingleRecordResponse<T> {
  record: T;
  statusOper: StatusOper;
}
