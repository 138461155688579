import React, { FormEvent, useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Language } from '../../languages/languageHandler';
import { createUser, IUser, PermissionOption, PermissionOptionResponse } from '../../../libraries/users/index';
import ModalComponent from '../modal/ModalComponent';
import UserForm from './UserForm';
import './styles.scss';
import { permissionsToUser, preparePermissions } from './permissionsAux';

interface ModalProps {
  isNew: boolean;
  hideBtn: () => void;
  lang: Language;
  permissionOptionData: PermissionOptionResponse;
}

const NewUser = ({ isNew, hideBtn, lang, permissionOptionData }: ModalProps) => {
  const queryClient = useQueryClient();

  const mutation = useMutation(createUser);

  const [permissionOptions, setPermissionOptions] = useState<PermissionOption[]>([]);

  useEffect(() => {
    preparePermissions(permissionOptionData.list, {});
    setPermissionOptions(permissionOptionData.list);
  }, [permissionOptionData.list]);

  function submitUser(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const { entid, uid, name, mobile, email, card, lang, region, password, pin } = e.target as typeof e.target & {
      entid: { value: number };
      uid: { value: number };
      name: { value: string };
      mobile: { value: string };
      email: { value: string };
      card: { value: string };
      lang: { value: number };
      region: { value: string };
      password: { value: string };
      pin: { value: string };
    };

    let userData: IUser = {
      entid: entid.value,
      uid: uid.value,
      name: name.value,
      mobile: mobile.value,
      email: email.value,
      card: card.value,
      lang: lang.value,
      region: region.value,
      password: password.value,
      pin: pin.value
    };

    permissionsToUser(permissionOptions, userData);

    mutation.mutateAsync(userData);

    queryClient.fetchQuery('users');

    hideBtn();
  }

  return (
    <>
      <ModalComponent
        show={isNew}
        handleClick={hideBtn}
        animation={true}
        title={lang?.USER_NEW}
        content={
          <>
            <UserForm
              lang={lang}
              isNew={true}
              userAttributes={null}
              submitForm={e => submitUser(e)}
              cancelBtn={hideBtn}
              permissionOptions={permissionOptions}
            />
          </>
        }
        size={'xl'}
      />
    </>
  );
};

export default NewUser;
