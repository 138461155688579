import React, { FormEvent } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import EventService from '../../../libraries/events/EventService';
import { Language } from '../../languages/languageHandler';
import ModalComponent from '../modal/ModalComponent';
import EventsForm from './EventsForm';
import './styles.scss';

interface ModalProps {
  isNew: boolean;
  hideBtn: () => void;
  lang: Language;
}

const NewEvent = ({ isNew, hideBtn, lang }: ModalProps) => {
  const queryClient = useQueryClient();

  const mutateAsync = useMutation(EventService.createEvent);

  async function submitEvent(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const {
      eventId,
      name,
      address,
      zipCode,
      lat,
      longitude,
      installationDate,
      beginDate,
      endDate,
      uninstallationDate
    } = e.target as typeof e.target & {
      eventId: { value: number };
      name: { value: string };
      address: { value: string };
      zipCode: { value: string };
      lat: { value: number };
      longitude: { value: number };
      installationDate: { value: Date };
      beginDate: { value: Date };
      endDate: { value: Date };
      uninstallationDate: { value: Date };
    };

    await mutateAsync.mutateAsync({
      eventId: eventId.value,
      name: name.value,
      address: address.value,
      zipCode: zipCode.value,
      lat: lat.value,
      longitude: longitude.value,
      installationDate: new Date(installationDate.value),
      beginDate: new Date(beginDate.value),
      endDate: new Date(endDate.value),
      uninstallationDate: new Date(uninstallationDate.value)
    });

    queryClient.invalidateQueries('events');

    hideBtn();
  }

  return (
    <>
      <ModalComponent
        show={isNew}
        handleClick={hideBtn}
        animation={true}
        title={lang.EVENTS_NEW}
        content={
          <>
            <EventsForm
              lang={lang}
              isNew={true}
              eventAttributes={null}
              submitForm={e => submitEvent(e)}
              cancelBtn={hideBtn}
            />
          </>
        }
        size={'xl'}
      />
    </>
  );
};

export default NewEvent;
