import axios from 'axios';
import { zps_vi_url } from '../zps';

const ADS_BASE_URL = `${zps_vi_url}/ad`;

export type IAd = {
  adId: number;
  fileName: string;
  file: string;
  time: number;
};

export const fetchFilteredAds = async (
  pageNumber: number,
  pageSize: number,
  keyword?: string,
  beginTime?: number,
  endTime?: number
) => {
  const response = await axios.get(
    `${ADS_BASE_URL}s?pageNumber=${pageNumber}&pageSize=${pageSize}${keyword ? `&keyword=${keyword}` : ''}${
      beginTime ? `&beginTime=${beginTime}` : ''
    }${endTime ? `&endTime=${endTime}` : ''}`
  );

  return response.data;
};

export const getAds = async () => {
  const { data } = await axios.get(ADS_BASE_URL + 's');

  return data;
};

export const getAdById = async (adId: number) => {
  const response = await axios.get(ADS_BASE_URL + '/' + adId);
  return response.data;
};

export const createAd = (ad: IAd) => {
  return axios.post(ADS_BASE_URL + '/new', ad);
};

export const updateAd = (ad: IAd) => {
  return axios.put(ADS_BASE_URL + '/update', ad);
};

export const deleteAd = async (adId: number) => {
  const response = await axios.delete(ADS_BASE_URL + '/delete/' + adId);
  console.log(response);
};
