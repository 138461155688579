import React, { useEffect, useMemo, useState } from 'react';
import { Pagination } from 'react-bootstrap';
import './styles.scss';

const PaginationComponent = ({ total, itemsPerPage, currentPage, onPageChange }) => {
  const [totalPages, setTotalPages] = useState();

  useEffect(() => {
    if (total !== null && total > 0 && itemsPerPage > 0) {
      setTotalPages(Math.ceil(total / itemsPerPage));
    }
  }, [total, itemsPerPage]);

  const paginationItems = useMemo(() => {
    const pages = [];
    const start = currentPage - 1 > 0 ? currentPage - 1 : 0;
    if (totalPages !== undefined) {
      const end = currentPage + 2 <= totalPages ? currentPage + 2 : totalPages;

      for (let index = start; index < end; index++) {
        pages.push(
          <>
            {}
            <Pagination.Item
              key={index}
              tabIndex={index}
              active={index === currentPage}
              onClick={() => onPageChange(index)}
            >
              {index + 1}
            </Pagination.Item>
          </>
        );
      }
    }

    return pages;
  }, [currentPage, totalPages, onPageChange]);

  if (totalPages === 0) return null;

  if (total === undefined) return null;

  return (
    <div className="pagination-container">
      <Pagination>
        <Pagination.First key={0} onClick={() => onPageChange(0)} />
        <Pagination.Prev onClick={() => onPageChange(currentPage - 1)} disabled={currentPage === 0} />
        {paginationItems}
        <Pagination.Next onClick={() => onPageChange(currentPage + 1)} disabled={currentPage === totalPages - 1} />
        <Pagination.Last
          key={totalPages - 1}
          onClick={() => onPageChange(totalPages !== undefined && totalPages % 1 !== 0 ? totalPages : totalPages - 1)}
        />
      </Pagination>
    </div>
  );
};

export default PaginationComponent;
