import React from 'react';
import { IGlass } from '../../../libraries/glass/GlassService';
import * as Icon from 'react-bootstrap-icons';
import { Col, Container, Row, Image, Modal, Button } from 'react-bootstrap';
import './styles.scss';
import { Language } from '../../languages/languageHandler';

interface DetailsProps {
  glass: IGlass;
  toggleUpdate: () => void;
  deleteGlass: () => void;
  hideBtn: () => void;
  lang: Language;
}

const DetailsContent = ({ glass, toggleUpdate, deleteGlass, hideBtn, lang }: DetailsProps) => {
  return (
    <>
      <Container>
        <Row className="mb-2">
          <Col>
            <div className="modal-heading">
              <h5>{lang.GLOBAL_CONTENT}</h5>
            </div>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <div>
              <Image className="mx-auto d-block glassDetsImg" src={`data:image/jpeg;base64,${glass.image}`} />
            </div>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <p className="iconCol">
              <Icon.CardText /> {glass.name}
            </p>
          </Col>
          <Col>
            <p className="iconCol">
              <Icon.Tag /> {glass.category.description}
            </p>
          </Col>
        </Row>
      </Container>

      <Modal.Footer>
        <Button className="updateBtn" onClick={toggleUpdate}>
          {lang.GLOBAL_UPDATE_BUTTON}
        </Button>
        <Button variant="danger" onClick={deleteGlass}>
          {lang.GLOBAL_DELETE_BUTTON}
        </Button>
        <Button variant="secondary" onClick={hideBtn}>
          {lang.GLOBAL_RETURN_BUTTON}
        </Button>
      </Modal.Footer>
    </>
  );
};

export default DetailsContent;
