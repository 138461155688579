import React, { FormEvent, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import GlassService, { getCategoryById, IGlass, IGlassCategory } from '../../../libraries/glass/GlassService';
import { Language } from '../../languages/languageHandler';
import GlassForm from './GlassForm';

interface UpdateProps {
  glass: IGlass;
  toggleUpdate: () => void;
  lang: Language;
}

const UpdateContent = ({ glass, toggleUpdate, lang }: UpdateProps) => {
  const queryClient = useQueryClient();

  const mutation = useMutation(GlassService.updateGlass);

  const [glassAttributes] = useState<IGlass>(glass);

  const [selectedCategory, setSelectedCategory] = useState<IGlassCategory>();

  const handleCategoryChange = (id: number) => {
    getCategoryById(id).then(response => {
      console.log('Category changed to: ', response.record);
      setSelectedCategory(response.record);
    });
  };

  function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    console.log('Update Content handleSubmit glassAttributes: ', glassAttributes);

    console.log('Update Content handleSubmit selectedCategory: ', selectedCategory);

    if (glassAttributes !== null && selectedCategory) {
      mutation.mutateAsync({
        glassId: glassAttributes.glassId,
        glassCode: glassAttributes.glassCode,
        image: glassAttributes.image,
        name: glassAttributes.name,
        category: selectedCategory
      });

      queryClient.getQueryData('glass');

      queryClient.fetchQuery('glasses');

      toggleUpdate();
    }
  }

  return (
    <>
      <GlassForm
        lang={lang}
        isNew={false}
        glassAttributes={glassAttributes}
        submitForm={e => handleSubmit(e)}
        cancelBtn={toggleUpdate}
        handleCategoryChange={handleCategoryChange}
      />
    </>
  );
};

export default UpdateContent;
