import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row, Table } from 'react-bootstrap';
import { Language } from '../../languages/languageHandler';
import { fetchEntities, fetchUsers, IEntity, IEntityResponse, IUser, IUserResponse } from '../../../libraries/users';
import { useSelector } from 'react-redux';
import { AppState } from '../../client-redux/reducers';
import { LoginBody } from '../../../libraries/login';
import PaginationComponent from '../pagination/PaginationComponent';

interface UserPickerProps {
  lang: Language;
  addToUsersList?: (e: any) => void;
}

const UserPicker = ({ lang, addToUsersList }: UserPickerProps) => {
  const login = useSelector<AppState, LoginBody | undefined>(state => state.login.user);

  const [entid, _setEntid] = useState<string | undefined>(login?.entid);
  const [limit, _setLimit] = useState<number>(10);
  const [offset, _setOffset] = useState<number>(0);
  const [totalRows, _setTotalRows] = useState<boolean>(true);

  const [selectedEntid, setSelectedEntid] = useState<string>();
  const [selectedName, setSelectedName] = useState<string>();
  const [selectedUid, setSelectedUid] = useState<string>();

  const [pageNumber, setPageNumber] = useState<number>(0);

  const [usersResponse, setUsersResponse] = useState<IUserResponse>();
  const [userOptionsList, setUserOptionsList] = useState<IUser[]>([]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [entitiesResponse, setEntitiesResponse] = useState<IEntityResponse>();
  const [entities, setEntities] = useState<IEntity[]>([]);

  useEffect(() => {
    fetchUsers(entid, limit, offset, totalRows.toString()).then(response => {
      setUsersResponse(response);
      setUserOptionsList(response.users);
    });

    fetchEntities().then(response => {
      setEntitiesResponse(response);
      setEntities(response.entities);
    });
  }, [entid, limit, offset, totalRows]);

  const selectEntid = (entid: any) => {
    setSelectedEntid(entid.target.value);
  };

  const changePage = (n: number) => {
    if (n < pageNumber) {
      _setOffset(offset - limit);
    } else if (n > pageNumber) {
      _setOffset(offset + limit);
    }
    setPageNumber(n);
  };

  const handleNameChange = (e: any) => {
    setSelectedName(e.target.value);
  };

  const handleUidChange = (e: any) => {
    setSelectedUid(e.target.value);
  };

  const submitSearch = () => {
    if (selectedEntid) {
      fetchUsers(selectedEntid, limit, offset, totalRows.toString(), selectedName, selectedUid).then(response => {
        setUsersResponse(response);
        setUserOptionsList(response.users);
      });
    } else {
      fetchUsers(entid, limit, offset, totalRows.toString(), selectedName, selectedUid).then(response => {
        setUsersResponse(response);
        setUserOptionsList(response.users);
      });
    }
  };

  return (
    <>
      <Container>
        <Row className="mb-4 user-picker-row">
          <div id="user-picker-label-entity">
            <Form.Label>{lang?.USER_ENTITY}</Form.Label>
          </div>
          <div id="user-picker-select-entity">
            <Form.Control as="select" size="sm" name="entity" onChange={e => selectEntid(e)}>
              {entities.map(entity => (
                <option value={entity.entid}>{entity.name}</option>
              ))}
            </Form.Control>
          </div>
          <div id="user-picker-label-name">
            <Form.Label>{lang?.GLOBAL_NAME}</Form.Label>
          </div>
          <div id="user-picker-input-name">
            <Form.Control size="sm" name="name" onChange={handleNameChange} />
          </div>

          <div id="user-picker-label-id">
            <Form.Label>ID</Form.Label>
          </div>
          <div id="user-picker-input-id">
            <Form.Control size="sm" name="uid" onChange={handleUidChange} />
          </div>
          <div id="user-picker-btn">
            <Button size="sm" onClick={submitSearch}>
              Pesquisa
            </Button>
          </div>
        </Row>
        <Row className="mb-2">
          <Col>
            <div className="distribution-details-table">
              <Table size="sm">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>{lang?.GLOBAL_NAME}</th>
                  </tr>
                </thead>
                <tbody>
                  {addToUsersList &&
                    userOptionsList.map((user, index) => (
                      <tr key={index} onClick={() => addToUsersList(user)}>
                        <td>{user.uid}</td>
                        <td>{user.name}</td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
        <Row>
          <PaginationComponent
            total={usersResponse?.totalRows}
            itemsPerPage={10}
            currentPage={pageNumber}
            onPageChange={changePage}
          />
        </Row>
      </Container>
    </>
  );
};

export default UserPicker;
