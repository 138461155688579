import React from 'react';
import { IEvent } from '../../../libraries/events/EventService';
import { Container, Row, Col, Modal, Button } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import moment from 'moment';
import './styles.scss';
import { Language } from '../../languages/languageHandler';

interface GeneralInfoProps {
  event: IEvent;
  deleteEvent: () => void;
  toggleUpdate: () => void;
  hideBtn: () => void;
  lang: Language;
}

const GeneralInformation = ({ event, deleteEvent, toggleUpdate, hideBtn, lang }: GeneralInfoProps) => {
  return (
    <>
      <Container>
        <Row className="mb-2">
          <Col>
            <div className="modal-heading">
              <h5>{lang.EVENT_DETAILS_GENERAL}</h5>
            </div>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <h6>{lang.EVENT_DETAILS_GENERAL_EVENT_DATES}</h6>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <p>
              {lang.EVENT_DETAILS_GENERAL_EVENT_DATES_FROM}{' '}
              <strong>{moment(event?.beginDate).format('DD/MM/YYYY')}</strong>{' '}
              {lang.EVENT_DETAILS_GENERAL_EVENT_DATES_TO} <strong>{moment(event?.endDate).format('DD/MM/YYYY')}</strong>
            </p>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <h6>{lang.EVENT_DETAILS_GENERAL_DIRECTIONS}</h6>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <p className="iconCol">
              <Icon.GeoAltFill /> {event?.address}, {event?.zipCode}
            </p>
          </Col>
          <Col>
            <p className="iconCol">
              <Icon.PinMapFill /> {event?.lat}, {event?.longitude}
            </p>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <h6>{lang.EVENT_INSTALLATION}</h6>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <p className="iconCol">
              <Icon.Calendar2CheckFill /> {moment(event?.installationDate).format('DD/MM/YYYY')}
            </p>
          </Col>
          <Col>
            <p className="iconCol">
              <Icon.Calendar2XFill /> {moment(event?.uninstallationDate).format('DD/MM/YYYY')}
            </p>
          </Col>
        </Row>
      </Container>
      <Modal.Footer>
        <Button className="updateBtn" onClick={toggleUpdate}>
          {lang.GLOBAL_UPDATE_BUTTON}
        </Button>
        <Button variant="danger" onClick={() => deleteEvent()}>
          {lang.GLOBAL_DELETE_BUTTON}
        </Button>
        <Button variant="secondary" onClick={hideBtn}>
          {lang.GLOBAL_RETURN_BUTTON}
        </Button>
      </Modal.Footer>
    </>
  );
};

export default GeneralInformation;
