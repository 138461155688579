import React, { FormEvent, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import EventService, { IEvent } from '../../../libraries/events/EventService';
import { Language } from '../../languages/languageHandler';
import EventsForm from './EventsForm';

interface UpdateProps {
  event: IEvent;
  toggleUpdate: () => void;
  lang: Language;
}

const UpdateContent = ({ event, toggleUpdate, lang }: UpdateProps) => {
  const queryClient = useQueryClient();

  const mutation = useMutation(EventService.updateEvent);

  const [eventAttributes] = useState<IEvent>(event);

  function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (eventAttributes !== null) {
      mutation.mutateAsync({
        eventId: eventAttributes.eventId,
        name: eventAttributes.name,
        address: eventAttributes.address,
        zipCode: eventAttributes.zipCode,
        lat: eventAttributes.lat,
        longitude: eventAttributes.longitude,
        installationDate: new Date(eventAttributes.installationDate),
        beginDate: new Date(eventAttributes.beginDate),
        endDate: new Date(eventAttributes.endDate),
        uninstallationDate: new Date(eventAttributes.uninstallationDate)
      });

      queryClient.getQueryData('event');

      queryClient.fetchQuery('events');

      toggleUpdate();
    }
  }

  return (
    <>
      <EventsForm
        lang={lang}
        isNew={false}
        eventAttributes={eventAttributes}
        submitForm={e => handleSubmit(e)}
        cancelBtn={toggleUpdate}
      />
    </>
  );
};

export default UpdateContent;
