import React, { FormEvent } from 'react';
import { Modal, Form, Col, Button } from 'react-bootstrap';
import { useMutation, useQuery, useQueryClient, UseQueryResult } from 'react-query';
import EquipmentService, { IEquipment } from '../../../libraries/equipment/EquipmentService';
import EventEquipmentService from '../../../libraries/eventequipment/EventEquipmentService';
import { IEvent } from '../../../libraries/events/EventService';
import { Language } from '../../languages/languageHandler';
import './styles.scss';

interface FormProps {
  cancelBtn: () => void;
  event: IEvent;
  lang: Language;
}

const EventEquipmentForm = ({ cancelBtn, event, lang }: FormProps) => {
  const { data }: UseQueryResult<IEquipment[], Error> = useQuery(
    ['availableEquipment', [event.installationDate, event.uninstallationDate]],
    () => EquipmentService.getAvailableEquipment(event.installationDate, event.uninstallationDate)
  );

  const queryClient = useQueryClient();

  const submition = useMutation(EventEquipmentService.createEventEquipment);

  async function submitEventEquipment(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (!data) return;

    const { eventId, eid } = e.target as typeof e.target & {
      eventId: { value: number };
      eid: { value: number };
    };

    const equipment = data.find(equipment => Number(equipment.eid) === Number(eid.value));

    if (!!equipment) {
      await submition.mutateAsync({
        eventEquipmentId: {
          eventId: eventId.value,
          equipment: {
            eid: equipment.eid,
            etid: equipment.etid,
            serialNumber: equipment.serialNumber,
            description: equipment.description
          }
        }
      });
    }

    queryClient.invalidateQueries('eventEquipment');

    cancelBtn();
  }

  return (
    <div>
      <Form onSubmit={e => submitEventEquipment(e)}>
        <div className="modal-heading">
          <h5>{lang.EVENT_EQUIPMENT_FORM_TITLE}</h5>
        </div>
        <Form.Control value={event.eventId} name="eventId" hidden />
        <Form.Group className="mb-2" as={Col} controlId="formGridEquipment">
          <Form.Label>{lang.EVENT_EQUIPMENT_FORM_SELECT}:</Form.Label>
          <Form.Control as="select" name="eid">
            {data?.map(equipment => (
              <option value={equipment.eid}>
                {equipment.eid} - {equipment.description}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        <Modal.Footer>
          <Button variant="success" type="submit">
            {lang.GLOBAL_SAVE_BUTTON}
          </Button>
          <Button variant="secondary" onClick={cancelBtn}>
            {lang.GLOBAL_CANCEL_BUTTON}
          </Button>
        </Modal.Footer>
      </Form>
    </div>
  );
};

export default EventEquipmentForm;
