import React, { useState } from 'react';
import EventDonationService, {
  IEventDonation,
  IEventDonationId
} from '../../../libraries/eventdonation/EventDonationService';
import { Container, Table, Row, Col, Modal, Button, Image } from 'react-bootstrap';
import './styles.scss';
import { useMutation, useQueryClient } from 'react-query';
import { Language } from '../../languages/languageHandler';

interface EventDonationProps {
  donationsList: IEventDonation[] | undefined;
  eventId: number;
  hideBtn: () => void;
  addBtn: () => void;
  lang: Language;
}

const EventDonation = ({ donationsList, eventId, hideBtn, addBtn, lang }: EventDonationProps) => {
  const { mutateAsync } = useMutation(EventDonationService.deleteEventDonationEntity);

  const queryClient = useQueryClient();

  const [activeRow, setActiveRow] = useState<IEventDonationId | null>(null);
  const [isSelected, setIsSelected] = useState<boolean>(false);

  const handleToggle =
    (eventDonationId: IEventDonationId): (() => void) =>
    () => {
      setActiveRow(eventDonationId);
      setIsSelected(!isSelected);
    };

  const isActive = (eventDonationId: IEventDonationId): boolean => {
    if (!activeRow) return false;

    return (
      eventDonationId.eventId === activeRow.eventId &&
      eventDonationId.donation.donationId === activeRow.donation.donationId
    );
  };

  const deleteEventDonation = async (activeRow: IEventDonationId | null) => {
    if (activeRow !== null && window.confirm('Are you certain you wish to remove this entity?')) {
      await mutateAsync({
        eventId: eventId,
        donation: activeRow.donation
      });

      queryClient.invalidateQueries('eventDonations');

      setActiveRow(null);
    }
  };

  return (
    <>
      <Container>
        <Row className="mb-2">
          <Col>
            <div className="event-modal-heading">
              <h5>{lang.GLOBAL_DONATION}</h5>
            </div>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <div className="event-details-table">
              <Table size="sm">
                <thead>
                  <tr>
                    <th>{lang.DONATION_ENTITIES_LOGO}</th>
                    <th>{lang.GLOBAL_NAME}</th>
                  </tr>
                </thead>
                <tbody>
                  {donationsList?.map(entity => (
                    <tr
                      className={`table-row ${isActive(entity.eventDonationId) ? 'activeRow' : ''}`}
                      onClick={handleToggle(entity.eventDonationId)}
                    >
                      <td id="eventDon-td">
                        <div className="eventDon-container">
                          <Image
                            className="eventDonImage align-self-center"
                            src={`data:image/jpeg;base64,${entity.eventDonationId.donation.logo}`}
                            rounded
                          />
                        </div>
                      </td>
                      <td>{entity.eventDonationId.donation.name}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
      </Container>
      <Modal.Footer>
        <Button variant="success" onClick={addBtn}>
          {lang.GLOBAL_ADD_BUTTON}
        </Button>
        <Button variant="danger" onClick={() => deleteEventDonation(activeRow)}>
          {lang.GLOBAL_DELETE_BUTTON}
        </Button>
        <Button variant="secondary" onClick={hideBtn}>
          {lang.GLOBAL_RETURN_BUTTON}
        </Button>
      </Modal.Footer>
    </>
  );
};

export default EventDonation;
