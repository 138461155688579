/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Modal, Form, Col, Row, Button, Tabs, Tab, Container } from 'react-bootstrap';
import { set } from 'lodash';
import './styles.scss';
import { Language } from '../../languages/languageHandler';
import {
  fetchDistributionEvents,
  IDistribution,
  IDistributionEvent,
  IDistributionEventResponse
} from '../../../libraries/distributionList';
import { fetchUsers, IUser, IUserResponse, PermissionOption } from '../../../libraries/users';
import EmailSectionForm from './EmailSectionForm';
import UserSectionForm from './UserSectionForm';

interface FormProps {
  isNew: boolean;
  distributionAttributes: IDistribution | null;
  submitForm: (e: any) => void;
  cancelBtn: () => void;
  lang: Language;
  permissionOptions: PermissionOption[];
  addToUserList: (e: any) => void;
  deleteFromUserList: (e: any) => void;
  userList: IUser[];
  addToEmailList: (e: any) => void;
  deleteFromEmailList: (e: any) => void;
  emailList: string[];
  openUserForm: boolean;
  toggleOpenUserForm: (e: any) => void;
}

const DistributionForm = ({
  isNew,
  distributionAttributes,
  submitForm,
  cancelBtn,
  lang,
  permissionOptions,
  addToUserList,
  deleteFromUserList,
  userList,
  addToEmailList,
  deleteFromEmailList,
  emailList,
  openUserForm,
  toggleOpenUserForm
}: FormProps) => {
  const [eventResponse, setEventResponse] = useState<IDistributionEventResponse>();
  const [usersResponse, setUsersResponse] = useState<IUserResponse>();

  const [distributionEventList, setDistributionEventList] = useState<IDistributionEvent[]>([]);
  const [distributionUsersList, setDistributionUsersList] = useState<IUser[]>([]);

  useEffect(() => {
    fetchDistributionEvents().then(response => {
      setEventResponse(response);
      setDistributionEventList(response.events);
    });

    fetchUsers().then(response => {
      setUsersResponse(response);
      setDistributionUsersList(response.users);
    });
  }, [distributionAttributes, isNew, emailList]);

  const [updatedAttributes, setUpdatedAttributes] = useState<IDistribution | null>(distributionAttributes);

  const [selectedPermissions, setSelectedPermissions] = useState<PermissionOption[]>(permissionOptions);

  const handleChange = (fieldPath: string) => (event: any) => {
    if (!updatedAttributes) return;

    set(updatedAttributes, fieldPath, event.target.value);
    setUpdatedAttributes(updatedAttributes);
  };

  const toggleSelection = (po: PermissionOption) => {
    let newP: PermissionOption[] = [...selectedPermissions];
    po.selected = !po.selected;
    setSelectedPermissions(newP);
  };

  return (
    <div>
      <Form onSubmit={submitForm}>
        <Row className="mb-4">
          <Form.Group as={Col} controlId="formGridAd">
            <Form.Label>{lang?.GLOBAL_EVENT}</Form.Label>
            {isNew ? (
              <Form.Control as="select" size="sm" name="eventCode">
                {distributionEventList.map(event => (
                  <option value={event.eventCode}>{event.description}</option>
                ))}
              </Form.Control>
            ) : (
              <Form.Control
                as="select"
                size="sm"
                name="eventCode"
                defaultValue={distributionAttributes?.eventCode}
                onChange={handleChange('eventCode')}
              >
                {distributionEventList.map(event => (
                  <option value={event.eventCode}>{event.description}</option>
                ))}
              </Form.Control>
            )}
          </Form.Group>
        </Row>
        <Row className="mb-4">
          <Container>
            <Tabs id="controlled-tab-example" defaultActiveKey="distributionPermissions" className="mb-3">
              <Tab eventKey="distributionPermissions" title={lang.USER_PERMISSIONS}>
                <div className="tab-container">
                  {isNew
                    ? selectedPermissions.map(po => {
                        return (
                          <Form.Group controlId="formGridPermissions" key={po.langKey}>
                            <Form.Check
                              type="checkbox"
                              label={lang[po.langKey]}
                              onChange={() => {
                                toggleSelection(po);
                              }}
                            />
                          </Form.Group>
                        );
                      })
                    : selectedPermissions.map(po => {
                        return (
                          <Form.Group controlId="formGridPermissions" key={po.langKey}>
                            <Form.Check
                              type="checkbox"
                              checked={po.selected}
                              label={lang[po.langKey]}
                              onChange={() => {
                                toggleSelection(po);
                              }}
                            />
                          </Form.Group>
                        );
                      })}
                </div>
              </Tab>
              <Tab eventKey="distributionUsers" title={lang.GLOBAL_USERS}>
                <UserSectionForm
                  lang={lang}
                  addToUserList={addToUserList}
                  deleteFromUserList={deleteFromUserList}
                  userList={userList}
                  toggleOpenUserForm={toggleOpenUserForm}
                  openUserForm={openUserForm}
                />
              </Tab>
              <Tab eventKey="distributionEmails" title="Email">
                <div className="tab-container">
                  <EmailSectionForm
                    lang={lang}
                    addToEmailList={addToEmailList}
                    deleteFromEmailList={deleteFromEmailList}
                    emailList={emailList}
                  />
                </div>
              </Tab>
            </Tabs>
          </Container>
        </Row>
        <Modal.Footer>
          <Button variant="success" type="submit">
            {lang.GLOBAL_SAVE_BUTTON}
          </Button>
          <Button variant="secondary" onClick={cancelBtn}>
            {lang.GLOBAL_CANCEL_BUTTON}
          </Button>
        </Modal.Footer>
      </Form>
    </div>
  );
};

export default DistributionForm;
