import { combineReducers } from 'redux';
import login from './login/reducer';
import lang from './languageHandle/reducer';
import sidebar from './sidebarHandle/reducer';

const appState = combineReducers({
  login,
  lang,
  sidebar
});

export default appState;

export type AppState = ReturnType<typeof appState>;
