import { Image } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../client-redux/reducers';
import { Language } from '../../languages/languageHandler';
import { Link, useNavigate } from 'react-router-dom';
import { logout } from '../../client-redux/login';
import logoBg from '../../../assets/img/zarph_white_horiz.png';
import logoSml from '../../../assets/img/zarph_Z_logo_white.png';
import userLogo from '../../../assets/img/user-profile.svg';
import exitLogo from '../../../assets/img/exit.svg';
import sidebarHamburger from '../../../assets/img/menu-sidebar.svg';

import './sidebar.scss';
import { collapse, open, updateActiveTab } from '../../client-redux/sidebarHandle';
import { useWindowSize } from '../../utils/script';
import { useEffect, useState } from 'react';
import { ViewMapProps } from '../../utils/ViewMapProps';

export const Sidebar = () => {
  const views = useSelector<AppState, ViewMapProps[]>(state => state.sidebar.views);
  const sidebarCollapsed = useSelector<AppState, boolean>(state => state.sidebar.isCollapsed);
  const [isLargeScreen, setIsLargeScreen] = useState<boolean>(false);
  const lang = useSelector<AppState, Language>(state => state.lang.lang);
  const activeTab = useSelector<AppState, string>(state => state.sidebar.activeTab);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { width } = useWindowSize();

  const [updatedWidth, setUpdatedWidth] = useState<number>(width);

  console.log('Screen Width: ', width);

  /* const clickAction = (link: string, label?: string) => {
    if (label) {
      dispatch(updateActiveTab(label));
    }

    navigate(link);
  }; */

  useEffect(() => {
    console.log('updatedWidth: ', updatedWidth);
    if (width !== updatedWidth) {
      setUpdatedWidth(width);
      if (width >= 2560) {
        setIsLargeScreen(true);
        dispatch(open());
      } else {
        setIsLargeScreen(false);
      }
    }
  }, [dispatch, updatedWidth, width]);

  const renderViews = () => {
    return views.map((element, index) => {
      return (
        <Link
          to={element.link}
          onClick={() => dispatch(updateActiveTab(element.label))}
          className={`nav-tab ${sidebarCollapsed ? 'collapsed' : ''} ${activeTab === element.label ? 'active' : ''}`}
          key={index}
          id="sidebar-tab"
        >
          <div className={`nav-tab-left ${activeTab === element.label ? 'active' : ''}`}>
            <Image key={element.icon} src={element.icon} className="sidebar-icon" />
          </div>
          {element.label && !sidebarCollapsed ? (
            <div className="nav-tab-right">
              <span className="sidebar-label">{lang[element.label]}</span>
            </div>
          ) : null}
        </Link>
      );
    });
  };

  const logoutOper = () => {
    dispatch(logout());
    navigate('/login');
  };

  const toggleSidebar = () => {
    console.log('toggleSidebar clicked!');
    if (sidebarCollapsed) {
      dispatch(open());
    } else {
      dispatch(collapse());
    }
  };

  const handleMouseLeave = () => {
    console.log('isLargeScreen: ', isLargeScreen);
    if (!isLargeScreen && !sidebarCollapsed) {
      console.log('handleMouseLeave && !isLargeScreen');
      toggleSidebar();
    }
  };

  return (
    <>
      <div className={`sidebar ${!sidebarCollapsed ? `sidebar-on` : 'sidebar-off'}`} onMouseLeave={handleMouseLeave}>
        <div className={`sidebar-top-section ${sidebarCollapsed ? 'collapsed' : ''}`}>
          {sidebarCollapsed && (
            <div className="sidebar-top-logo-sml">
              <Image src={logoSml} className="logo-sml" />
            </div>
          )}
          <div className="sidebar-top-left">
            <button className="sidebar-btn" onClick={toggleSidebar}>
              <Image src={sidebarHamburger} id="sidebar-open-btn-img" />
            </button>
          </div>
          {!sidebarCollapsed && (
            <div className="sidebar-top-right">
              <Image src={logoBg} className="brandLogo" />
            </div>
          )}
        </div>
        <div className="sidebar-content-section">
          <div className="sidebar-tabs">{renderViews()}</div>
          <div className="sidebar-content-bottom">
            <div className="sidebar-content-bottom-profile-section">
              <Link
                to="/profile"
                className={`nav-tab ${sidebarCollapsed ? 'collapsed' : ''} ${
                  activeTab === lang.USER_PROFILE ? 'active' : ''
                }`}
                id="profile-tab"
                onClick={() => dispatch(updateActiveTab(lang.USER_PROFILE))}
              >
                <div className={`nav-tab-left ${activeTab === lang.USER_PROFILE ? 'active' : ''}`}>
                  <Image key={userLogo} src={userLogo} className="sidebar-icon" />
                </div>
                {!sidebarCollapsed ? (
                  <div className="nav-tab-right">
                    <span className="sidebar-label">{lang.USER_PROFILE}</span>
                  </div>
                ) : null}
              </Link>
            </div>
            <div className={`sidebar-bottom ${sidebarCollapsed ? 'collapsed' : ''}`}>
              <Link onClick={logoutOper} className={`nav-tab ${sidebarCollapsed ? 'collapsed' : ''}`} to={'/login'}>
                <div className="nav-tab-left">
                  <Image src={exitLogo} className="sidebar-icon" />
                </div>
                {!sidebarCollapsed ? (
                  <div className="nav-tab-right">
                    <span className="sidebar-label">{lang.GLOBAL_LOGOUT}</span>
                  </div>
                ) : null}
              </Link>
            </div>
            <span className={`version`}>{process.env.REACT_APP_VERSION}</span>
          </div>
        </div>

        {/* <div className="w-100">
          <Nav.Link className="navBrand">
            <Image src={!sidebarCollapsed || isLargeScreen ? logoBg : logoSml} id="sidebarLogo" alt="Zarph logo" />
          </Nav.Link>
          <div className="w-100 mt-5">
            <embed type="image/svg+xml" key={sidebarOpenButton} src={sidebarOpenButton} className="sidebar-icon" />
          </div>
          <div className="w-100 mt-5">{renderViews()}</div>
        </div>
        <div className="w-100 h-15 d-flex flex-column justify-content-between">
          <Nav.Link href="/profile" className="navTabLink">
            <div className="d-flex align-items-center">
              <embed type="image/svg+xml" key={userLogo} src={userLogo} className="sidebar-icon" />
              {!sidebarCollapsed ? <span className="ps-2 link-text">{lang.USER_PROFILE}</span> : null}
            </div>
          </Nav.Link>
          <Nav.Link href="/login" onClick={logoutOper} className="navLogout">
            <div className="d-flex align-items-center">
              <embed type="image/svg+xml" src={exitLogo} className="sidebar-icon" />
              {!sidebarCollapsed ? <span>{lang.GLOBAL_LOGOUT}</span> : null}
            </div>
          </Nav.Link>
        </div> */}
      </div>
    </>
  );
};
