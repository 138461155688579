import axios from 'axios';
import { IDonation } from '../donation/DonationService';
import { IEvent } from '../events/EventService';
import { IGlass } from '../glass/GlassService';
import { ApiResponse, Page, zps_vi_url } from '../zps';

const PROCESSED_GLASS_BASE_URL = `${zps_vi_url}/movement`;

export interface IProcessedGlassId {
  eid: number;
  period: number;
  id: number;
}

export interface IProcessedGlass {
  id: IProcessedGlassId;
  dateTime: Date;
  status: string;
  error: string;
  event?: IEvent;
  glass?: IGlass;
  refundType?: string;
  code?: number;
  donation?: IDonation;
  cycle?: number;
  tube?: number;
  comStatus?: number;
}

export interface IProcessedGlassResponse extends ApiResponse {
  page: Page<IProcessedGlass>;
}

export const fetchMovements = async (pageNumber: number, pageSize: number) => {
  const response = await axios.get(`${PROCESSED_GLASS_BASE_URL}/list?pageNumber=${pageNumber}&pageSize=${pageSize}`);
  return response.data;
};

export const fetchFilteredMovements = async (
  pageNumber: number,
  pageSize: number,
  beginning: string,
  end: string,
  eid?: number,
  status?: string,
  eventId?: number,
  glassCode?: string,
  donationId?: number
) => {
  const response = await axios.get(
    `${PROCESSED_GLASS_BASE_URL}/list?pageNumber=${pageNumber}&pageSize=${pageSize}&beginning=${beginning}&end=${end}${
      eid === undefined ? '' : `&eid=${eid}`
    }${status === undefined ? '' : `&status=${status}`}${eventId === undefined ? '' : `&eventId=${eventId}`}${
      glassCode === undefined ? '' : `&glassCode=${glassCode}`
    }${donationId === undefined ? '' : `&donationId=${donationId}`}`
  );
  return response;
};

export const fetchEidsInMovementList = async () => {
  const response = await axios.get(`${PROCESSED_GLASS_BASE_URL}/eid_list`);
  return response.data;
};

export const fetchEventsInMovementList = async () => {
  const response = await axios.get(`${PROCESSED_GLASS_BASE_URL}/event_list`);
  return response.data;
};

export const fetchGlassesInMovementsList = async () => {
  const response = await axios.get(`${PROCESSED_GLASS_BASE_URL}/glass_list`);
  return response.data;
};

export const fetchDonationsInMovementsList = async () => {
  const response = await axios.get(`${PROCESSED_GLASS_BASE_URL}/donation_list`);
  return response.data;
};
