import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { fetchDashboardInfo, IDashboardResponse } from '../../../libraries/dashboard';
import { AppState } from '../../client-redux/reducers';
import { Language } from '../../languages/languageHandler';
import Header from '../ui/Header';
import './styles.scss';

const Dashboard = () => {
  const lang = useSelector<AppState, Language | undefined>(state => state.lang.lang);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [entid, setEntid] = useState<number>(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [sid, setSid] = useState<number>(0);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [message, setMessage] = useState<IDashboardResponse>();

  useEffect(() => {
    fetchDashboardInfo(entid, sid).then(response => {
      if (response.statusOper.code === 0) {
        setMessage(response);
      }
    });
  }, [entid, sid]);

  return (
    <Container fluid>
      <Header pageTitle={lang?.DASHBOARD} />
    </Container>
  );
};

export default Dashboard;
