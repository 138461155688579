import React, { FormEvent, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { IUser, PermissionOption, updateUser } from '../../../libraries/users/index';
import { Language } from '../../languages/languageHandler';
import UserForm from './UserForm';
import './styles.scss';
import { permissionsToUser } from './permissionsAux';

interface UpdateProps {
  user: IUser;
  toggleUpdate: () => void;
  lang: Language;
  permissionOptions: PermissionOption[];
}

const UpdateContent = ({ user, toggleUpdate, lang, permissionOptions }: UpdateProps) => {
  const queryClient = useQueryClient();

  const updater = useMutation(updateUser);

  const [userAttributes] = useState<IUser>(user);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (userAttributes !== null) {
      let userData: IUser = {
        entid: userAttributes.entid,
        uid: userAttributes.uid,
        name: userAttributes.name,
        mobile: userAttributes.mobile,
        email: userAttributes.email,
        card: userAttributes.card,
        lang: userAttributes.lang,
        region: userAttributes.region,
        password: userAttributes.password,
        pin: userAttributes.pin
      };

      console.log('update user permissionOptions:', permissionOptions);

      permissionsToUser(permissionOptions, userData);

      console.log('update user data:', userData);

      updater.mutateAsync({ user: userData });

      queryClient.resetQueries();

      queryClient.fetchQuery([]);

      queryClient.getQueryData(['user', userData.uid]);
      queryClient.fetchQuery('users');

      toggleUpdate();
    }
  };

  return (
    <>
      <UserForm
        lang={lang}
        isNew={false}
        userAttributes={userAttributes}
        submitForm={e => handleSubmit(e)}
        cancelBtn={toggleUpdate}
        permissionOptions={permissionOptions}
      />
    </>
  );
};

export default UpdateContent;
