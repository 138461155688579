import axios from 'axios';
import { ApiResponse, zps_ea_war } from '../zps';

const DASHBOARD_BASE_URL = `${zps_ea_war}/equipment/dashboard/`;

export type IBill = {
  0: number;
  1: number;
  2: number;
  3: number;
  4: number;
  5: number;
  6: number;
};

export type ICoin = {
  0: number;
  1: number;
  2: number;
  3: number;
  4: number;
  5: number;
  6: number;
  7: number;
};

export type ISafe = {
  bills: number;
  coins: number;
};

export type IRecycler = {
  bills: IBill[];
  coins: ICoin[];
};

export type IDashboardEquipment = {
  cid: number;
  description: string;
  eid: number;
  entid: number;
  recyclers: IRecycler[];
  safe: ISafe;
  sid: number;
  state: number;
  totalAmount: number;
};

export interface IDashboardResponse extends ApiResponse {
  equipments: IDashboardEquipment[];
  type: string;
}

export const fetchDashboardInfo = async (entid: number, sid: number) => {
  const { data } = await axios.get(`${DASHBOARD_BASE_URL}/details/?entid=${entid}&sid=${sid}`);

  return data;
};
