import React, { FormEvent, useEffect, useState } from 'react';
import { Modal, Form, Col, Button, Row } from 'react-bootstrap';
import { useMutation, useQueryClient } from 'react-query';
import { getCategoryById, getGlassesWithoutImage, IGlassCategory } from '../../../libraries/glass/GlassService';
import EventGlassService from '../../../libraries/eventglass/EventGlassService';
import './styles.scss';
import { Language } from '../../languages/languageHandler';

interface FormProps {
  cancelBtn: () => void;
  eventId: number;
  lang: Language;
}

const EventGlassForm = ({ cancelBtn, eventId, lang }: FormProps) => {
  const [glasses, setGlasses] = useState<any[]>();

  const [glassCategory, setGlassCategory] = useState<IGlassCategory>();

  useEffect(() => {
    getGlassesWithoutImage().then(response => {
      setGlasses(response.list);
    });
  }, []);

  const handleGlassSelection = (glassId: number) => {
    if (glasses) {
      const selectedGlass = glasses.find(glass => Number(glass.glassId) === Number(glassId));
      if (selectedGlass) {
        getCategoryById(selectedGlass.category).then(response => {
          setGlassCategory(response.record);
        });
      }
    }
  };

  const queryClient = useQueryClient();

  const submition = useMutation(EventGlassService.createEventGlass);

  async function submitEventGlass(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (!glasses) return;

    const { eventId, glassId, receptacle, deliveryAnimation } = e.target as typeof e.target & {
      eventId: { value: number };
      glassId: { value: number };
      receptacle: { value: number };
      deliveryAnimation: { value: number };
    };

    const glass = glasses.find(glass => Number(glass.glassId) === Number(glassId.value));

    if (!!glass && glassCategory) {
      await submition.mutateAsync({
        eventGlassId: {
          eventId: eventId.value,
          glass: {
            glassId: glass.glassId,
            glassCode: glass.glassCode,
            name: glass.name,
            image: glass.image,
            category: glassCategory
          }
        },
        receptacle: Number(receptacle.value),
        deliveryAnimation: deliveryAnimation.value
      });
    }

    queryClient.invalidateQueries('eventGlasses');

    cancelBtn();
  }

  return (
    <div>
      <Form onSubmit={submitEventGlass}>
        <div className="modal-heading">
          <h5>{lang.EVENT_GLASS_FORM_TITLE}</h5>
        </div>
        <Form.Control value={eventId} name="eventId" hidden />
        <Form.Group className="mb-2" as={Col} controlId="formGridGlass">
          <Form.Label>{lang.EVENT_GLASS_FORM_GLASS}:</Form.Label>
          <Form.Control as="select" name="glassId" onChange={event => handleGlassSelection(Number(event.target.value))}>
            {glasses ? (
              glasses?.map(glass => <option value={glass.glassId}>{glass.name}</option>)
            ) : (
              <option>No Glasses Available</option>
            )}
          </Form.Control>
        </Form.Group>
        <Row className="mb-2">
          <Form.Group as={Col} controlId="formGridReceptacle">
            <Form.Label>{lang.EVENT_GLASS_FORM_SELECT_RECEPTACLE}:</Form.Label>
            <Form.Control as="select" name="receptacle">
              <option value="1">{lang.EVENT_GLASS_FORM_RECEPTACLE_1}</option>
              <option value="2">{lang.EVENT_GLASS_FORM_RECEPTACLE_2}</option>
            </Form.Control>
          </Form.Group>
          <Form.Group as={Col} controlId="formGridReceptacle">
            <Form.Label>{lang.EVENT_GLASS_FORM_DELIVERY}:</Form.Label>
            <Form.Control as="select" name="deliveryAnimation">
              <option value="1">{lang.EVENT_GLASS_FORM_RIGHT}</option>
              <option value="2">{lang.EVENT_GLASS_FORM_LEFT}</option>
            </Form.Control>
          </Form.Group>
        </Row>
        <Modal.Footer>
          <Button variant="success" type="submit">
            {lang.GLOBAL_SAVE_BUTTON}
          </Button>
          <Button variant="secondary" onClick={cancelBtn}>
            {lang.GLOBAL_CANCEL_BUTTON}
          </Button>
        </Modal.Footer>
      </Form>
    </div>
  );
};

export default EventGlassForm;
