import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Col, Modal, Row, Table } from 'react-bootstrap';
import { fetchEquipmentByEid, IEquipmentDeviceStatus, IEquipmentStatusResponse } from '../../../libraries/equipment';
import { Language } from '../../languages/languageHandler';
import ModalComponent from '../modal/ModalComponent';

interface ShowEquipmentProps {
  eid: number;
  openDetails: boolean;
  hideBtn: () => void;
  lang: Language;
}

const ShowEquipment = ({ eid, openDetails, hideBtn, lang }: ShowEquipmentProps) => {
  const [message, setMessage] = useState<IEquipmentStatusResponse>();
  const [statusList, setStatusList] = useState<IEquipmentDeviceStatus[]>();
  useEffect(() => {
    fetchEquipmentByEid(eid).then(response => {
      setMessage(response);
      setStatusList(response.status.statusList);
    });
  }, [eid]);

  const checkIfOnline = (status?: number) => {
    if (status === 4) {
      return 'Offline';
    } else {
      return 'Online';
    }
  };

  return (
    <>
      <ModalComponent
        show={openDetails}
        handleClick={hideBtn}
        animation={true}
        title={`EID: ${eid}`}
        size={'xl'}
        content={
          <>
            <Table responsive className="show-equipment-table">
              <thead>
                <tr>
                  <th>{lang.EQUIPMENT_COM_STATUS}</th>
                  <th>{lang.EQUIPMENT_STATE}</th>
                  <th>{lang.EQUIPMENT_LAST_COM_DATE}</th>
                  <th>{lang.EQUIPMENT_LAST_OPERATION}</th>
                  <th>{lang.EQUIPMENT_LAST_OPERATION_DATE}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{checkIfOnline(message?.status.status)}</td>
                  <td>
                    {message?.status.inService ? lang.EQUIPMENT_STATE_IN_SERVICE : lang.EQUIPMENT_STATE_OUT_OF_SERVICE}
                  </td>
                  <td>{moment(message?.status.lastComDt).format('DD/MM/YYYY HH:mm:ss')}</td>
                  <td>{message?.status.lastOperation}</td>
                  <td>{moment(message?.status.lastOperationDt).format('DD/MM/YYYY HH:mm:ss')}</td>
                </tr>
              </tbody>
            </Table>
            <Table className="status-list-table">
              <thead>
                <tr className="show-equipment-table-break">
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {statusList?.map((status, index) => (
                  <tr key={index}>
                    <td className="status-list-cell">{`${status.idLangKey}`}</td>
                    <td className="status-list-cell">{`${status.statusLangKey}`}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Row>
              <Col>IP</Col>
              <Col>N/A</Col>
            </Row>
            <Row>
              <Col>IP Local</Col>
              <Col>{message?.status.lastLocalIp}</Col>
            </Row>
            <Row>
              <Col>Software Version</Col>
              <Col>{message?.status.softwareVersion}</Col>
            </Row>
            <Modal.Footer>
              <Button className="updateBtn">{lang.GLOBAL_REBOOT}</Button>
              <Button className="updateBtn">{lang.GLOBAL_SHUT_DOWN}</Button>
              <Button className="updateBtn">{lang.GLOBAL_OPEN_ESCROW}</Button>
              <Button className="updateBtn">{lang.GLOBAL_CLOSE_ESCROW}</Button>
              <Button className="updateBtn">{lang.EQUIPMENT_STATE_OUT_OF_SERVICE}</Button>
              <Button className="updateBtn">{lang.EQUIPMENT_STATE_IN_SERVICE}</Button>
            </Modal.Footer>
          </>
        }
      />
    </>
  );
};

export default ShowEquipment;
