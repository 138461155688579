import React, { FormEvent, useEffect, useState } from 'react';
import { Button, Col, Form, InputGroup, Row, Table } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import { useSelector } from 'react-redux';
import {
  deleteCategory,
  getAllCategoriesPage,
  getCategoryById,
  IGlassCategory
} from '../../../libraries/glass/GlassService';
import { Page } from '../../../libraries/zps';
import { AppState } from '../../client-redux/reducers';
import { Language } from '../../languages/languageHandler';
import PaginationComponent from '../pagination/PaginationComponent';
import Header from '../ui/Header';
import NewCategory from './NewCategory';
import './styles.scss';
import UpdateCategory from './UpdateCategory';

const Categories = () => {
  const lang = useSelector<AppState, Language | undefined>(state => state.lang.lang);

  const [page, setPage] = useState<Page<IGlassCategory>>();
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);

  const [selectedKeyword, setSelectedKeyword] = useState<string | undefined>();

  const [activeRow, setActiveRow] = useState<number | null>(null);
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const [openNew, setOpenNew] = useState<boolean>(false);
  const [openUpdate, setOpenUpdate] = useState<boolean>(false);

  const [selectedCategory, setSelectedCategory] = useState<IGlassCategory>();

  useEffect(() => {
    getAllCategoriesPage(pageNumber, pageSize, selectedKeyword).then(response => {
      console.log('categories: ', response);
      setPage(response.page);
    });

    if (activeRow) {
      getCategoryById(activeRow).then(response => {
        setSelectedCategory(response);
      });
    }
  }, [activeRow, pageNumber, pageSize, selectedKeyword]);

  const submitSearch = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const { keyword } = e.target as typeof e.target & {
      keyword: { value: string };
    };

    keyword.value ? setSelectedKeyword(keyword.value) : setSelectedKeyword(undefined);

    setPageNumber(0);
  };

  const changePage = (n: number) => {
    setPageNumber(n);
  };

  const resultsSelect = (e: any) => {
    setPageSize(e.target.value);
  };

  const isActive = (glassId: number): boolean => {
    if (!activeRow) return false;

    return glassId === activeRow;
  };

  const handleToggle =
    (categoryId: number | undefined): (() => void) =>
    () => {
      if (categoryId) {
        setActiveRow(categoryId);
        setIsSelected(!isSelected);
      }
    };

  function toggleNewCategoryModal() {
    setOpenNew(!openNew);
  }

  function toggleUpdateCategoryModal(categoryId: number) {
    setActiveRow(null);
    setActiveRow(categoryId);
    setOpenUpdate(!openUpdate);
  }

  const deleteSelectedCategory = (categoryId: number) => {
    deleteCategory(categoryId);
  };

  return (
    <>
      <div className="index-container">
        {lang && <NewCategory lang={lang} isNew={openNew} hideBtn={() => toggleNewCategoryModal()} />}
        {lang && activeRow && selectedCategory && (
          <UpdateCategory
            lang={lang}
            openUpdate={openUpdate}
            hideBtn={() => toggleUpdateCategoryModal(activeRow)}
            categoryId={activeRow}
            selectedCategory={selectedCategory}
          />
        )}
        <div className="top-index-section">
          <div className="top-left">
            <Header pageTitle={lang?.CATEGORIES} />
          </div>
          <div className="top-right-categories">
            <button className="btn btn-md btn-table add-btn" onClick={() => toggleNewCategoryModal()}>
              <Icon.Plus />
              &nbsp;{lang?.GLOBAL_ADD_BUTTON}&nbsp;
            </button>
          </div>
        </div>
        <div className="categories-content-section">
          <div className="top-content-section">
            <div className="search-section">
              <Form className="search-form" onSubmit={e => submitSearch(e)}>
                <Row>
                  <Col lg={6}>
                    <InputGroup>
                      <InputGroup.Text>
                        <Icon.Search />
                      </InputGroup.Text>
                      <Form.Control size="sm" name="keyword" placeholder={lang?.CATEGORY} autoComplete="off" />
                    </InputGroup>
                  </Col>
                  <Col>
                    <Button size="sm" type="submit">
                      {lang?.GLOBAL_SEARCH}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
            <div className="results-section">
              <span>{lang?.GLOBAL_SHOWING} </span>
              <Form>
                <Form.Select className="results-select" size="sm" name="results" onChange={e => resultsSelect(e)}>
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                </Form.Select>
              </Form>
              <span> {lang?.GLOBAL_RESULTS}</span>
            </div>
          </div>
          <div className="index-table">
            <Table size="md" className="data-table">
              <thead className="table-head">
                <tr className="table-head-row">
                  <th id="categoryId">#</th>
                  <th id="categoryName">{lang?.CATEGORY}</th>
                  <th id="categoryUpdate"></th>
                  <th id="categoryDelete"></th>
                </tr>
              </thead>
              <tbody>
                {console.log('categories page: ', page)}
                {page?.content?.map((category: IGlassCategory) => (
                  <tr
                    key={category.categoryId}
                    className={`table-row ${category.categoryId && isActive(category.categoryId) ? 'activeRow' : ''}`}
                    onClick={handleToggle(category.categoryId)}
                  >
                    <td>{category.categoryId}</td>
                    <td>{category.description}</td>
                    <td>
                      <button className="update-btn" onClick={() => toggleUpdateCategoryModal(category.categoryId)}>
                        <Icon.PencilSquare />
                      </button>
                    </td>
                    <td>
                      <button className="delete-btn" onClick={() => deleteSelectedCategory(category.categoryId)}>
                        <Icon.Trash />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div className="pagination-section">
              {
                <PaginationComponent
                  total={page?.totalElements}
                  itemsPerPage={pageSize}
                  currentPage={pageNumber}
                  onPageChange={changePage}
                />
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Categories;
