import axios from 'axios';
import { ApiResponse, zps_ea_war } from '../zps';

const EQUIP_BASE_URL = `${zps_ea_war}/equipment`;

export type IEquipment = {
  almostFullPerc: number;
  almostFullValue: number;
  bag: boolean;
  bankid: number;
  cardInsertionTimeout: number;
  cardRemovalTimeout: number;
  comuid: number;
  currencies: ICurrency[];
  dataInsertionTimeout: number;
  declarationTimeout: number;
  depositTimeout: number;
  description: string;
  eid: number;
  entid: number;
  equiId: number;
  etid: number;
  etvid: number;
  fullPerc: number;
  fullValue: number;
  fullValueCid: number;
  gid: number;
  idTerminal: number;
  inuse: number;
  lang: number;
  languages: IEquipmentLanguage[];
  lid: number;
  maintid: number;
  maintid2: number;
  maxBills: number;
  maxCoins: number;
  p43sf8: number;
  region: string;
  sa1: number;
  sa2: number;
  sa3: number;
  sa4: number;
  sa5: number;
  sa6: number;
  sa7: number;
  sa8: number;
  secur: number;
  serialnumber: string;
  sid: number;
  status: number;
  suppid: number;
  token: string;
  zkey: string;
  amount: string;
};

export type IEquipmentState = {
  cardreaderState: number;
  depositToRecover: boolean;
  diskUsage: number;
  equipmentState: number;
  fakeNoteDetected: boolean;
  moosState: number;
  paySystemState: number;
  printerState: number;
  quantityState: number;
  switchs: number;
  upsState: number;
  valueState: number;
};

export type IEquipmentLanguage = {
  eid: number;
  gid: number;
  langid: number;
  langidx: number;
};

export type ICurrency = {
  cid: number;
  eid: number;
  gid: number;
  maxAmount: number;
  minAmount: number;
};

export type IPeriodAmount = {
  amount: number;
  amountBilled: number;
  cid: 1;
  envTotals: number;
};

export type IEquipmentDetails = {
  equipment: IEquipment;
  equipmentState: IEquipmentState;
  lastComDt: Date;
  lastLocalIp: string;
  lastOperation: string;
  lastOperationDt: Date;
  period: number;
  periodAmounts: IPeriodAmount[];
  softwareVersion: string;
};

export interface IEquipmentStatusResponse extends ApiResponse {
  status: {
    inService: boolean;
    lastComDt: Date;
    lastLocalIp: string;
    lastOperation: string;
    lastOperationDt: Date;
    softwareVersion: string;
    status: number;
  };
  statusList: IEquipmentDeviceStatus[];
}

export type IEquipmentDeviceStatus = {
  id: string;
  idLangKey: string;
  level: string;
  statusLangKey: string;
};

export interface IEquipmentListResponse extends ApiResponse {
  equipments: IEquipmentDetails[];
  type: string;
}

export const fetchEquipmentList = async (entid: number, sid: number) => {
  const { data } = await axios.get(`${EQUIP_BASE_URL}/details/?entid=${entid}&sid=${sid}`);

  return data;
};

export const fetchEquipmentByEid = async (eid: number) => {
  const { data } = await axios.get(`${EQUIP_BASE_URL}/status?eid=${eid}`);

  return data;
};
