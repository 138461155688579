import { ViewMapProps } from '../../utils/ViewMapProps';
import { AppAction } from '../actions';
import { ActionTypes } from './actionTypes';

export interface SidebarState {
  isCollapsed: boolean;
  activeTab: string;
  views: ViewMapProps[];
}

const initialState: SidebarState = { isCollapsed: true, activeTab: 'GLOBAL_EQUIPMENT', views: [] };

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action: AppAction<any>): SidebarState => {
  switch (action.type) {
    case ActionTypes.OPEN: {
      return {
        ...state,
        ...action.payload,
        isCollapsed: false
      };
    }
    case ActionTypes.COLLAPSE: {
      return {
        ...state,
        ...action.payload,
        isCollapsed: true
      };
    }
    case ActionTypes.UPDATE_ACTIVE_TAB: {
      return {
        ...state,
        activeTab: action.payload
      };
    }
    case ActionTypes.SAVE_VIEWS: {
      return {
        ...state,
        views: action.payload
      };
    }
    default:
      return state;
  }
};
