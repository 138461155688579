import React, { useState, useRef, useEffect } from 'react';
import { Modal, Form, Col, Row, Button, Image } from 'react-bootstrap';
import { getAllCategories, IGlass, IGlassCategory } from '../../../libraries/glass/GlassService';
import { set } from 'lodash';
import './styles.scss';
import { Language } from '../../languages/languageHandler';

interface FormProps {
  isNew: boolean;
  glassAttributes: IGlass | null;
  submitForm: (e: any) => void;
  handleImageUpload?: (e: any) => void;
  imageUploader?: any;
  uploadedImage?: any;
  cancelBtn: () => void;
  lang: Language;
  handleCategoryChange: (e: number) => void;
}

const GlassForm = ({
  isNew,
  glassAttributes,
  submitForm,
  handleImageUpload,
  imageUploader,
  uploadedImage,
  cancelBtn,
  lang,
  handleCategoryChange
}: FormProps) => {
  const [updatedAttributes, setUpdatedAttributes] = useState<IGlass | null>(glassAttributes);

  const [categories, setCategories] = useState<IGlassCategory[]>();

  const updatedImage = useRef<any>(`data:image/jpeg;base64,${glassAttributes?.image}`);
  const imageUpdater = useRef<any>(null);

  useEffect(() => {
    getAllCategories().then(response => {
      setCategories(response);
    });
  }, []);

  const handleChange = (fieldPath: string) => (event: any) => {
    if (!updatedAttributes) return;

    if (fieldPath === 'image') {
      const [file] = event.target.files;

      if (file !== null || file !== undefined) {
        const reader = new FileReader();

        const { current } = updatedImage;

        current.file = file;

        reader.onload = (e: ProgressEvent<FileReader>) => {
          if (e.target) {
            current.src = e.target.result;

            if (reader.result) {
              const base64String: string = reader.result.toString().replace('data:', '').replace(/^.+,/, '');

              set(updatedAttributes, fieldPath, base64String);

              setUpdatedAttributes({
                glassCode: updatedAttributes.glassCode,
                name: updatedAttributes.name,
                image: base64String,
                category: updatedAttributes.category
              });
            }
          }
        };

        reader.readAsDataURL(file);
      }
    }
    if (fieldPath !== 'image') {
      set(updatedAttributes, fieldPath, event?.target?.value);
    }
  };

  return (
    <div>
      <Form onSubmit={submitForm}>
        <Row className="mb-2">
          <Form.Group as={Col} controlId="formGridGlassId">
            <Form.Label>{lang.GLASS_CODE}:</Form.Label>
            {isNew ? (
              <Form.Control size="sm" required name="glassCode" placeholder={lang.GLASS_CODE_PLACEHOLDER} />
            ) : (
              <Form.Control readOnly size="sm" value={glassAttributes?.glassCode} name="glassId" />
            )}
          </Form.Group>
          <Form.Group as={Col} controlId="formGridName">
            <Form.Label>{lang.GLASS_DESCRIPTION}:</Form.Label>
            {isNew ? (
              <Form.Control size="sm" name="name" placeholder={lang.GLASS_DESCRIPTION_PLACEHOLDER} />
            ) : (
              <Form.Control
                size="sm"
                name="name"
                defaultValue={glassAttributes?.name}
                onChange={handleChange('name')}
              />
            )}
          </Form.Group>
          <Form.Group as={Col} controlId="formGridSizeType">
            <Form.Label>{lang.GLASS_SIZE_TYPE}:</Form.Label>

            {isNew ? (
              <Form.Select
                size="sm"
                name="category"
                onChange={event => handleCategoryChange(Number(event.target.value))}
              >
                {categories?.map((category, index) => (
                  <option key={index} value={category.categoryId}>
                    {category.description}
                  </option>
                ))}
              </Form.Select>
            ) : (
              <Form.Select
                size="sm"
                name="category"
                defaultValue={glassAttributes?.category.categoryId}
                onChange={event => handleCategoryChange(Number(event.target.value))}
              >
                {categories?.map((category, index) => (
                  <option key={index} value={category.categoryId}>
                    {category.description}
                  </option>
                ))}
              </Form.Select>
            )}
          </Form.Group>
        </Row>

        <Row className="mb-2">
          <Form.Group as={Col} controlId="formGridImage">
            <Form.Label>{lang.GLASS_SELECT_IMAGE}</Form.Label>
            {isNew ? (
              <Form.Control
                size="sm"
                type="file"
                name="image"
                placeholder="Select an image"
                ref={imageUploader}
                onChange={handleImageUpload}
              />
            ) : (
              <Form.Control size="sm" type="file" name="image" ref={imageUpdater} onChange={handleChange('image')} />
            )}
          </Form.Group>
        </Row>

        <Row className="mb-2 previewContainer">
          {isNew ? (
            <Image className="previewImage" ref={uploadedImage} />
          ) : (
            <Image className=" previewImage" ref={updatedImage} />
          )}
        </Row>

        <Modal.Footer>
          <Button variant="success" type="submit">
            {lang.GLOBAL_SAVE_BUTTON}
          </Button>
          <Button variant="secondary" onClick={cancelBtn}>
            {lang.GLOBAL_CANCEL_BUTTON}
          </Button>
        </Modal.Footer>
      </Form>
    </div>
  );
};

export default GlassForm;
