import axios from 'axios';
import { Page, zps_vi_url } from '../zps';

const GLASS_BASE_URL = `${zps_vi_url}/glass`;

export type IGlassCategory = {
  categoryId: number;
  description: string;
};

export type IGlass = {
  glassId?: number;
  glassCode: string;
  name: string;
  image: string;
  category: IGlassCategory;
};

export type IGlassWithoutImage = {
  glassId?: number;
  glassCode: string;
  name: string;
  sizeType: number;
};

export interface IGlassResponse {
  page: Page<IGlass>;
}

export const getGlasses = async (pageNumber: number, pageSize: number, keyword?: String, category?: number) => {
  const response = await axios.get(
    `${GLASS_BASE_URL}es/page?pageNumber=${pageNumber}&pageSize=${pageSize}${
      keyword === undefined ? '' : `&keyword=${keyword}`
    }${category === undefined ? '' : `&category=${category}`}`
  );
  return response.data;
};

export const getGlassesWithoutImage = async () => {
  const response = await axios.get(`${GLASS_BASE_URL}es`);
  return response.data;
};

//Glass Categories

export const getAllCategories = async () => {
  const response = await axios.get(`${GLASS_BASE_URL}/categories`);
  return response.data;
};

export const getAllCategoriesPage = async (pageNumber: number, pageSize: number, keyword?: String) => {
  const response = await axios.get(
    `${GLASS_BASE_URL}/categories/page?pageNumber=${pageNumber}&pageSize=${pageSize}${
      keyword === undefined ? '' : `&keyword=${keyword}`
    }`
  );
  return response.data;
};

export const newCategory = async (category: IGlassCategory) => {
  const response = await axios.post(`${GLASS_BASE_URL}/categories/new`, category);
  return response;
};

export const updateCategory = async (category: IGlassCategory) => {
  const response = await axios.put(`${GLASS_BASE_URL}/categories/update`, category);
  return response;
};

export const getCategoryById = async (categoryId: Number) => {
  const response = await axios.get(`${GLASS_BASE_URL}/category/${categoryId}`);
  return response.data;
};

export const deleteCategory = async (categoryId: Number) => {
  const response = await axios.delete(`${GLASS_BASE_URL}/category/delete/${categoryId}`);
  return response.data;
};

class GlassService {
  async getGlassById(glassId: number) {
    return axios.get(`${GLASS_BASE_URL}/${glassId}`).then(response => response.data);
  }

  getGlassByGlassCode(glassCode: string) {
    return axios.get(`${GLASS_BASE_URL}/glassCode/${glassCode}`).then(response => response.data);
  }

  createGlass(glass: IGlass) {
    return axios.post(`${GLASS_BASE_URL}/new`, glass);
  }

  updateGlass(glass: IGlass) {
    return axios.put(`${GLASS_BASE_URL}/update`, glass);
  }

  deleteGlass(glassId: number) {
    return axios.delete(`${GLASS_BASE_URL}/delete/${glassId}`);
  }

  readGlass() {
    return axios.get('http://192.168.0.74:5050');
  }
}

const ExportedGlassService = new GlassService();

export default ExportedGlassService;
