import React, { useRef, useState } from 'react';
import { Modal, Form, Col, Row, Button, Image } from 'react-bootstrap';
import { IDonation } from '../../../libraries/donation/DonationService';
import { set } from 'lodash';
import './styles.scss';
import { Language } from '../../languages/languageHandler';

interface FormProps {
  isNew: boolean;
  donationAttributes: IDonation | null;
  submitForm: (e: any) => void;
  handleImageUpload?: (e: any) => void;
  imageUploader?: any;
  uploadedImage?: any;
  cancelBtn: () => void;
  lang: Language;
}

const DonationForm = ({
  isNew,
  donationAttributes,
  submitForm,
  handleImageUpload,
  imageUploader,
  uploadedImage,
  cancelBtn,
  lang
}: FormProps) => {
  const [updatedAttributes, setUpdatedAttributes] = useState<IDonation | null>(donationAttributes);

  const updatedImage = useRef<any>(`data:image/jpeg;base64,${donationAttributes?.logo}`);
  const imageUpdater = useRef<any>(null);

  const handleChange = (fieldPath: string) => (event: any) => {
    if (!updatedAttributes) return;

    if (fieldPath === 'logo') {
      const [file] = event.target.files;

      if (file !== null || file !== undefined) {
        const reader = new FileReader();

        const { current } = updatedImage;

        current.file = file;

        reader.onload = (e: ProgressEvent<FileReader>) => {
          if (e.target) {
            current.src = e.target.result;

            if (reader.result) {
              const base64String: string = reader.result.toString().replace('data:', '').replace(/^.+,/, '');

              set(updatedAttributes, fieldPath, base64String);

              setUpdatedAttributes({
                donationId: updatedAttributes.donationId,
                name: updatedAttributes.name,
                logo: base64String
              });
            }
          }
        };

        reader.readAsDataURL(file);
      }
    }
    if (fieldPath !== 'logo') {
      set(updatedAttributes, fieldPath, event?.target?.value);
    }
  };

  return (
    <div>
      <Form onSubmit={submitForm}>
        <Row className="mb-2">
          <Form.Group as={Col} controlId="formGridDonationId">
            <Form.Label>{lang.DONATION_ENTITY_ID}:</Form.Label>
            {isNew ? (
              <Form.Control type="number" name="donationId" placeholder={lang.DONATION_ENTITY_ID_PLACEHOLDER} />
            ) : (
              <Form.Control readOnly value={donationAttributes?.donationId} name="donationId" />
            )}
          </Form.Group>
          <Form.Group as={Col} controlId="formGridName">
            <Form.Label>{lang.GLOBAL_NAME}:</Form.Label>
            {isNew ? (
              <Form.Control name="name" placeholder={lang.DONATION_ENTITY_NAME_PLACEHOLDER} />
            ) : (
              <Form.Control name="name" defaultValue={donationAttributes?.name} onChange={handleChange('name')} />
            )}
          </Form.Group>
        </Row>

        <Row className="mb-2">
          <Form.Group as={Col} controlId="formGridLogo">
            <Form.Label>{lang.DONATION_ENTITY_SELECT_LOGO}</Form.Label>
            {isNew ? (
              <Form.Control
                type="file"
                name="logo"
                placeholder="Please enter logo link"
                ref={imageUploader}
                onChange={handleImageUpload}
              />
            ) : (
              <Form.Control type="file" name="logo" ref={imageUpdater} onChange={handleChange('logo')} />
            )}
          </Form.Group>
        </Row>

        <Row className="mb-2">{isNew ? <Image ref={uploadedImage} /> : <Image ref={updatedImage} />}</Row>

        <Modal.Footer>
          <Button variant="success" type="submit">
            {lang.GLOBAL_SAVE_BUTTON}
          </Button>
          <Button variant="secondary" onClick={cancelBtn}>
            {lang.GLOBAL_CANCEL_BUTTON}
          </Button>
        </Modal.Footer>
      </Form>
    </div>
  );
};

export default DonationForm;
