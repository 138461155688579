import axios from 'axios';
import { zps_vi_url } from '../zps';

const DON_BASE_URL = `${zps_vi_url}/donentity`;

export type IDonation = {
  donationId: number;
  name: string;
  logo: string;
};

export const fetchFilteredDonations = async (pageNumber: number, pageSize: number, keyword?: string) => {
  const response = await axios.get(
    `${zps_vi_url}/donentities?pageNumber=${pageNumber}&pageSize=${pageSize}${keyword ? `&keyword=${keyword}` : ''}`
  );

  return response.data;
};

export const fetchDonationWithoutImageList = async () => {
  const response = await axios.get(`${zps_vi_url}/donentities/list`);

  return response.data;
};

class DonationService {
  getDonationEntityById(donationId: number) {
    return axios.get(DON_BASE_URL + '/' + donationId).then(response => response.data);
  }

  createDonationEntity(donation: IDonation) {
    return axios.post(DON_BASE_URL + '/new', donation);
  }

  updateDonationEntity(donation: IDonation) {
    return axios.put(DON_BASE_URL + '/update', donation);
  }

  deleteDonationEntity(donationId: number) {
    return axios.delete(DON_BASE_URL + '/delete/' + donationId);
  }
}

const ExportedDonationService = new DonationService();

export default ExportedDonationService;
