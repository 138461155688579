export const viLang_en = {
  GLOBAL_LANGUAGE_NAME: 'English',
  GLOBAL_LANGUAGE_EN: 'pt-PT',
  GLOBAL_LANGUAGE_PT: 'en-GB',
  //Common Wording
  GLOBAL_ADD_BUTTON: 'Add',
  GLOBAL_DELETE_BUTTON: 'Delete',
  GLOBAL_UPDATE_BUTTON: 'Update',
  GLOBAL_CONTENT: 'Content',
  GLOBAL_SAVE_BUTTON: 'Save',
  GLOBAL_CANCEL_BUTTON: 'Cancel',
  GLOBAL_RETURN_BUTTON: 'Return',
  GLOBAL_EQUIPMENT: 'Equipment',
  GLOBAL_GLASSES: 'Glasses',
  GLOBAL_DONATION: 'Donation Entities',
  GLOBAL_SECTION_TABLE_TITLE: 'Manage ',
  GLOBAL_ADD_NEW: 'Add New ',
  GLOBAL_NAME: 'Name',
  GLOBAL_SEARCH: 'Search',
  GLOBAL_PROFILE: 'Profile',
  GLOBAL_USERS: 'Users',
  GLOBAL_PASSWORD: 'Password',
  GLOBAL_EVENT: 'Event',
  GLOBAL_LOGOUT: 'Logout',
  GLOBAL_ALL: 'All',
  GLOBAL_SHOWING: 'Showing',
  GLOBAL_RESULTS: 'results',
  GLOBAL_LANGUAGE: 'Language',
  GLOBAL_REBOOT: 'Reboot',
  GLOBAL_SHUT_DOWN: 'Shut down',
  GLOBAL_OPEN_ESCROW: 'Open escrow',
  GLOBAL_CLOSE_ESCROW: 'Close escrow',

  //Events Section
  EVENTS: 'Events',
  EVENTS_NEW: 'Add New Event',
  EVENT_ADDRESS: 'Address',
  EVENT_ZIP_CODE: 'Zip Code',
  EVENT_BEGIN_DATE: 'Begin Date',
  EVENT_BEGIN_DATE_PLACEHOLDER: 'From',
  EVENT_END_DATE: 'End Date',
  EVENT_END_DATE_PLACEHOLDER: 'To',
  EVENT_LAT: 'Lat',
  EVENT_LONG: 'Long',
  EVENT_ID: 'Event Id',
  EVENT_DATE_INFORMATION: 'Date Information',
  EVENT_INSTALLATION: 'Installation',
  EVENT_UNINSTALLATION: 'Uninstallation',
  EVENT_DETAILS_GENERAL: 'General Information',
  EVENT_DETAILS_GENERAL_EVENT_DATES: 'Event Dates',
  EVENT_DETAILS_GENERAL_EVENT_DATES_FROM: 'From',
  EVENT_DETAILS_GENERAL_EVENT_DATES_TO: 'to',
  EVENT_DETAILS_GENERAL_DIRECTIONS: 'Directions',
  EVENT_GLASS_RECEPTACLE: 'Receptacle',
  EVENT_GLASS_DELIVERY_ANIMATION: 'Delivery Animation',
  EVENT_AD_ORDER: 'Order',
  EVENT_ID_PLACEHOLDER: 'Enter Event Id',
  EVENT_NAME_PLACEHOLDER: 'Enter Event Name',
  EVENT_ZIP_CODE_PLACEHOLDER: '0000-000 City',
  EVENT_LAT_PLACEHOLDER: '0.000',
  EVENT_LONG_PLACEHOLDER: '0.000',
  EVENT_EQUIPMENT_FORM_TITLE: 'Add Equipment to Event',
  EVENT_EQUIPMENT_FORM_SELECT: 'Select the Equipment',
  EVENT_GLASS_FORM_TITLE: 'Add Glass to Event',
  EVENT_GLASS_FORM_GLASS: 'Select the Glass',
  EVENT_GLASS_FORM_SELECT_RECEPTACLE: 'Select the receptacle',
  EVENT_GLASS_FORM_RECEPTACLE_1: 'Receptacle 1',
  EVENT_GLASS_FORM_RECEPTACLE_2: 'Receptacle 2',
  EVENT_GLASS_FORM_DELIVERY: 'Select the delivery animation',
  EVENT_GLASS_FORM_RIGHT: 'Direct to the right side',
  EVENT_GLASS_FORM_LEFT: 'Direct to the left side',
  EVENT_AD_FORM_TITLE: 'Add Advertisement to Event',
  EVENT_AD_FORM_SELECT: 'Select the Advertisement',
  EVENT_AD_FORM_ORDER: 'Pick the order',
  EVENT_DON_FORM_TITLE: 'Add Donation Entity to Event',
  EVENT_DON_SELECT: 'Select the Donation Entity',

  //Equipment Section
  EQUIPMENT_ID: 'EID',
  EQUIPMENT_SERIAL_NUMBER: 'Serial Number',
  EQUIPMENT_DESCRIPTION: 'Description',
  EQUIPMENT_IDENTIFICATION: 'Identification',
  EQUIPMENT_SOFTWARE_VERSION: 'Software Version',
  EQUIPMENT_CYCLE: 'Cycle',
  EQUIPMENT_OCCUPATION: 'Occupancy',
  EQUIPMENT_COM_STATUS: 'Status',
  EQUIPMENT_STATE: 'Equipment State',
  EQUIPMENT_STATE_IN_SERVICE: 'In Service',
  EQUIPMENT_STATE_OUT_OF_SERVICE: 'Out of Service',
  EQUIPMENT_LAST_COM_DATE: 'Last Communication',
  EQUIPMENT_LAST_OPERATION: 'Last Operation',
  EQUIPMENT_LAST_OPERATION_DATE: 'Last Operation Date',
  EQUIPMENT_OCCUPATION_PER: '% Occupancy',
  EQUIPMENT_EXISTENCES: 'Total Amount',
  EQUIPMENT_LOCAL: 'Place',
  EQUIPMENT_DETAILS_TITLE: "Equipment's Details",
  EQUIPMENT_DETAILS_SUBTITLE: 'Equipment Information',

  //Glasses Section
  GLASS: 'Glass',
  GLASS_NEW: 'Add New Glass',
  GLASS_IMAGE: 'Image',
  GLASS_DESCRIPTION: 'Description',
  GLASS_DETAILS_TITLE: 'Glass Details',
  GLASS_ID: 'Glass ID',
  GLASS_ID_PLACEHOLDER: 'Enter Glass ID',
  GLASS_CODE: 'Glass ID Code',
  GLASS_CODE_PLACEHOLDER: 'Enter the Glass ID code',
  GLASS_SELECT_IMAGE: 'Select an Image',
  GLASS_DESCRIPTION_PLACEHOLDER: 'Enter Glass description',
  GLASS_SIZE_TYPE: 'Size Type',
  GLASS_SIZE_TYPE_PLACEHOLDER: 'Enter size type',
  GLASS_READ: 'Read Glass',

  //Glass Category
  CATEGORY: 'Category',
  CATEGORIES: 'Categories',
  CATEGORY_DESCRIPTION: 'Description',
  CATEGORY_NEW: 'New Category',

  //Advertising Section
  ADVERTISEMENTS: 'Advertisements',
  ADVERTISING_NEW: 'Add New Advertisement',
  ADVERTISING_FILE: 'File',
  ADVERTISING_DURATION: 'Duration',
  ADVERTISING_SECONDS: 'Seconds',
  ADVERTISING_SECS: 'secs',
  ADVERTISING_DETAILS_TITLE: "Advertisement's Details",
  ADVERTISING: 'Ad',
  ADVERTISING_ID: 'Advertisemente Id',
  ADVERTISING_ID_PLACEHOLDER: 'Enter Advertisement Id',

  //Donation Entities Section
  DONATION_ENTITY: 'Entity',
  DONATION_ENTITIES: 'Entities',
  DONATION_ENTITIES_LOGO: 'Logo',
  DONATION_ENTITY_ID: 'Donation Entity ID',
  DONATION_ENTITY_ID_PLACEHOLDER: "Please enter entity's ID number",
  DONATION_ENTITY_NAME_PLACEHOLDER: "Please enter entity's name",
  DONATION_ENTITY_SELECT_LOGO: 'Select the logo',
  DONATION_ENTITY_FORM_TITLE: 'Add New Entity',

  //Login
  LOGIN_ENTITY: 'Entity',
  LOGIN_USER: 'User',
  LOGIN_BUTTON: 'Login',

  //Alert
  ALERT_ERRORCODE: 'Code',
  ALERT_ERRORMESSAGE: 'Message',

  //Users
  USER_EMAIL: 'Email',
  USER_EMAIL_PLACEHOLDER: 'example@email.com',
  USER_CARD: 'Card',
  USER_CARD_PLACEHOLDER: "Please enter User's card",
  USER_REGION: 'Region',
  USER_LANG: 'Language',
  USER_LANG_EN: 'English',
  USER_LANG_PT: 'Portuguese',
  USER_NEW: 'Add New User',
  USER_INFO: 'Personal Details',
  USER_ENTITY: 'Entity',
  USER_ENTITY_PLACEHOLDER: 'Select the Entity',
  USER_ID: 'User Id',
  USER_ID_PLACEHOLDER: 'Select the User Id',
  USER_NAME_PLACEHOLDER: "Please enter the User's name",
  USER_MOBILE: 'Mobile Number',
  USER_MOBILE_PLACEHOLDER: "Please enter the User's phone number",
  USER_PERMISSIONS: 'Permissions',
  USER_CONFIRM_PASSWORD: 'Confirm Password',
  USER_CONFIRM_CURRENT_PASSWORD: 'Please confirm your current password',
  USER_CHANGE_PASSWORD: 'Change Password',
  USER_NEW_PASSWORD: 'New Password',
  USER_PIN: 'PIN Code',
  USER_CHANGE_PIN: 'Change PIN',
  USER_NEW_PIN: 'New PIN Code',
  USER_CONFIRM_PIN: 'Confirm PIN Code',
  USER_DELETE_CONFIRM: 'Are you certain you wish to delete this user? This action is irreversible',
  USER_CONTACT_INFO: 'Contact Information',
  EDIT_USERS_LABEL_SYSTEM_MANAGEMENT_PERMISSION_1: 'User management and permissions',
  EDIT_USERS_LABEL_SYSTEM_MANAGEMENT_PERMISSION_2: 'System Configurations',
  EDIT_USERS_LABEL_SYSTEM_MANAGEMENT_PERMISSION_3: 'Eq. Non Financial Remote Oper.',
  EDIT_USERS_LABEL_SYSTEM_MANAGEMENT_PERMISSION_4: 'Technician',
  EDIT_USERS_LABEL_BUSINESS_DATA_MANAGEMENT_PERMISSION_1: 'Financial data',
  EDIT_USERS_LABEL_BUSINESS_DATA_MANAGEMENT_PERMISSION_2: 'CIT',
  EDIT_USERS_LABEL_BUSINESS_DATA_MANAGEMENT_PERMISSION_3: 'Eq. Financial Remote Oper.',
  EDIT_USERS_LABEL_BUSINESS_DATA_MANAGEMENT_PERMISSION_4: 'Deposit Operator',
  EDIT_USERS_LABEL_BUSINESS_DATA_MANAGEMENT_PERMISSION_5: 'APM',
  USER_DELEGATIONS: 'Delegations',
  USER_MY_PROFILE: 'My Profile',
  USER_PROFILE: 'Profile',

  //Movements
  MOVEMENTS: 'Transactions',
  PROCESSED_GLASS_PERIOD: 'Period',
  PROCESSED_GLASS_ID: 'ID',
  PROCESSED_GLASS_DATE_TIME: 'Date Time',
  PROCESSED_GLASS_STATUS: 'Status',
  SUCCESS: 'Collected',
  CANCELLED: 'Cancelled',
  ERROR: 'Error',
  PROCESSED_GLASS_ERROR: 'Error',
  NONE: ' ',
  INTERNAL: 'Internal Error',
  NO_GLASS_ID: 'Unrecognized glass',
  PROCESSED_GLASS_REFUND_TYPE: 'Refund Type',
  CASH: 'Cash',
  DONATION: 'Donation',
  PROCESSED_GLASS_CODE: 'Code',
  PROCESSED_GLASS_TUBE: 'Tube',
  PROCESSED_GLASS_INITIAL_DATE: 'Intitial Date',
  PROCESSED_GLASS_END_DATE: 'End Date',
  PROCESSED_GLASS_SEARCH_PLACEHOLER: 'Select an option',
  TRANSACTION_DETAILS: 'Transaction Details',
  TRANSACTION_AMOUNT: 'Amount',
  TRANSACTION_RECEIVED: 'Received',
  TRANSACTION_DISPENSED: 'Dispensed',
  TRANSACTION_PAYMENT_METHOD: 'Payment Method',
  TRANSACTION_TYPE: 'Type',

  //Enterprise Equipment Periods
  ENTERPRISE_EQUIP_PERIODS: 'Enterprise Equipment Periods',
  ENTERPRISE_EQUIP_PERIODS_SHORT: 'Ent. Equip. Periods',

  //Bank Transactions
  BANK_TRANSACTIONS: 'Bank Transactions',

  //Dashboard
  DASHBOARD: 'Dashboard'
};
