import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';
import { composeWithDevTools } from 'redux-devtools-extension';
import { sentryReduxEnhancer } from './middlewares/sentry-redux';
import { loadFromLocalStorage, saveToLocalStorage } from './local-storage';

const store = createStore(
  rootReducer,
  loadFromLocalStorage(),
  composeWithDevTools(applyMiddleware(thunk), sentryReduxEnhancer)
);

store.subscribe(() => {
  console.log('store.subscribe called');
  saveToLocalStorage(store.getState());
});

export type StoreState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export type GetState = () => StoreState;

export default store;
