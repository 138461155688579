import React, { FormEvent } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { IUser, updateUser } from '../../../libraries/users';
import { selectedLang } from '../../client-redux/languageHandle';
import { Language } from '../../languages/languageHandler';
import ModalComponent from '../modal/ModalComponent';
import './styles.scss';

interface ModalProps {
  toggleModal: () => void;
  userData: IUser;
  openModal: boolean;
  lang: Language;
}

const UpdateUserConfirmation = ({ userData, openModal, toggleModal, lang }: ModalProps) => {
  const dispatch = useDispatch();

  function submitUpdatedUser(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const { currentPassword } = e.target as typeof e.target & {
      currentPassword: { value: string };
    };

    updateUser({
      currentPassword: currentPassword.value,
      deletePin: false,
      user: userData
    });

    if (userData.lang !== 1) {
      dispatch(selectedLang(2));
    } else {
      dispatch(selectedLang(1));
    }

    toggleModal();
  }

  return (
    <>
      <ModalComponent
        show={openModal}
        handleClick={toggleModal}
        title={''}
        size={'sm'}
        content={
          <>
            <Form onSubmit={submitUpdatedUser}>
              <Form.Label>{lang?.USER_CONFIRM_CURRENT_PASSWORD}</Form.Label>
              <Form.Control type="password" size="sm" name="currentPassword" />
              <Modal.Footer className="password-confirmation-modal-footer">
                <Button variant="success" type="submit">
                  {lang.GLOBAL_SAVE_BUTTON}
                </Button>
                <Button variant="secondary" onClick={toggleModal}>
                  {lang.GLOBAL_CANCEL_BUTTON}
                </Button>
              </Modal.Footer>
            </Form>
          </>
        }
      />
    </>
  );
};

export default UpdateUserConfirmation;
