import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { SidebarButton } from '../sidebar/sidebar-button';

export interface HeaderProps {
  pageTitle?: string;
}

const Header = ({ pageTitle }: HeaderProps) => {
  return (
    <Row className="mt-2 mb-2">
      <Col xs="auto" sm="auto" md="auto" className="d-lg-none">
        <SidebarButton />
      </Col>
      <Col xs={10} sm={10} md={10} lg="auto" className="text-center">
        <h2 className="mb-0">{pageTitle}</h2>
      </Col>
    </Row>
  );
};

export default Header;
