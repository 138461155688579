import React, { FormEvent, useState } from 'react';
import { IDistribution, IDistributionPayload, updateDistribution } from '../../../libraries/distributionList';
import { IUser, PermissionOption } from '../../../libraries/users';
import { Language } from '../../languages/languageHandler';
import { permissionsToUser } from '../users/permissionsAux';
import DistributionForm from './DistributionForm';

interface UpdateProps {
  distribution: IDistribution;
  toggleUpdate: () => void;
  lang: Language;
  permissionOptions: PermissionOption[];
  haveChanged: () => void;
}

const UpdateDistribution = ({ distribution, toggleUpdate, lang, permissionOptions, haveChanged }: UpdateProps) => {
  const [emailList, setEmailList] = useState<string[]>(distribution.emails);
  const [usersList, setUsersList] = useState<IUser[]>(distribution.users);

  const [openUserForm, setOpenUserForm] = useState<boolean>(false);

  const [distributionAttributes] = useState<IDistribution>(distribution);

  const addToEmailList = (newEmail: string) => {
    const newEmailList: string[] = [...emailList];
    newEmailList.push(newEmail);
    setEmailList(newEmailList);
  };

  const deleteFromEmailList = (index: number) => {
    const reducedEmailList: string[] = [...emailList];
    reducedEmailList.splice(index, 1);
    setEmailList(reducedEmailList);
  };

  const addToUserList = (newUser: IUser) => {
    const newUserList: IUser[] = [...usersList];
    newUserList.push(newUser);
    setUsersList(newUserList);
    setOpenUserForm(false);
  };

  const deleteFromUserList = (index: number) => {
    const reducedUsersList: IUser[] = [...usersList];
    reducedUsersList.splice(index, 1);
    setUsersList(reducedUsersList);
  };

  const toggleOpenUserForm = (b: boolean) => {
    setOpenUserForm(b);
  };

  const submitDistribution = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const { eventCode } = e.target as typeof e.target & {
      eventCode: { value: number };
    };

    let distributionPayload: IDistributionPayload = {
      emails: emailList,
      eventCode: eventCode.value,
      id: distributionAttributes.id,
      users: usersList
    };

    permissionsToUser(permissionOptions, distributionPayload);

    updateDistribution(distributionPayload).then(response => {
      if (response.data.statusOper.code === 0) {
        haveChanged();
      }
    });

    toggleUpdate();
  };

  return (
    <>
      <DistributionForm
        isNew={false}
        distributionAttributes={distributionAttributes}
        submitForm={e => submitDistribution(e)}
        cancelBtn={toggleUpdate}
        lang={lang}
        permissionOptions={permissionOptions}
        addToUserList={addToUserList}
        deleteFromUserList={deleteFromUserList}
        userList={usersList}
        addToEmailList={addToEmailList}
        deleteFromEmailList={deleteFromEmailList}
        emailList={emailList}
        openUserForm={openUserForm}
        toggleOpenUserForm={toggleOpenUserForm}
      />
    </>
  );
};

export default UpdateDistribution;
