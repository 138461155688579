import moment from 'moment';
import React, { FormEvent, useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTransactionList, ITransactionResponse } from '../../../libraries/transactions';
import { AppState } from '../../client-redux/reducers';
import { Language } from '../../languages/languageHandler';
import * as Icon from 'react-bootstrap-icons';
import './styles.scss';
import { useNavigate } from 'react-router-dom';
import { fetchEquipmentList, IEquipmentListResponse } from '../../../libraries/equipment';
import Header from '../ui/Header';
import { formatAmount2 } from '../../utils/script';
import ShowTransaction from './ShowTransaction';

const Transactions = () => {
  const lang = useSelector<AppState, Language | undefined>(state => state.lang.lang);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [beginDate /* , setBeginDate */] = useState<string>(moment().startOf('day').format('YYYY-MM-DDT00:00:00.000'));
  const [endDate /* , setEndDate */] = useState<string>(moment().format('YYYY-MM-DDT23:59:59.000'));
  const [entid /* , setEntid */] = useState<number>(0);
  const [sid /* , setSid */] = useState<number>(0);
  const [eid /* , setEid */] = useState<number>(0);
  const [offset /* , setOffset */] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [totalRows /* , setTotalRows */] = useState<boolean>(true);

  const [equipmentList, setEquipmentList] = useState<IEquipmentListResponse>();

  const [openDetails, setOpenDetails] = useState<boolean>(false);
  const [activeRow, setActiveRow] = useState<number>();

  const [message, setMessage] = useState<ITransactionResponse>();
  const [noResults, setNoResults] = useState<boolean>(false);

  useEffect(() => {
    fetchTransactionList(`${beginDate}Z`, `${endDate}Z`, entid, sid, eid, offset, limit, totalRows).then(response => {
      if (response.statusOper.code === 0) {
        setNoResults(false);
        setMessage(response);
      } else if (response.statusOper.code === 6 || response.statusOper.code === 2) {
        //dispatch(logout());
        //navigate('/login');
      } else {
        setNoResults(true);
      }
    });
  }, [beginDate, dispatch, eid, endDate, entid, navigate, limit, offset, sid, totalRows]);

  useEffect(() => {
    fetchEquipmentList(entid, sid).then(response => {
      console.log(response);
      setEquipmentList(response);
    });
  }, [entid, sid]);

  const resultsSelect = (e: any) => {
    setLimit(e.target.value);
  };

  const getTransactionList = () => {
    fetchTransactionList(`${beginDate}Z`, `${endDate}Z`, entid, sid, eid, offset, limit, totalRows).then(response => {
      if (response.statusOper.code === 0) {
        setNoResults(false);
        setMessage(response);
      } else if (response.statusOper.code === 6 || response.statusOper.code === 2) {
        //dispatch(logout());
        //navigate('/login');
      } else {
        setNoResults(true);
      }
    });
  };

  const submitSearch = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    getTransactionList();
  };

  const showTransactionDetails = (n: number) => {
    setActiveRow(n);
    setOpenDetails(true);
  };

  return (
    <Container fluid>
      {openDetails && activeRow && lang && message ? (
        <ShowTransaction
          transaction={message.transactions[activeRow]}
          openDetails={openDetails}
          hideBtn={() => setOpenDetails(false)}
          lang={{}}
        />
      ) : null}
      <Header pageTitle="Transações" />
      <Form onSubmit={e => submitSearch(e)}>
        <Row className="mt-2 align-items-center">
    {/*       <Col lg="auto" md={2} sm="auto" xs={12}>
            <Form.Label className="equipmentSearchLabel">Entidade</Form.Label>
          </Col>
          <Col lg="auto" md={4} sm="auto" xs={12}>
            <Form.Select size="sm" name="entid">
              <option value={9999}>Zarph Test</option>
            </Form.Select>
          </Col>
          <Col lg="auto" md={2} sm="auto" xs={12} className="mt-2 mt-sm-0 ml-auto">
            <Form.Label className="equipmentSearchLabel">Delegação</Form.Label>
          </Col>
          <Col lg="auto" md={4} sm="auto" xs={12}>
            <Form.Select size="sm" name="sid">
              <option value={0}>Todas</option>
              <option value={1}>HQ</option>
              <option value={2}>Demo 1</option>
              <option value={10}>Odivelas</option>
            </Form.Select>
          </Col> */}
          <Col lg="auto" md={2} sm="auto" xs={12} className="mt-2 mt-md-2 mt-sm-0 ml-auto">
            <Form.Label className="equipmentSearchLabel">Equipamento</Form.Label>
          </Col>
          <Col lg="auto" md={4} sm="auto" xs={12} className="mt-md-2">
            <Form.Select size="sm" name="eid">
              {equipmentList?.equipments ? (
                <>
                  <option value={0}>Todos</option>
                  {equipmentList?.equipments.map((equipmentDetails, index) => (
                    <option key={index} value={equipmentDetails.equipment.eid}>
                      {equipmentDetails.equipment.eid} - {equipmentDetails.equipment.description}
                    </option>
                  ))}
                </>
              ) : (
                <>
                  <option>Sem Resultados</option>
                </>
              )}
            </Form.Select>
          </Col>
          <Col lg="auto" md={2} sm="auto" xs={12} className="mt-2 mt-sm-0 ml-auto">
            <Form.Label className="equipmentSearchLabel">{lang?.EVENT_BEGIN_DATE_PLACEHOLDER}</Form.Label>
          </Col>
          <Col lg="auto" md={4} sm="auto" xs={12} className="mt-md-2">
            <Form.Control
              size="sm"
              type="datetime-local"
              name="beginning"
              defaultValue={moment().startOf('day').format('YYYY-MM-DDT00:00:00')}
            />
          </Col>
          <Col lg="auto" md={2} sm="auto" xs={12} className="mt-2 mt-md-2 mt-sm-0 ml-auto">
            <Form.Label className="equipmentSearchLabel">{lang?.EVENT_END_DATE_PLACEHOLDER}</Form.Label>
          </Col>
          <Col lg="auto" md={4} sm="auto" xs={12} className="mt-md-2 ml-auto">
            <Form.Control
              size="sm"
              type="datetime-local"
              name="end"
              defaultValue={moment().format('YYYY-MM-DDT23:59')}
            />
          </Col>
          <Col lg="auto" xs={12} md="auto" className="mt-4 mt-md-2 mt-sm-0 ml-auto">
            <Button type="submit" className="w-100" size="sm">
              {lang?.GLOBAL_SEARCH}
            </Button>
          </Col>
        </Row>
      </Form>
      <Row className="mt-2">
        <Col>
          <Table responsive striped hover size="md" className="data-table">
            <thead className="table-head">
              <tr className="table-head-row">
                <th className="transaction-column">{lang?.EQUIPMENT_ID}</th>
                <th className="transaction-column">Delegação</th>
                <th className="transaction-column">{lang?.PROCESSED_GLASS_PERIOD}</th>
                <th className="transaction-column">Código</th>
                <th className="transaction-column">Utilizador/Caixa</th>
                <th className="transaction-column">{lang?.PROCESSED_GLASS_DATE_TIME}</th>
                <th className="transaction-column">Tipo</th>
                <th className="transaction-column">Montante</th>
                <th className="transaction-column">Recebido</th>
                <th className="transaction-column">Dispensado</th>
                <th className="transaction-column">Documento</th>
                <th className="transaction-column"></th>
              </tr>
            </thead>
            <tbody>
              {noResults ? (
                <tr>
                  <td colSpan={16} style={{ textAlign: 'center' }}>
                    Sem resultados
                  </td>
                </tr>
              ) : (
                message?.transactions.map((transaction, index) => (
                  <tr key={transaction.code} onClick={() => showTransactionDetails(index)}>
                    <td>{transaction.eid}</td>
                    <td>{transaction.eid}</td>
                    <td>{transaction.period}</td>
                    <td>{transaction.code}</td>
                    <td>{transaction.uid ? transaction.uid : ''}</td>
                    <td>{moment(transaction.dateTime).format('YYYY-MM-DD HH:mm')}</td>
                    <td>{transaction.type}</td>
                    <td>{formatAmount2(transaction.value)}</td>
                    <td>{formatAmount2(transaction.valueRec)}</td>
                    <td>{formatAmount2(transaction.valueDis)}</td>
                    <td>{transaction.docCode}</td>
                    <td className="pdfBtn">
                      <Icon.FilePdf />
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row className="mt-2 align-items-center">
        <Col>
          <div className="pagination-section">
            {/* <PaginationComponent
              total={page?.totalElements}
              itemsPerPage={pageSize}
              currentPage={pageNumber}
              onPageChange={changePage}
            /> */}
          </div>
        </Col>
        <Col xs="auto" className="align-self-end d-flex flex-row align-items-center justify-content-flex-end">
          <span>{lang?.GLOBAL_SHOWING} </span>
          <Form.Select className="mx-1" size="sm" onChange={e => resultsSelect(e)}>
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </Form.Select>
          <span> {lang?.GLOBAL_RESULTS}</span>
        </Col>
      </Row>
    </Container>
  );
};

export default Transactions;
