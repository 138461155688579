import React, { FormEvent, useEffect, useState } from 'react';
import GlassService, {
  getAllCategories,
  getGlasses,
  IGlass,
  IGlassCategory
} from '../../../libraries/glass/GlassService';
import NewGlass from './NewGlass';
import ShowGlass from './ShowGlass';
import { Table, Image, Form, Row, Col, InputGroup, Button } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import './styles.scss';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { AppState } from '../../client-redux/reducers';
import { Language } from '../../languages/languageHandler';
import { Page } from '../../../libraries/zps';
import PaginationComponent from '../pagination/PaginationComponent';
import Header from '../ui/Header';

function Glasses() {
  const lang = useSelector<AppState, Language | undefined>(state => state.lang.lang);

  const { mutateAsync } = useMutation(GlassService.deleteGlass);

  const [page, setPage] = useState<Page<IGlass>>();
  // const [glasses, setGlasses] = useState<IGlass[]>();

  const [glassCategories, setGlassCategories] = useState<IGlassCategory[]>();
  const [pageNumber, setPageNumber] = useState<number>(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pageSize, setPageSize] = useState<number>(10);

  const [selectedKeyword, setSelectedKeyword] = useState<string | undefined>();
  const [selectedCategory, setSelectedCategory] = useState<number | undefined>();

  const [activeRow, setActiveRow] = useState<number | null>(null);
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const [openNew, setOpenNew] = useState<boolean>(false);
  const [openDetails, setOpenDetails] = useState<boolean>(false);

  const changePage = (n: number) => {
    setPageNumber(n);
  };

  useEffect(() => {
    getGlasses(pageNumber, pageSize, selectedKeyword, selectedCategory).then(response => {
      setPage(response.page);
    });
  }, [pageNumber, pageSize, selectedCategory, selectedKeyword]);

  useEffect(() => {
    getAllCategories().then(response => {
      setGlassCategories(response);
    });
  }, []);

  const handleToggle =
    (glassId: number | undefined): (() => void) =>
    () => {
      if (glassId) {
        setActiveRow(glassId);
        setIsSelected(!isSelected);
        setOpenDetails(!openDetails);
      }
    };

  function toggleNewGlassModal() {
    setOpenNew(!openNew);
  }

  function toggleDetailsModal() {
    setOpenDetails(!openDetails);
    setIsSelected(!isSelected);
  }

  const isActive = (glassId: number): boolean => {
    if (!activeRow) return false;

    return glassId === activeRow;
  };

  /* function disableBtn(activeRow: number | null): string {
    if (activeRow === null) {
      return 'disabled';
    } else {
      return '';
    }
  } */

  const submitSearch = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const { keyword, category } = e.target as typeof e.target & {
      keyword: { value: string };
      category: { value: number };
    };

    keyword.value ? setSelectedKeyword(keyword.value) : setSelectedKeyword(undefined);

    category.value ? setSelectedCategory(category.value) : setSelectedCategory(undefined);

    setPageNumber(0);
  };

  const deleteGlass = async (activeRow: number | null | undefined) => {
    if (
      activeRow !== null &&
      activeRow !== undefined &&
      window.confirm('Are you certain you wish to delete this cup? This action is irreversible.')
    ) {
      await mutateAsync(activeRow);

      setActiveRow(null);

      setOpenDetails(false);
    }
  };

  const readNewGlass = async () => {
    try {
      setTimeout(() => {
        window.alert('Leitura de Copo em processo');
      }, 0);
      await GlassService.readGlass();

      window.alert('Leitura bem sucedida');
    } catch {
      window.confirm('Ocorreu um erro na leitura');
    }
  };

  const resultsSelect = (e: any) => {
    setPageSize(e.target.value);
  };

  return (
    <>
      <div className="index-container">
        <div>{lang && <NewGlass lang={lang} isNew={openNew} hideBtn={() => toggleNewGlassModal()} />}</div>
        <div>
          {activeRow && lang && (
            <ShowGlass
              lang={lang}
              openDetails={openDetails}
              glassId={activeRow}
              hideBtn={() => toggleDetailsModal()}
              deleteBtn={() => deleteGlass(activeRow)}
            />
          )}
        </div>
        <div className="top-index-section">
          <div className="top-left">
            <Header pageTitle={lang?.GLOBAL_GLASSES} />
          </div>
          <div className="top-right">
            <button className="btn btn-md btn-table add-btn" onClick={() => toggleNewGlassModal()}>
              <Icon.Plus />
              &nbsp;{lang?.GLOBAL_ADD_BUTTON}&nbsp;
            </button>
            <button hidden className="btn btn-primary btn-table read-btn" onClick={() => readNewGlass()}>
              <Icon.CameraFill />
              &nbsp;{lang?.GLASS_READ}&nbsp;
            </button>
          </div>
        </div>
        <div className="content-section">
          <div className="top-content-section">
            <div className="search-section">
              <Form className="search-form" onSubmit={e => submitSearch(e)}>
                <Row>
                  <Col lg={6}>
                    <InputGroup>
                      <InputGroup.Text>
                        <Icon.Search />
                      </InputGroup.Text>
                      <Form.Control size="sm" name="keyword" placeholder="Pesquise por Código ou Descrição" />
                    </InputGroup>
                  </Col>
                  <Col lg={4}>
                    <Form.Select size="sm" name="category">
                      <option key={-1} value="" className="selectPlaceholder">
                        Categorias
                      </option>
                      {glassCategories?.map((category, index) => (
                        <option key={index} value={category.categoryId}>
                          {category.description}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                  <Col>
                    <Button size="sm" type="submit">
                      Pesquisa
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
            <div className="results-section">
              <span>{lang?.GLOBAL_SHOWING} </span>
              <Form>
                <Form.Select className="results-select" size="sm" name="results" onChange={e => resultsSelect(e)}>
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                </Form.Select>
              </Form>
              <span> {lang?.GLOBAL_RESULTS}</span>
            </div>
          </div>
          <div className="index-table">
            <Table size="md" className="data-table">
              <thead className="table-head">
                <tr className="table-head-row">
                  <th id="glassImage">{lang?.GLASS_IMAGE}</th>
                  <th id="glassCode">{lang?.GLASS_CODE}</th>
                  <th id="glassDescription">{lang?.GLASS_DESCRIPTION}</th>
                  <th id="glassDelete"></th>
                </tr>
              </thead>
              <tbody>
                {page?.content?.map((glass, index) => (
                  <tr
                    key={index}
                    className={`table-row ${glass.glassId && isActive(glass.glassId) ? 'activeRow' : ''}`}
                    onClick={handleToggle(glass.glassId)}
                  >
                    <td>
                      <div id="glassImage-container">
                        <Image className="table-image" src={`data:image/jpeg;base64,${glass.image}`} rounded />
                      </div>
                    </td>
                    <td id="glassTableNums" className="align-middle">
                      {glass.glassCode}
                    </td>
                    <td className="align-middle">{glass.name}</td>
                    <td>
                      <button className="delete-btn" onClick={() => deleteGlass(glass.glassId)}>
                        <Icon.Trash />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div className="pagination-section">
              {
                <PaginationComponent
                  total={page?.totalElements}
                  itemsPerPage={pageSize}
                  currentPage={pageNumber}
                  onPageChange={changePage}
                />
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Glasses;
