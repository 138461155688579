import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, FormControl, InputGroup, Row, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEquipmentList, IEquipmentDetails, IEquipmentListResponse } from '../../../libraries/equipment';
import { AppState } from '../../client-redux/reducers';
import { Language } from '../../languages/languageHandler';
import { logout } from '../../client-redux/login';
import * as Icon from 'react-bootstrap-icons';
import moment from 'moment';
import './styles.scss';
import { useNavigate } from 'react-router-dom';
import Header from '../ui/Header';
import ShowEquipment from './ShowEquipment';

const Equipment = () => {
  const lang = useSelector<AppState, Language | undefined>(state => state.lang.lang);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //const [keyword, setKeyword] = useState<string>();

  const [entid, setEntid] = useState<number>(0);
  const [sid, setSid] = useState<number>(0);

  const [openDetails, setOpenDetails] = useState<boolean>(false);
  const [activeRow, setActiveRow] = useState<number>();

  const [message, setMessage] = useState<IEquipmentListResponse>();
  const [noResults, setNoResults] = useState<boolean>(false);
  const [filteredData, setFilteredData] = useState<IEquipmentDetails[] | undefined>(undefined);

  useEffect(() => {
    fetchEquipmentList(entid, sid).then(response => {
      if (response.statusOper.code === 0) {
        setNoResults(false);
        setMessage(response);
      } else if (response.statusOper.code === 6) {
        dispatch(logout());
        navigate('/login');
      } else {
        setNoResults(true);
      }
    });
  }, [entid, navigate, sid, dispatch]);

  const checkStatusForCell = (statusCode: number) => {
    if (statusCode === 4) {
      return <div className="offlineStatus">Offline</div>;
    } else {
      return <div className="onlineStatus">Online</div>;
    }
  };

  const checkStatusForRow = (statusCode: number) => {
    if (statusCode === 4) {
      return false;
    } else {
      return true;
    }
  };

  const delegationCheck = (sid: number) => {
    if (sid === 0) return '';
    if (sid === 1) return 'HQ';
    if (sid === 2) return 'Demo 1';
    if (sid === 10) return 'Odivelas';
  };

  const filterSelect = (e: any) => {
    e.preventDefault();

    const { entid, sid } = e.target as typeof e.target & {
      entid: { entid: number };
      sid: { sid: number };
    };

    setEntid(entid.value);
    setSid(sid.value);
  };

  const handleKeyword = (e: any) => {
    const keyword = e.target.value;
    console.log(keyword);
    const newFilter = message?.equipments.filter(value => {
      return (
        value.equipment.eid.toString().toLowerCase().includes(keyword.toLowerCase()) ||
        value.equipment.description.toLowerCase().includes(keyword.toLowerCase())
      );
    });

    if (keyword !== '' && newFilter) {
      setFilteredData(newFilter);
      console.log(filteredData);
    } else {
      setFilteredData([]);
    }
  };

  const showEquipment = (eid: number) => {
    setActiveRow(eid);
    setOpenDetails(true);
  };

  return (
    <Container fluid>
      {openDetails && activeRow && lang && (
        <ShowEquipment eid={activeRow} openDetails={openDetails} hideBtn={() => setOpenDetails(false)} lang={{}} />
      )}
      <Header pageTitle={lang?.GLOBAL_EQUIPMENT} />
      <Row className="mt-2">
        <Col xs={12} md={6} lg={3}>
          <InputGroup>
            <InputGroup.Text id="search-placeholder">
              <Icon.Search />
            </InputGroup.Text>
            <FormControl
              id="search-input"
              size="sm"
              placeholder="Pesquisar"
              aria-label="Pesquisar"
              aria-describedby="search-placeholder"
              onChange={handleKeyword}
            />
          </InputGroup>
        </Col>
      </Row>
      <Form onSubmit={e => filterSelect(e)}>
        <Row className="mt-2 align-items-center">
          <Col sm="auto" xs={3}>
            <span>Entidade</span>
          </Col>
          <Col sm="auto" xs={9}>
            <Form.Select size="sm" name="entid">
              <option value={9999}>Zarph Test</option>
            </Form.Select>
          </Col>
          <Col sm="auto" xs={3} className="mt-1 mt-sm-0">
            <span>Delegação</span>
          </Col>
          <Col sm="auto" xs={9} className="mt-1 mt-sm-0">
            <Form.Select size="sm" name="sid">
              <option value={0}>Todas</option>
              <option value={1}>HQ</option>
              <option value={2}>Demo 1</option>
              <option value={10}>Odivelas</option>
            </Form.Select>
          </Col>
          <Col xs="auto" className="mt-1 mt-sm-0 ml-auto">
            <Button size="sm" type="submit">
              {lang?.GLOBAL_SEARCH}
            </Button>
          </Col>
        </Row>
      </Form>
      <Row className="mt-2">
        <Col>
          <Table responsive hover size="md" className="data-table equipment-table">
            <thead className="table-head">
              <tr className="table-head-row">
                <th className="w-25">{lang?.EQUIPMENT_IDENTIFICATION}</th>
                <th>{lang?.EQUIPMENT_COM_STATE}</th>
                <th>{lang?.EQUIPMENT_EXISTENCES}</th>
                <th>Delegação</th>
                <th>{lang?.EQUIPMENT_ID}</th>
                <th>Tipo</th>
                <th>Banco</th>
                <th>Período</th>
                <th>Estado</th>
                <th>{lang?.EQUIPMENT_COM_DATE}</th>
                <th>U. Oper.</th>
                <th>D. U. Oper.</th>
                <th>{lang?.EQUIPMENT_SOFTWARE_VERSION}</th>
                <th className="equipmentPDF"></th>
              </tr>
            </thead>
            <tbody>
              {noResults ? (
                <tr>
                  <td colSpan={16} style={{ textAlign: 'center' }}>
                    Sem resultados
                  </td>
                </tr>
              ) : filteredData ? (
                filteredData.map(equipmentDetails => (
                  <tr
                    key={equipmentDetails.equipment.eid}
                    className={checkStatusForRow(equipmentDetails.equipment.status) ? 'online-row' : 'offline-row'}
                  >
                    {/* <td>{equipmentDetails.equipment.eid}</td>
                    <td>{equipmentDetails.equipment.etid}</td> */}
                    <td>{equipmentDetails.equipment.description}</td>
                    <td>{equipmentDetails.equipment.bankid === 0 ? '' : equipmentDetails.equipment.bankid}</td>
                    <td>{delegationCheck(equipmentDetails.equipment.sid)}</td>
                    <td>{checkStatusForCell(equipmentDetails.equipment.status)}</td>
                    <td>
                      {equipmentDetails.equipmentState?.equipmentState
                        ? equipmentDetails.equipmentState.equipmentState
                        : ''}
                    </td>
                    <td>{moment(equipmentDetails.lastComDt).format('DD/MM/YYYY HH:mm:ss')}</td>
                    <td>{equipmentDetails.lastOperation}</td>
                    <td>{moment(equipmentDetails.lastOperationDt).format('DD/MM/YYYY HH:mm:ss')}</td>
                    <td>{equipmentDetails.softwareVersion}</td>
                    <td>{equipmentDetails.period}</td>
                    <td>{equipmentDetails.equipment.eid}</td>
                    <td>{equipmentDetails.equipment.eid}</td>
                    <td>{equipmentDetails.equipment.eid}</td>
                    <td className="pdfBtn">
                      <Icon.FilePdf />
                    </td>
                  </tr>
                ))
              ) : (
                message?.equipments.map((equipmentDetails, index) => (
                  <tr
                    key={index}
                    className={checkStatusForRow(equipmentDetails.equipment.status) ? 'online-row' : 'offline-row'}
                    onClick={
                      checkStatusForRow(equipmentDetails.equipment.status)
                        ? () => showEquipment(equipmentDetails.equipment.eid)
                        : undefined
                    }
                  >
                    <td>{equipmentDetails.equipment.description}</td>
                    <td>{checkStatusForCell(equipmentDetails.equipment.status)}</td>
                    <td>{equipmentDetails.equipment.amount || 0} €</td>
                    <td>{delegationCheck(equipmentDetails.equipment.sid)}</td>
                    <td>{equipmentDetails.equipment.eid}</td>
                    <td>{equipmentDetails.equipment.etid}</td>
                    <td>{equipmentDetails.equipment.bankid === 0 ? '' : equipmentDetails.equipment.bankid}</td>
                    <td>{equipmentDetails.period}</td>
                    <td>
                      {equipmentDetails.equipmentState?.equipmentState
                        ? equipmentDetails.equipmentState.equipmentState
                        : ''}
                    </td>
                    <td>{moment(equipmentDetails.lastComDt).format('DD/MM/YYYY HH:mm:ss')}</td>
                    <td>{equipmentDetails.lastOperation}</td>
                    <td>{moment(equipmentDetails.lastOperationDt).format('DD/MM/YYYY HH:mm:ss')}</td>
                    <td>{equipmentDetails.softwareVersion}</td>
                    <td className="equipmentPDF pdfBtn">
                      <Icon.FilePdf />
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row className="mt-2 align-items-center">
        <Col>
          <div className="pagination-section">
            {/* <PaginationComponent
              total={page?.totalElements}
              itemsPerPage={pageSize}
              currentPage={pageNumber}
              onPageChange={handlePageChange}
            /> */}
          </div>
        </Col>
        <Col xs="auto" className="align-self-end d-flex flex-row align-items-center justify-content-flex-end">
          <span>{lang?.GLOBAL_SHOWING}</span>
          <Form.Select size="sm" className="mx-1" /* onChange={e => resultsSelect(e)} */>
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
          </Form.Select>
          <span>{lang?.GLOBAL_RESULTS}</span>
        </Col>
      </Row>
    </Container>
  );
};

export default Equipment;
