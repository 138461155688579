import React, { useState } from 'react';
import { Modal, Form, Col, Row, Button } from 'react-bootstrap';
import { IUser, PermissionOption } from '../../../libraries/users/index';
import { IRegionResponse, fetchRegions } from '../../../libraries/region/index';
import { ILanguageResponse, fetchLanguages } from '../../../libraries/language/index';
import { set } from 'lodash';
import { Language } from '../../languages/languageHandler';
import { useQuery, UseQueryResult } from 'react-query';
import './styles.scss';

interface FormProps {
  isNew: boolean;
  userAttributes: IUser | null;
  submitForm: (e: any) => void;
  cancelBtn: () => void;
  lang: Language;
  permissionOptions: PermissionOption[];
}

const UserForm = ({ isNew, userAttributes, submitForm, cancelBtn, lang, permissionOptions }: FormProps) => {
  const [updatedAttributes, setUpdatedAttributes] = useState<IUser | null>(userAttributes);

  const regions: UseQueryResult<IRegionResponse> = useQuery<IRegionResponse>('regions', fetchRegions);
  const languages: UseQueryResult<ILanguageResponse> = useQuery<ILanguageResponse>('languages', fetchLanguages);
  const [selectedPermissions, setSelectedPermissions] = useState<PermissionOption[]>(permissionOptions);

  const handleChange = (fieldPath: string) => (event: any) => {
    if (!updatedAttributes) return;

    set(updatedAttributes, fieldPath, event?.target?.value);
    setUpdatedAttributes(updatedAttributes);
  };

  const toogleSelection = (po: PermissionOption) => {
    let newP: PermissionOption[] = [...selectedPermissions];
    po.selected = !po.selected;
    setSelectedPermissions(newP);
  };

  return (
    <div>
      <Form onSubmit={submitForm}>
        <div className="modal-heading">
          <h5>{lang.USER_INFO}</h5>
        </div>
        <Row className="mb-2">
          <Form.Group as={Col} controlId="formGridEntid">
            <Form.Label>{lang.USER_ENTITY}</Form.Label>
            {isNew ? (
              <Form.Control type="number" placeholder={lang.USER_ENTITY_PLACEHOLDER} name="entid" />
            ) : (
              <Form.Control readOnly value={userAttributes?.entid} name="entid" />
            )}
          </Form.Group>
          <Form.Group as={Col} controlId="formGridUid">
            <Form.Label>{lang.USER_ID}</Form.Label>
            {isNew ? (
              <Form.Control type="number" placeholder={lang.USER_ID_PLACEHOLDER} name="uid" />
            ) : (
              <Form.Control type="number" name="uid" value={userAttributes?.uid} readOnly />
            )}
          </Form.Group>
        </Row>
        <Row className="mb-2">
          <Form.Group as={Col} controlId="formGridName">
            <Form.Label>{lang.GLOBAL_NAME}</Form.Label>
            {isNew ? (
              <Form.Control placeholder={lang.USER_NAME_PLACEHOLDER} name="name" />
            ) : (
              <Form.Control
                type="text"
                name="name"
                defaultValue={userAttributes?.name}
                onChange={handleChange('name')}
              />
            )}
          </Form.Group>
        </Row>
        <Row className="mb-2">
          <Form.Group as={Col} controlId="formGridMobile">
            <Form.Label>{lang.USER_MOBILE}</Form.Label>
            {isNew ? (
              <Form.Control placeholder={lang.USER_MOBILE_PLACEHOLDER} name="mobile" />
            ) : (
              <Form.Control
                type="text"
                name="name"
                defaultValue={userAttributes?.mobile}
                onChange={handleChange('mobile')}
              />
            )}
          </Form.Group>
          <Form.Group as={Col} controlId="formGridEmail">
            <Form.Label>{lang.USER_EMAIL}</Form.Label>
            {isNew ? (
              <Form.Control type="email" placeholder={lang.USER_EMAIL_PLACEHOLDER} name="email" />
            ) : (
              <Form.Control
                type="email"
                name="email"
                defaultValue={userAttributes?.email}
                onChange={handleChange('email')}
              />
            )}
          </Form.Group>
          <Form.Group as={Col} controlId="formGridCard">
            <Form.Label>{lang.USER_CARD}</Form.Label>
            {isNew ? (
              <Form.Control type="text" placeholder={lang.USER_CARD_PLACEHOLDER} name="card" />
            ) : (
              <Form.Control
                type="text"
                name="card"
                defaultValue={userAttributes?.card}
                onChange={handleChange('card')}
              />
            )}
          </Form.Group>
        </Row>
        <Row className="mb-2">
          <Form.Group as={Col} controlId="formGridAd">
            <Form.Label>{lang.USER_LANG}</Form.Label>
            {isNew ? (
              <Form.Control as="select" name="lang">
                {languages.data?.languages?.map(language => (
                  <option value={language.langid}>{language.nativeName}</option>
                ))}
              </Form.Control>
            ) : (
              <Form.Control as="select" name="lang" defaultValue={userAttributes?.lang} onChange={handleChange('lang')}>
                {languages.data?.languages?.map(language => (
                  <option value={language.langid}>{language.nativeName}</option>
                ))}
              </Form.Control>
            )}
          </Form.Group>
          <Form.Group as={Col} controlId="formGridAd">
            <Form.Label>{lang.USER_REGION}</Form.Label>
            {isNew ? (
              <Form.Control as="select" name="region">
                {regions.data?.regions?.map(region => (
                  <option value={region}>{region}</option>
                ))}
              </Form.Control>
            ) : (
              <Form.Control
                as="select"
                name="region"
                defaultValue={userAttributes?.region}
                onChange={handleChange('region')}
              >
                {regions.data?.regions?.map(region => (
                  <option value={region}>{region}</option>
                ))}
              </Form.Control>
            )}
          </Form.Group>
        </Row>
        <Row className="mb-2">
          <Form.Group as={Col} controlId="formGridPassword">
            <Form.Label>{lang.GLOBAL_PASSWORD}</Form.Label>
            <Form.Control type="password" name="password" />
          </Form.Group>
          <Form.Group as={Col} controlId="formGridControlPassword">
            <Form.Label>{lang.USER_CONFIRM_PASSWORD}</Form.Label>
            <Form.Control type="password" name="confirmPassword" />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formGridPin">
            <Form.Label>{lang.USER_PIN}</Form.Label>
            <Form.Control type="password" name="pin" />
          </Form.Group>
          <Form.Group as={Col} controlId="formGridControlPassword">
            <Form.Label>{lang.USER_CONFIRM_PIN}</Form.Label>
            <Form.Control type="password" name="confirmPin" />
          </Form.Group>
        </Row>
        <div className="modal-heading">
          <h5>{lang.USER_PERMISSIONS}</h5>
        </div>
        <div className="mb-3 permissionsRow">
          {isNew
            ? selectedPermissions.map(po => {
                return (
                  <Form.Group controlId="formGridPermissions" key={po.langKey}>
                    <Form.Check
                      type="checkbox"
                      label={lang[po.langKey]}
                      onChange={() => {
                        toogleSelection(po);
                      }}
                    />
                  </Form.Group>
                );
              })
            : selectedPermissions.map(po => {
                return (
                  <Form.Group controlId="formGridPermissions" key={po.langKey}>
                    <Form.Check
                      type="checkbox"
                      checked={po.selected}
                      label={lang[po.langKey]}
                      onChange={() => {
                        toogleSelection(po);
                      }}
                    />
                  </Form.Group>
                );
              })}
        </div>
        {/* <Row className="mb-2">
          <Form.Group as={Col} controlId="formGridPermissions">
            <Form.Check type="checkbox" label={lang.USER_PERMISSIONS_CHECK_1} />
          </Form.Group>
          <Form.Group as={Col} controlId="formGridPermissions">
            <Form.Check type="checkbox" label={lang.USER_PERMISSIONS_CHECK_2} />
          </Form.Group>
        </Row>
        <Row className="mb-2">
          <Form.Group as={Col} controlId="formGridPermissions">
            <Form.Check type="checkbox" label={lang.USER_PERMISSIONS_CHECK_3} />
          </Form.Group>
          <Form.Group as={Col} controlId="formGridPermissions">
            <Form.Check type="checkbox" label={lang.USER_PERMISSIONS_CHECK_4} />
          </Form.Group>
        </Row>
        <Row className="mb-2">
          <Form.Group as={Col} controlId="formGridPermissions">
            <Form.Check type="checkbox" label={lang.USER_PERMISSIONS_CHECK_5} />
          </Form.Group>
          <Form.Group as={Col} controlId="formGridPermissions">
            <Form.Check type="checkbox" label={lang.USER_PERMISSIONS_CHECK_6} />
          </Form.Group>
        </Row>
        <Row className="mb-2">
          <Form.Group as={Col} controlId="formGridPermissions">
            <Form.Check type="checkbox" label={lang.USER_PERMISSIONS_CHECK_7} />
          </Form.Group>
          <Form.Group as={Col} controlId="formGridPermissions">
            <Form.Check type="checkbox" label={lang.USER_PERMISSIONS_CHECK_8} />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formGridPermissions">
            <Form.Check type="checkbox" label={lang.USER_PERMISSIONS_CHECK_9} />
          </Form.Group>
        </Row> */}
        <Modal.Footer>
          <Button variant="success" type="submit">
            {lang.GLOBAL_SAVE_BUTTON}
          </Button>
          <Button variant="secondary" onClick={cancelBtn}>
            {lang.GLOBAL_CANCEL_BUTTON}
          </Button>
        </Modal.Footer>
      </Form>
    </div>
  );
};

export default UserForm;
