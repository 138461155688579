import React, { FormEvent, useRef, useState } from 'react';
import GlassService, { getCategoryById, IGlassCategory } from '../../../libraries/glass/GlassService';
import GlassForm from './GlassForm';
import ModalComponent from '../modal/ModalComponent';
import './styles.scss';
import { useMutation, useQueryClient } from 'react-query';
import { Language } from '../../languages/languageHandler';

interface ModalProps {
  isNew: boolean;
  hideBtn: () => void;
  lang: Language;
}

const NewGlass = ({ isNew, hideBtn, lang }: ModalProps) => {
  const queryClient = useQueryClient();

  const mutateAsync = useMutation(GlassService.createGlass);

  const uploadedImage = useRef<any>(null);
  const imageUploader = useRef<any>(null);

  const [loadedImage, setLoadedImage] = useState<string>();
  const [selectedCategory, setSelectedCategory] = useState<IGlassCategory>();

  const handleImageUpload = (e: any) => {
    const [file] = e.target.files;

    if (file !== null || file !== undefined) {
      const reader = new FileReader();
      const { current } = uploadedImage;

      current.file = file;

      reader.onload = (e: ProgressEvent<FileReader>) => {
        if (e.target) {
          current.src = e.target.result;

          if (reader.result) {
            const base64String: string = reader.result.toString().replace('data:', '').replace(/^.+,/, '');

            setLoadedImage(base64String);
          }
        }
      };

      reader.readAsDataURL(file);
    }
  };

  const handleCategoryChange = (id: number) => {
    getCategoryById(id).then(response => {
      console.log('Category changed to: ', response.record);
      setSelectedCategory(response.record);
    });
  };

  async function submitGlass(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const { glassCode, name } = e.target as typeof e.target & {
      glassCode: { value: string };
      name: { value: string };
    };

    console.log('NewGlass handleSubmit selectedCategory: ', selectedCategory);

    if (loadedImage !== undefined && selectedCategory) {
      await mutateAsync.mutateAsync({
        glassCode: glassCode.value,
        image: loadedImage,
        name: name.value,
        category: selectedCategory
      });
    }

    queryClient.invalidateQueries('glasses');

    hideBtn();
  }

  return (
    <>
      <ModalComponent
        show={isNew}
        handleClick={hideBtn}
        animation={true}
        size={'lg'}
        title={lang.GLASS_NEW}
        content={
          <>
            <GlassForm
              lang={lang}
              isNew={true}
              glassAttributes={null}
              submitForm={e => submitGlass(e)}
              handleImageUpload={handleImageUpload}
              imageUploader={imageUploader}
              uploadedImage={uploadedImage}
              cancelBtn={hideBtn}
              handleCategoryChange={handleCategoryChange}
            />
          </>
        }
      />
    </>
  );
};

export default NewGlass;
