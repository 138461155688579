import React from 'react';
import { IDonation } from '../../../libraries/donation/DonationService';
import { Col, Container, Row, Image, Modal, Button } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import './styles.scss';
import { Language } from '../../languages/languageHandler';

interface DetailsProps {
  entity: IDonation;
  toggleUpdate: () => void;
  deleteEntity: () => void;
  hideBtn: () => void;
  lang: Language;
}

const DetailsContent = ({ entity, toggleUpdate, deleteEntity, hideBtn, lang }: DetailsProps) => {
  return (
    <>
      <Container>
        <Row className="mb-2">
          <Col>
            <div className="modal-heading">
              <h5>{lang.GLOBAL_CONTENT}</h5>
            </div>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <div>
              <Image className="mx-auto d-block" src={`data:image/jpeg;base64,${entity.logo}`} fluid />
            </div>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <p className="iconCol">
              {/* <FontAwesomeIcon icon={faHandHoldingHeart} /> {entity.name}{' '} */}
              <Icon.HouseHeart />
              {entity.name}
            </p>
          </Col>
        </Row>
      </Container>

      <Modal.Footer>
        <Button className="updateBtn" onClick={toggleUpdate}>
          {lang.GLOBAL_UPDATE_BUTTON}
        </Button>
        <Button variant="danger" onClick={deleteEntity}>
          {lang.GLOBAL_DELETE_BUTTON}
        </Button>
        <Button variant="secondary" onClick={hideBtn}>
          {lang.GLOBAL_RETURN_BUTTON}
        </Button>
      </Modal.Footer>
    </>
  );
};

export default DetailsContent;
