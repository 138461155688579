import React, { useEffect, useState } from 'react';
import { Col, Container, Form } from 'react-bootstrap';
import { IDistribution } from '../../../libraries/distributionList';
import { PermissionOption, PermissionOptionResponse } from '../../../libraries/users';
import { Language } from '../../languages/languageHandler';
import { preparePermissions } from '../users/permissionsAux';
import './styles.scss';

interface PermissionProps {
  permissionOptionsData: PermissionOptionResponse;
  distribution: IDistribution;
  lang: Language;
}

const PermissionSection = ({ permissionOptionsData, distribution, lang }: PermissionProps) => {
  const [permissions, setPermissions] = useState<PermissionOption[]>();

  useEffect(() => {
    if (permissionOptionsData) {
      let permObj = distribution;
      preparePermissions(permissionOptionsData.list, permObj);
      setPermissions(permissionOptionsData.list);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Container>
        {permissions &&
          permissions.map(po => {
            return (
              <Form.Group as={Col} controlId="formGridPermissions" key={po.langKey}>
                <Form.Check type="checkbox" checked={po.selected} label={lang[po.langKey]} readOnly />
              </Form.Group>
            );
          })}
      </Container>
    </>
  );
};

export default PermissionSection;
