import React, { FormEvent } from 'react';
import { Modal, Form, Col, Button, Row } from 'react-bootstrap';
import { useMutation, useQuery, useQueryClient, UseQueryResult } from 'react-query';
import { getAds, IAd } from '../../../libraries/advertising/AdvertisingService';
import EventAdService from '../../../libraries/eventadvertising/EventAdService';
import { Language } from '../../languages/languageHandler';
import './styles.scss';

interface FormProps {
  cancelBtn: () => void;
  eventId: number;
  lang: Language;
}

const EventAdForm = ({ cancelBtn, eventId, lang }: FormProps) => {
  const { data }: UseQueryResult<IAd[], Error> = useQuery<IAd[], Error>('ads', () => getAds());

  const queryClient = useQueryClient();

  const submition = useMutation(EventAdService.createEventAd);

  async function submitEventAd(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (!data) return;

    const { eventId, adId, adOrder } = e.target as typeof e.target & {
      eventId: { value: number };
      adId: { value: number };
      adOrder: { value: number };
    };

    const ad = data.find(ad => Number(ad.adId) === Number(adId.value));

    if (!!ad) {
      await submition.mutateAsync({
        eventAdId: {
          eventId: eventId.value,
          ad: {
            adId: ad.adId,
            file: ad.file,
            time: ad.time,
            fileName: ad.fileName
          }
        },
        adOrder: adOrder.value
      });
    }

    queryClient.invalidateQueries('eventAds');

    cancelBtn();
  }

  return (
    <div>
      <Form onSubmit={e => submitEventAd(e)}>
        <div className="modal-heading">
          <h5>{lang.EVENT_AD_FORM_TITLE}</h5>
        </div>
        <Form.Control value={eventId} name="eventId" hidden />
        <Row className="mb-2">
          <Form.Group as={Col} controlId="formGridAd">
            <Form.Label>{lang.EVENT_AD_FORM_SELECT}</Form.Label>
            <Form.Control as="select" name="adId">
              {data?.map(ad => (
                <option value={ad.adId}>{ad.fileName}</option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group as={Col} controlId="formGridAdOrder">
            <Form.Label>{lang.EVENT_AD_FORM_ORDER}</Form.Label>
            <Form.Control type="number" name="adOrder" />
          </Form.Group>
        </Row>
        <Modal.Footer>
          <Button variant="success" type="submit">
            {lang.GLOBAL_SAVE_BUTTON}
          </Button>
          <Button variant="secondary" onClick={cancelBtn}>
            {lang.GLOBAL_CANCEL_BUTTON}
          </Button>
        </Modal.Footer>
      </Form>
    </div>
  );
};

export default EventAdForm;
