import React, { FormEvent, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { fetchFilteredAds, IAd, deleteAd } from '../../../libraries/advertising/AdvertisingService';
import NewAd from './NewAd';
import ShowAd from './ShowAd';
import PaginationComponent from '../pagination/PaginationComponent';
import { Table, Ratio, Image, Form, Row, Col, Button } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import { useSelector } from 'react-redux';
import { AppState } from '../../client-redux/reducers';
import { Language } from '../../languages/languageHandler';
import './styles.scss';
import { Page } from '../../../libraries/zps';
import Header from '../ui/Header';

function Ads() {
  const queryClient = useQueryClient();

  const lang = useSelector<AppState, Language | undefined>(state => state.lang.lang);

  const [page, setPage] = useState<Page<IAd>>();
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [keyword, setKeyword] = useState<string>();

  const [selectedBeginTime, setSelectedBeginTime] = useState<number | undefined>();
  const [selectedEndTime, setSelectedEndTime] = useState<number | undefined>();

  const [activeRow, setActiveRow] = useState<number | null>(null);
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const [openNew, setOpenNew] = useState<boolean>(false);
  const [openDetails, setOpenDetails] = useState<boolean>(false);

  useEffect(() => {
    fetchFilteredAds(pageNumber, pageSize, keyword, selectedBeginTime, selectedEndTime)
      .then(response => {
        setPage(response.page);
      })
      .catch(error => {
        console.log('fetch ads error: ', error);
      });
  }, [keyword, pageNumber, pageSize, selectedBeginTime, selectedEndTime]);

  const handleToggle =
    (adId: number): (() => void) =>
    () => {
      setActiveRow(adId);
      setIsSelected(!isSelected);
      setOpenDetails(!openDetails);
    };

  function toggleNewAdModal() {
    setOpenNew(!openNew);
  }

  function toggleDetailsModal() {
    setOpenDetails(!openDetails);
    setIsSelected(!isSelected);

    queryClient.fetchQuery('ads');
  }

  const isActive = (adId: number): boolean => {
    if (!activeRow) return false;

    return adId === activeRow;
  };

  function mediaHandler(filename: string, file: string) {
    const extension: string = filename.split('.')[1];

    if (extension === 'jpeg' || extension === 'jpg' || extension === 'png') {
      return (
        <Ratio aspectRatio="16x9">
          <Image id="table-video" className="align-middle" src={`data:image/${extension};base64,${file}`} />
        </Ratio>
      );
    } else if (extension === 'mp4') {
      return (
        <Ratio aspectRatio="16x9">
          <video
            id="table-video"
            src={`data:video/webm;base64,${file}`}
            autoPlay
            muted
            controls
            controlsList="nodownload nofullscreen"
          />
        </Ratio>
      );
    }
  }

  const handlePageChange = (n: number) => {
    setPageNumber(n);
  };

  const submitSearch = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const { keyword, beginTime, endTime } = e.target as typeof e.target & {
      keyword: { value: string };
      beginTime: { value: number };
      endTime: { value: number };
    };

    keyword.value ? setKeyword(keyword.value) : setKeyword(undefined);

    beginTime.value ? setSelectedBeginTime(beginTime.value) : setSelectedBeginTime(undefined);

    endTime.value ? setSelectedEndTime(endTime.value) : setSelectedEndTime(undefined);

    setPageNumber(0);
  };

  const resultsSelect = (e: any) => {
    setPageSize(e.target.value);
  };

  const deleteActiveAd = async (activeRow: number | null) => {
    if (
      activeRow !== null &&
      window.confirm('Are you certain you wish to delete this advertisement? This action is irreversible')
    ) {
      deleteAd(activeRow);

      queryClient.invalidateQueries('ads');

      setActiveRow(null);

      setOpenDetails(false);
    }
  };

  return (
    <div className="index-container">
      <div>{lang && <NewAd lang={lang} isNew={openNew} hideBtn={() => toggleNewAdModal()} />}</div>
      <div>
        {activeRow && lang && (
          <ShowAd
            lang={lang}
            adId={activeRow}
            openDetails={openDetails}
            hideBtn={() => toggleDetailsModal()}
            deleteBtn={() => deleteAd(activeRow)}
          />
        )}
      </div>
      <div className="top-index-section">
        <div className="top-left">
          <Header pageTitle={lang?.ADVERTISEMENTS} />
        </div>
        <div className="top-right">
          <button className="btn btn-md btn-success btn-table add-btn" onClick={() => toggleNewAdModal()}>
            <Icon.Plus />
            &nbsp;{lang?.GLOBAL_ADD_BUTTON}&nbsp;
          </button>
          {/* <button
            className={`btn btn-md btn-danger btn-table ${disableBtn(activeRow)}`}
            onClick={() => deleteAd(activeRow)}
          >
            <Icon.Trash />
            &nbsp;{lang?.GLOBAL_DELETE_BUTTON}&nbsp;
          </button> */}
        </div>
      </div>
      <div className="content-section">
        <div className="top-content-section">
          <div className="search-section">
            <Form className="search-form" onSubmit={e => submitSearch(e)}>
              <Row>
                <Col lg={1}>
                  <Form.Label>{lang?.GLOBAL_NAME}</Form.Label>
                </Col>
                <Col lg={3}>
                  <Form.Control size="sm" name="keyword" />
                </Col>
                <Col lg={1}>
                  <Form.Label>{lang?.EVENT_BEGIN_DATE_PLACEHOLDER}</Form.Label>
                </Col>
                <Col>
                  <Form.Control size="sm" type="number" name="beginTime" />
                </Col>
                <Col lg={1}>
                  <Form.Label>{lang?.EVENT_END_DATE_PLACEHOLDER}</Form.Label>
                </Col>
                <Col>
                  <Form.Control size="sm" type="number" name="endTime" />
                </Col>
                <Col>
                  <Button size="sm" type="submit">
                    {lang?.GLOBAL_SEARCH}
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
          <div className="results-section">
            <span>Mostrando </span>
            <Form.Select className="results-select" size="sm" onChange={e => resultsSelect(e)}>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </Form.Select>
            <span> resultados</span>
          </div>
        </div>
        <div className="index-table">
          <Table size="md" className="data-table">
            <thead className="table-head">
              <tr className="table-head-row">
                <th className="w-auto">#</th>
                <th className="w-25">{lang?.ADVERTISING_FILE}</th>
                <th className="w-25">
                  {lang?.ADVERTISING_DURATION} ({lang?.ADVERTISING_SECONDS})
                </th>
                <th className="w-25"></th>
              </tr>
            </thead>
            <tbody className="table-body">
              {page?.content.map(ad => (
                <tr
                  key={ad.adId}
                  className={`table-row ${isActive(ad.adId) ? 'activeRow' : ''}`}
                  onClick={handleToggle(ad.adId)}
                >
                  <td className="align-middle">{ad.adId}</td>
                  <td className="w-50">
                    <div className="media-container">{mediaHandler(ad.fileName, ad.file)}</div>
                  </td>
                  <td className="align-middle">{ad.time}</td>
                  <td className="align-middle">
                    <button className="delete-btn float-end" onClick={() => deleteActiveAd(ad.adId)}>
                      <Icon.Trash />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div className="pagination-section">
            <PaginationComponent
              total={page?.totalElements}
              itemsPerPage={pageSize}
              currentPage={pageNumber}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Ads;
