import React, { useState } from 'react';
import EventService, { IEvent } from '../../../libraries/events/EventService';
import EventDonationService, { IEventDonation } from '../../../libraries/eventdonation/EventDonationService';
import EventAdService, { IEventAd } from '../../../libraries/eventadvertising/EventAdService';
import EventGlassService, { IEventGlass } from '../../../libraries/eventglass/EventGlassService';
import GeneralInformation from './GeneralIformation';
import EventEquipment from './EventEquipment';
import EventGlass from './EventCupsContent';
import EventAd from './EventAdsContent';
import EventDonation from './EventDonationContent';
import { Tab, Tabs } from 'react-bootstrap';
import './styles.scss';
import EventEquipmentService, { IEventEquipment } from '../../../libraries/eventequipment/EventEquipmentService';
import { useQuery, useQueryClient, UseQueryResult } from 'react-query';
import NewEventEquipment from './NewEventEquipment';
import NewEventGlass from './NewEventGlass';
import NewEventAd from './NewEventAd';
import NewEventDonation from './NewEventDonation';
import { Language } from '../../languages/languageHandler';
import { ListResponse } from '../../../libraries/zps';

interface DetailsProps {
  event: IEvent;
  toggleUpdate: () => void;
  hideBtn: () => void;
  lang: Language;
}

const DetailsContent = ({ event, toggleUpdate, hideBtn, lang }: DetailsProps) => {
  const queryClient = useQueryClient();

  const equipment: UseQueryResult<ListResponse<IEventEquipment>> = useQuery(['eventEquipment', event.eventId], () =>
    EventEquipmentService.getEventEquipmentByEventId(event.eventId)
  );
  const entities: UseQueryResult<ListResponse<IEventDonation>> = useQuery(['eventDonations', event.eventId], () =>
    EventDonationService.getEventDonationEntityByEventId(event.eventId)
  );
  const ads: UseQueryResult<ListResponse<IEventAd>> = useQuery(['eventAds', event.eventId], () =>
    EventAdService.getEventAdByEventId(event.eventId)
  );
  const glasses: UseQueryResult<ListResponse<IEventGlass>> = useQuery(['eventGlasses', event.eventId], () =>
    EventGlassService.getEventGlassByEventId(event.eventId)
  );

  const [showEquipment, setShowEquipment] = useState<boolean>(true);
  const [showNewEquipment, setShowNewEquipment] = useState<boolean>(false);

  const [showCups, setShowCups] = useState<boolean>(true);
  const [showNewCup, setShowNewCup] = useState<boolean>(false);

  const [showAds, setShowAds] = useState<boolean>(true);
  const [showNewAd, setShowNewAd] = useState<boolean>(false);

  const [showDonations, setShowDonations] = useState<boolean>(true);
  const [showNewDonation, setShowNewDonation] = useState<boolean>(false);

  function toggleNewEquipment() {
    setShowEquipment(!showEquipment);
    setShowNewEquipment(!showNewEquipment);
  }

  function toggleNewCup() {
    setShowCups(!showCups);
    setShowNewCup(!showNewCup);
  }

  function toggleNewAd() {
    setShowAds(!showAds);
    setShowNewAd(!showNewAd);
  }

  function toggleNewDonation() {
    setShowDonations(!showDonations);
    setShowNewDonation(!showNewDonation);
  }

  const deleteEvent = (): void => {
    if (
      event !== null &&
      window.confirm('Are you certain you wish to delete this event? This action is irreversible')
    ) {
      EventService.deleteEvent(event.eventId);
      queryClient.invalidateQueries('events');
    }
  };

  return (
    <>
      <Tabs id="controlled-tab-example" defaultActiveKey="generalInfo" className="mb-3">
        <Tab eventKey="generalInfo" title={lang.EVENT_DETAILS_GENERAL}>
          <GeneralInformation
            lang={lang}
            event={event}
            deleteEvent={() => deleteEvent()}
            toggleUpdate={toggleUpdate}
            hideBtn={hideBtn}
          />
        </Tab>
        <Tab eventKey="equipment" title={lang.GLOBAL_EQUIPMENT}>
          {showEquipment && equipment.data !== undefined && (
            <EventEquipment
              lang={lang}
              equipmentList={equipment.data.list}
              eventId={event.eventId}
              hideBtn={hideBtn}
              addBtn={toggleNewEquipment}
            />
          )}
          {showNewEquipment && <NewEventEquipment lang={lang} event={event} cancelBtn={toggleNewEquipment} />}
        </Tab>
        <Tab eventKey="cups" title={lang.GLOBAL_GLASSES}>
          {showCups && glasses.data !== undefined && (
            <EventGlass
              lang={lang}
              glassesList={glasses.data.list}
              eventId={event.eventId}
              hideBtn={hideBtn}
              addBtn={toggleNewCup}
            />
          )}
          {showNewCup && <NewEventGlass lang={lang} cancelBtn={toggleNewCup} eventId={event.eventId} />}
        </Tab>
        <Tab eventKey="advertising" title={lang.ADVERTISEMENTS}>
          {showAds && ads.data !== undefined && (
            <EventAd
              lang={lang}
              adsList={ads.data.list}
              eventId={event.eventId}
              hideBtn={hideBtn}
              addBtn={toggleNewAd}
            />
          )}
          {showNewAd && <NewEventAd lang={lang} cancelBtn={toggleNewAd} eventId={event.eventId} />}
        </Tab>
        <Tab eventKey="donEntities" title={lang.GLOBAL_DONATION}>
          {showDonations && entities.data !== undefined && (
            <EventDonation
              lang={lang}
              donationsList={entities.data.list}
              eventId={event.eventId}
              hideBtn={hideBtn}
              addBtn={toggleNewDonation}
            />
          )}
          {showNewDonation && <NewEventDonation lang={lang} cancelBtn={toggleNewDonation} eventId={event.eventId} />}
        </Tab>
      </Tabs>
    </>
  );
};

export default DetailsContent;
