import React, { useState } from 'react';
import moment from 'moment';
import { Modal, Form, Col, Row, Button } from 'react-bootstrap';
import { IEvent } from '../../../libraries/events/EventService';
import { set } from 'lodash';
import './styles.scss';
import { Language } from '../../languages/languageHandler';

interface FormProps {
  isNew: boolean;
  eventAttributes: IEvent | null;
  submitForm: (e: any) => void;
  cancelBtn: () => void;
  lang: Language;
}

const EventsForm = ({ isNew, eventAttributes, submitForm, cancelBtn, lang }: FormProps) => {
  const [updatedAttributes, setUpdatedAttributes] = useState<IEvent | null>(eventAttributes);

  const handleChange = (fieldPath: string) => (event: any) => {
    if (!updatedAttributes) return;

    set(updatedAttributes, fieldPath, event?.target?.value);
    setUpdatedAttributes(updatedAttributes);
  };

  return (
    <div>
      <Form onSubmit={submitForm}>
        <div className="modal-heading">
          <h5>{lang.EVENT_DETAILS_GENERAL}</h5>
        </div>

        <Row className="mb-2">
          <Form.Group as={Col} controlId="formGridEventId">
            <Form.Label>{lang.EVENT_ID}</Form.Label>
            {isNew ? (
              <Form.Control type="number" placeholder={lang.EVENT_ID_PLACEHOLDER} name="eventId" />
            ) : (
              <Form.Control readOnly value={eventAttributes?.eventId} name="eventId" />
            )}
          </Form.Group>
          <Form.Group as={Col} controlId="formGridName">
            <Form.Label>{lang.GLOBAL_NAME}</Form.Label>
            {isNew ? (
              <Form.Control placeholder={lang.EVENT_NAME_PLACEHOLDER} name="name" />
            ) : (
              <Form.Control
                type="text"
                name="name"
                defaultValue={eventAttributes?.name}
                onChange={handleChange('name')}
              />
            )}
          </Form.Group>
        </Row>

        <Form.Group className="mb-2" controlId="formGridAddress">
          <Form.Label>{lang.EVENT_ADDRESS}</Form.Label>
          {isNew ? (
            <Form.Control name="address" />
          ) : (
            <Form.Control
              type="text"
              defaultValue={eventAttributes?.address}
              name="address"
              onChange={handleChange('address')}
            />
          )}
        </Form.Group>

        <Row className="mb-2">
          <Form.Group as={Col} controlId="formGridZipCode">
            <Form.Label>{lang.EVENT_ZIP_CODE}</Form.Label>
            {isNew ? (
              <Form.Control placeholder={lang.EVENT_ZIP_CODE_PLACEHOLDER} name="zipCode" />
            ) : (
              <Form.Control
                type="text"
                defaultValue={eventAttributes?.zipCode}
                name="zipCode"
                onChange={handleChange('zipCode')}
              />
            )}
          </Form.Group>
          <Form.Group as={Col} controlId="formGridLat">
            <Form.Label>{lang.EVENT_LAT}</Form.Label>
            {isNew ? (
              <Form.Control placeholder={lang.EVENT_LAT_PLACEHOLDER} name="lat" />
            ) : (
              <Form.Control type="text" defaultValue={eventAttributes?.lat} name="lat" onChange={handleChange('lat')} />
            )}
          </Form.Group>
          <Form.Group as={Col} controlId="formGridLong">
            <Form.Label>{lang.EVENT_LONG}</Form.Label>
            {isNew ? (
              <Form.Control placeholder={lang.EVENT_LONG_PLACEHOLDER} name="longitude" />
            ) : (
              <Form.Control
                type="text"
                defaultValue={eventAttributes?.longitude}
                name="longitude"
                onChange={handleChange('longitude')}
              />
            )}
          </Form.Group>
        </Row>

        <div className="modal-heading">
          <h5>{lang.EVENT_DETAILS_GENERAL_EVENT_DATES}</h5>
        </div>

        <Row className="mb-2">
          <Form.Group as={Col} controlId="formGridBeginDate">
            <Form.Label>{lang.EVENT_BEGIN_DATE}</Form.Label>
            {isNew ? (
              <Form.Control type="date" name="beginDate" />
            ) : (
              <Form.Control
                type="date"
                defaultValue={moment(eventAttributes?.beginDate).format('YYYY-MM-DD')}
                name="beginDate"
                onChange={handleChange('beginDate')}
              />
            )}
          </Form.Group>
          <Form.Group as={Col} controlId="formGridEndDate">
            <Form.Label>{lang.EVENT_END_DATE}</Form.Label>
            {isNew ? (
              <Form.Control type="date" name="endDate" />
            ) : (
              <Form.Control
                type="date"
                defaultValue={moment(eventAttributes?.endDate).format('YYYY-MM-DD')}
                name="endDate"
                onChange={handleChange('endDate')}
              />
            )}
          </Form.Group>
        </Row>

        <Row className="mb-2">
          <Form.Group as={Col} controlId="formGridInstallationDate">
            <Form.Label>{lang.EVENT_INSTALLATION}</Form.Label>
            {isNew ? (
              <Form.Control type="date" name="installationDate" />
            ) : (
              <Form.Control
                type="date"
                defaultValue={moment(eventAttributes?.installationDate).format('YYYY-MM-DD')}
                name="installationDate"
                onChange={handleChange('installationDate')}
              />
            )}
          </Form.Group>
          <Form.Group as={Col} controlId="formGridUninstalltionDate">
            <Form.Label>{lang.EVENT_UNINSTALLATION}</Form.Label>
            {isNew ? (
              <Form.Control type="date" name="uninstallationDate" />
            ) : (
              <Form.Control
                type="date"
                defaultValue={moment(eventAttributes?.uninstallationDate).format('YYYY-MM-DD')}
                name="uninstallationDate"
                onChange={handleChange('uninstallationDate')}
              />
            )}
          </Form.Group>
        </Row>

        <Modal.Footer>
          <Button variant="success" type="submit">
            {lang.GLOBAL_SAVE_BUTTON}
          </Button>
          <Button variant="secondary" onClick={cancelBtn}>
            {lang.GLOBAL_CANCEL_BUTTON}
          </Button>
        </Modal.Footer>
      </Form>
    </div>
  );
};

export default EventsForm;
