import React, { FormEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Row, Image, Dropdown, Container, Col } from 'react-bootstrap';
import zarphLogo from '../../../assets/img/zarph_white_horiz.png';
import './styles.scss';
import { login } from '../../client-redux/login';
import { useNavigate } from 'react-router-dom';
import { fetchLogin, LoginBody } from '../../../libraries/login';
import { AppState } from '../../client-redux/reducers';
import { Language } from '../../languages/languageHandler';
import { Alert } from '../alert';
import { StatusOper } from '../../../libraries/zps';
import * as Icon from 'react-bootstrap-icons';
import { selectedLang } from '../../client-redux/languageHandle';
import DropdownItem from 'react-bootstrap/esm/DropdownItem';
import { saveViews, updateActiveTab } from '../../client-redux/sidebarHandle';
import { buildUserMenus, ViewMapProps } from '../../utils/ViewMapProps';

export const Login = () => {
  const lang = useSelector<AppState, Language | undefined>(state => state.lang.lang);
  const token = useSelector<AppState, String | undefined>(state => state.login.token);
  const views = useSelector<AppState, ViewMapProps[]>(state => state.sidebar.views);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [entid, setEntid] = useState<string>('');
  const [uid, setUid] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [statusOper, setStatusOper] = useState<StatusOper | undefined>();

  const onEntidChange = (event: any) => {
    setEntid(event.target.value);
  };

  const onUidChange = (event: any) => {
    setUid(event.target.value);
  };

  const onPasswordChange = (event: any) => {
    setPassword(event.target.value);
  };

  const authentication = async (params: LoginBody) => {
    await fetchLogin(params)
      .then(async (response: any) => {
        if (response.data?.statusOper.code === 0) {
          dispatch(login(response.data));
          dispatch(selectedLang(response.data?.user?.lang || 1));
          dispatch(saveViews(buildUserMenus(response.data?.user?.permissions)));
          dispatch(updateActiveTab('EVENTS'));
        }
        setStatusOper(response.data?.statusOper);
      })
      .catch(error => console.log('Error: ', error));
  };

  const toggleAlert = () => {
    setStatusOper(undefined);
  };

  function submitLogin(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    authentication({
      entid,
      uid,
      password
    });
  }

  const setLanguage = (e: any) => {
    dispatch(selectedLang(Number(e)));
  };

  useEffect(() => {
    if (token && views && views.length > 0) {
      navigate(views[0].link);
    }
  }, [navigate, token, views]);

  return (
    <Container fluid className="login min-vh-100 d-flex align-items-center justify-content-center">
      {statusOper?.code && (
        <Alert
          position="top-center"
          variant="danger"
          visible={true}
          toggleAlert={() => toggleAlert()}
          errorCode={statusOper?.code}
          errorMessage={statusOper?.message}
        />
      )}
      <Row className="justify-content-center my-3">
        <Col xl={2} lg={3} sm={4} xs={12} className="px-xs-4">
          <Row>
            <Col className="text-center">
              <Image className="w-75 h-100" src={zarphLogo} />
            </Col>
          </Row>
          <Form onSubmit={e => submitLogin(e)}>
            <Row className="mt-3">
              <Col>
                <Form.Group>
                  <Form.Label className="text-white">{lang?.LOGIN_ENTITY}</Form.Label>
                  <Form.Control name="entid" value={entid} onChange={onEntidChange} />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <Form.Group>
                  <Form.Label className="text-white">{lang?.LOGIN_USER}</Form.Label>
                  <Form.Control name="uid" value={uid} onChange={onUidChange} />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <Form.Group>
                  <Form.Label className="text-white">{lang?.GLOBAL_PASSWORD}</Form.Label>
                  <Form.Control type="password" name="password" value={password} onChange={onPasswordChange} />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <Button className="login-button w-100" type="submit">
                  {lang?.LOGIN_BUTTON}
                </Button>
              </Col>
            </Row>
          </Form>
          <Row className="mt-3">
            <Col>
              <Dropdown onSelect={setLanguage}>
                <Dropdown.Toggle className="login-button">
                  <Icon.Translate />
                  <span className="mx-2">{lang?.GLOBAL_LANGUAGE_NAME}</span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <DropdownItem active eventKey="1">
                    {lang?.GLOBAL_LANGUAGE_EN}
                  </DropdownItem>
                  <DropdownItem eventKey="2">{lang?.GLOBAL_LANGUAGE_PT}</DropdownItem>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};
