import React, { FormEvent, useState } from 'react';
import { IAd, updateAd } from '../../../libraries/advertising/AdvertisingService';
import { Language } from '../../languages/languageHandler';
import AdsForm from './AdsForm';

interface UpdateProps {
  ad: IAd;
  toggleUpdate: () => void;
  lang: Language;
}

const UpdateContent = ({ ad, toggleUpdate, lang }: UpdateProps) => {
  const [adAttributes] = useState<IAd>(ad);

  function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (adAttributes !== null) {
      updateAd({
        adId: adAttributes.adId,
        file: adAttributes.file,
        time: adAttributes.time,
        fileName: adAttributes.fileName
      });

      toggleUpdate();
    }
  }

  return (
    <>
      <AdsForm
        lang={lang}
        isNew={false}
        adAttributes={adAttributes}
        submitForm={e => handleSubmit(e)}
        cancelBtn={toggleUpdate}
      />
    </>
  );
};

export default UpdateContent;
