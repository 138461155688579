import React, { FormEvent, useEffect, useState } from 'react';
import './styles.scss';
import { fetchFilteredDonations, IDonation } from '../../../libraries/donation/DonationService';
import PaginationComponent from '../pagination/PaginationComponent';
import NewDonation from './NewDonation';
import ShowDonation from './ShowDonation';
import { Table, Image, Form, Row, Col, Button } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import { useMutation, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { AppState } from '../../client-redux/reducers';
import { Language } from '../../languages/languageHandler';
import { Page } from '../../../libraries/zps';
import Header from '../ui/Header';
import ExportedDonationService from '../../../libraries/donation/DonationService';

function DonationEntities() {
  const queryClient = useQueryClient();

  const lang = useSelector<AppState, Language | undefined>(state => state.lang.lang);

  const mutation = useMutation(ExportedDonationService.deleteDonationEntity);

  const [page, setPage] = useState<Page<IDonation>>();
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [keyword, setKeyword] = useState<string>();

  const [activeRow, setActiveRow] = useState<number | null>(null);
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const [openNew, setOpenNew] = useState<boolean>(false);
  const [openDetails, setOpenDetails] = useState<boolean>(false);

  useEffect(() => {
    fetchFilteredDonations(pageNumber, pageSize, keyword).then(response => {
      setPage(response.page);
    });
  }, [keyword, pageNumber, pageSize]);

  const handleToggle =
    (donationId: number): (() => void) =>
    () => {
      setActiveRow(donationId);
      setIsSelected(!isSelected);
      setOpenDetails(!openDetails);
    };

  function toggleNewEntityModal() {
    setOpenNew(!openNew);
  }

  function toggleDetailsModal() {
    setOpenDetails(!openDetails);
    setIsSelected(!isSelected);

    queryClient.fetchQuery('donations');
  }

  const isActive = (donationId: number): boolean => {
    if (!activeRow) return false;

    return donationId === activeRow;
  };

  const submitSearch = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const { keyword } = e.target as typeof e.target & {
      keyword: { value: string };
    };

    keyword.value ? setKeyword(keyword.value) : setKeyword(undefined);

    setPageNumber(0);
  };

  const handlePageChange = (n: number): void => {
    setPageNumber(n);
  };

  const resultsSelect = (e: any) => {
    setPageSize(e.target.value);
  };

  const deleteEntity = async (activeRow: number | null) => {
    if (
      activeRow !== null &&
      window.confirm('Are you certain you wish to delete this entity? This action is irreversible')
    ) {
      await mutation.mutateAsync(activeRow);

      queryClient.invalidateQueries('donations');

      setActiveRow(null);

      setOpenDetails(false);
    }
  };

  return (
    <div className="index-container">
      <div>{lang && <NewDonation lang={lang} isNew={openNew} hideBtn={() => toggleNewEntityModal()} />}</div>
      <div>
        {activeRow && lang && (
          <ShowDonation
            lang={lang}
            openDetails={openDetails}
            donationId={activeRow}
            hideBtn={() => toggleDetailsModal()}
            deleteBtn={() => deleteEntity(activeRow)}
          />
        )}
      </div>
      <div className="top-index-section">
        <div className="top-left">
          <Header pageTitle={lang?.GLOBAL_DONATION} />
        </div>
        <div className="top-right">
          <button className="btn btn-md btn-success btn-table add-btn" onClick={() => toggleNewEntityModal()}>
            <Icon.Plus />
            &nbsp;{lang?.GLOBAL_ADD_BUTTON}&nbsp;
          </button>
        </div>
      </div>
      <div className="content-section">
        <div className="top-content-section">
          <div className="search-section">
            <Form className="search-form" onSubmit={e => submitSearch(e)}>
              <Row>
                <Col lg={1}>
                  <Form.Label>{lang?.GLOBAL_NAME}</Form.Label>
                </Col>
                <Col lg={3}>
                  <Form.Control size="sm" name="keyword" />
                </Col>
                <Col>
                  <Button size="sm" type="submit">
                    {lang?.GLOBAL_SEARCH}
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
          <div className="results-section">
            <span>Mostrando </span>
            <Form.Select className="results-select" size="sm" onChange={e => resultsSelect(e)}>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </Form.Select>
            <span> resultados</span>
          </div>
        </div>
        <div className="index-table">
          <Table size="md" className="data-table">
            <thead className="table-head">
              <tr className="table-head-row">
                <th id="donationId">#</th>
                <th id="donationLogo">{lang?.DONATION_ENTITIES_LOGO}</th>
                <th id="donationName">{lang?.GLOBAL_NAME}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {page?.content.map(entity => (
                <tr
                  key={entity.donationId}
                  className={`table-row ${isActive(entity.donationId) ? 'activeRow' : ''}`}
                  onClick={handleToggle(entity.donationId)}
                >
                  <td className="align-middle">{entity.donationId}</td>
                  <td>
                    <div id="donationLogo-container">
                      <Image className="table-image" src={`data:image/jpeg;base64,${entity.logo}`} rounded />
                    </div>
                  </td>
                  <td className="align-middle">{entity.name}</td>
                  <td className="align-middle">
                    <button className="delete-btn float-end" onClick={() => deleteEntity(entity.donationId)}>
                      <Icon.Trash />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div className="pagination-section">
            <PaginationComponent
              total={page?.totalElements}
              itemsPerPage={pageSize}
              currentPage={pageNumber}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DonationEntities;
