import { ActionTypes } from './actionTypes';
import { AppDispatch } from '../store';
import { createAction } from '../actions';
import { ViewMapProps } from '../../utils/ViewMapProps';

export const collapse = () => (dispatch: AppDispatch) => {
  dispatch(createAction(ActionTypes.COLLAPSE));
};

export const open = () => (dispatch: AppDispatch) => {
  dispatch(createAction(ActionTypes.OPEN));
};

export const updateActiveTab = (activeLabel?: string) => (dispatch: AppDispatch) => {
  dispatch(createAction(ActionTypes.UPDATE_ACTIVE_TAB, activeLabel));
};

export const saveViews = (views: ViewMapProps[]) => (dispatch: AppDispatch) => {
  dispatch(createAction(ActionTypes.SAVE_VIEWS, views));
};
