import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row, Table } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import { useMutation, useQuery, useQueryClient, UseQueryResult } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../client-redux/reducers';
import { Language } from '../../languages/languageHandler';
import {
  fetchUsers,
  IUserResponse,
  deleteUser,
  fetchPermissions,
  PermissionOptionResponse
} from '../../../libraries/users/index';
import './styles.scss';
import { LoginBody } from '../../../libraries/login';
import NewUser from './NewUser';
import ShowUser from './ShowUser';
import { loadLanguageSettings } from '../../client-redux/languageHandle';
import Header from '../ui/Header';

function Users() {
  const lang = useSelector<AppState, Language | undefined>(state => state.lang.lang);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const login = useSelector<AppState, LoginBody | undefined>(state => state.login.user);

  const permissionOptionData: UseQueryResult<PermissionOptionResponse> = useQuery([], () => fetchPermissions());

  const [entid, _setEntid] = useState<string | undefined>(login?.entid);
  const [limit, _setLimit] = useState<number>(100);
  const [offset, _SetOffset] = useState<number>(0);
  const [totalRows, _setTotalRows] = useState<boolean>(true);

  const [activeRow, setActiveRow] = useState<number | null>(null);
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const [openNew, setOpenNew] = useState<boolean>(false);
  const [openDetails, setOpenDetails] = useState<boolean>(false);

  const { data }: UseQueryResult<IUserResponse> = useQuery('users', () =>
    fetchUsers(entid, limit, offset, totalRows.toString())
  );

  useEffect(() => {
    dispatch(loadLanguageSettings());
  }, [dispatch]);

  const mutation = useMutation(deleteUser);

  const handleToggle =
    (uid: number): (() => void) =>
    () => {
      setActiveRow(uid);
      setIsSelected(!isSelected);
      setOpenDetails(!openDetails);
    };

  const isActive = (uid: number): boolean => {
    if (!activeRow) return false;

    return uid === activeRow;
  };

  const toggleNewUserModal = () => {
    setOpenNew(!openNew);
  };

  const toggleDetailsModal = () => {
    setOpenDetails(!openDetails);
    setIsSelected(!isSelected);

    queryClient.invalidateQueries('users');
  };

  const langIdentifier = (language: number) => {
    switch (language) {
      case 0:
        return lang?.USER_LANG_EN;
      case 1:
        return lang?.USER_LANG_PT;
    }
  };

  const deleteActiveUser = async (activeRow: number | null) => {
    if (activeRow !== null && window.confirm(lang?.USER_DELETE_CONFIRM)) {
      await mutation.mutateAsync(activeRow);

      queryClient.invalidateQueries('users');

      setActiveRow(null);

      setOpenDetails(false);
    }
  };

  return (
    <Container fluid>
      {lang && permissionOptionData.data && (
        <NewUser
          permissionOptionData={permissionOptionData.data}
          lang={lang}
          isNew={openNew}
          hideBtn={() => toggleNewUserModal()}
        />
      )}
      {activeRow && lang && permissionOptionData.data && (
        <ShowUser
          lang={lang}
          openDetails={openDetails}
          uid={activeRow}
          hideBtn={() => toggleDetailsModal()}
          deleteBtn={() => deleteActiveUser(activeRow)}
          permissionOptionData={permissionOptionData.data}
        />
      )}
      <Header pageTitle={lang?.GLOBAL_USERS} />
      <Row className="align-items-center justify-content-end">
        <Col xs="auto">
          <Button variant="success" size="sm" onClick={toggleNewUserModal}>
            <Icon.PersonPlusFill />
            {/* &nbsp;<span className="hidden-sm hidden-xs">{lang?.GLOBAL_ADD_BUTTON}</span>&nbsp; */}
          </Button>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col>
          <Table responsive striped hover size="md" className="data-table">
            <thead className="table-head">
              <tr className="table-head-row">
                <th>#</th>
                <th>{lang?.GLOBAL_NAME}</th>
                <th>{lang?.USER_EMAIL}</th>
                <th>{lang?.USER_CARD}</th>
                <th>{lang?.USER_REGION}</th>
                <th>{lang?.USER_LANG}</th>
                <th></th>
              </tr>
            </thead>
            <tbody className="table-body">
              {data?.users?.map(user => (
                <tr
                  key={user.uid}
                  className={`table-row ${isActive(user.uid) ? 'activeRow' : ''}`}
                  onClick={handleToggle(user.uid)}
                >
                  <td>{user.uid}</td>
                  <td>{user.name}</td>
                  <td>{user.email}</td>
                  <td>{user.card}</td>
                  <td>{user.region}</td>
                  <td>{langIdentifier(user.lang)}</td>
                  <td className="equipmentPDF pdfBtn" onClick={() => deleteActiveUser(user.uid)}>
                    <Icon.Trash />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
}

export default Users;
