import React, { useEffect, useState } from 'react';
import { fetchUser, IUserResponse, PermissionOption, PermissionOptionResponse } from '../../../libraries/users/index';
import { useQuery, UseQueryResult } from 'react-query';
import { Language } from '../../languages/languageHandler';
import ModalComponent from '../modal/ModalComponent';
import './styles.scss';
import DetailsContent from './DetailsContent';
import UpdateContent from './UpdateContent';
import { preparePermissions } from './permissionsAux';
import { AppState } from '../../client-redux/reducers';
import { LoginBody } from '../../../libraries/login';
import { useSelector } from 'react-redux';

interface ModalProps {
  uid: number;
  openDetails: boolean;
  hideBtn: () => void;
  deleteBtn: () => void;
  lang: Language;
  permissionOptionData: PermissionOptionResponse;
}

const ShowUser = ({ uid, openDetails, hideBtn, lang, permissionOptionData }: ModalProps) => {
  const login = useSelector<AppState, LoginBody | undefined>(state => state.login.user);
  const userData: UseQueryResult<IUserResponse> = useQuery(['user', uid], () => fetchUser(login?.entid, String(uid)));

  const [showDetails, setShowDetails] = useState<boolean>(true);
  const [showUpdate, setShowUpdate] = useState<boolean>(false);

  const [permissionOptions, setPermissionOptions] = useState<PermissionOption[]>([]);
  const [user, setUser] = useState<any>();

  useEffect(() => {
    if (userData?.status === 'success') {
      let userObj = userData.data.users[0];
      setUser(userObj);
      preparePermissions(permissionOptionData.list, userObj);
      setPermissionOptions(permissionOptionData.list);
    }
  }, [userData, permissionOptionData]);

  const toggleUpdate = () => {
    setShowDetails(!showDetails);
    setShowUpdate(!showUpdate);
  };

  return (
    <>
      <ModalComponent
        show={openDetails}
        handleClick={hideBtn}
        animation={true}
        title={user?.name}
        content={
          <>
            {showDetails && user && (
              <DetailsContent
                lang={lang}
                user={user}
                toggleUpdate={() => toggleUpdate()}
                hideBtn={hideBtn}
                permissionOptions={permissionOptions}
              />
            )}
            {showUpdate && user && (
              <UpdateContent
                lang={lang}
                user={user}
                toggleUpdate={() => toggleUpdate()}
                permissionOptions={permissionOptions}
              />
            )}
          </>
        }
        size={'xl'}
      />
    </>
  );
};

export default ShowUser;
