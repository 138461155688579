import React, { useState } from 'react';
import EventGlassService, { IEventGlass, IEventGlassId } from '../../../libraries/eventglass/EventGlassService';
import { Container, Table, Row, Col, Modal, Button, Image } from 'react-bootstrap';
import './styles.scss';
import { useMutation, useQueryClient } from 'react-query';
import { Language } from '../../languages/languageHandler';

interface EventGlassProps {
  glassesList: IEventGlass[] | undefined;
  eventId: number;
  hideBtn: () => void;
  addBtn: () => void;
  lang: Language;
}

const EventGlass = ({ glassesList, eventId, hideBtn, addBtn, lang }: EventGlassProps) => {
  const { mutateAsync } = useMutation(EventGlassService.deleteEventGlass);

  const queryClient = useQueryClient();

  const [activeRow, setActiveRow] = useState<IEventGlassId | null>(null);
  const [isSelected, setIsSelected] = useState<boolean>(false);

  const handleToggle =
    (eventGlassId: IEventGlassId): (() => void) =>
    () => {
      setActiveRow(eventGlassId);
      setIsSelected(!isSelected);
    };

  const isActive = (eventGlassId: IEventGlassId): boolean => {
    if (!activeRow) return false;

    return eventGlassId.eventId === activeRow.eventId && eventGlassId.glass.glassId === activeRow.glass.glassId;
  };

  const deleteEventGlass = async (activeRow: IEventGlassId | null) => {
    if (activeRow !== null && window.confirm('Are you certain you wish to remove this cup?')) {
      await mutateAsync({
        eventId: eventId,
        glass: activeRow.glass
      });

      queryClient.invalidateQueries('eventGlasses');

      setActiveRow(null);
    }
  };

  return (
    <>
      <Container>
        <Row className="mb-2">
          <Col>
            <div className="event-modal-heading">
              <h5>{lang.GLOBAL_GLASSES}</h5>
            </div>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <div className="event-details-table">
              <Table size="sm">
                <thead>
                  <tr>
                    <th>{lang.GLASS_IMAGE}</th>
                    <th>{lang.EVENT_GLASS_RECEPTACLE}</th>
                    <th>{lang.EVENT_GLASS_DELIVERY_ANIMATION}</th>
                  </tr>
                </thead>
                <tbody>
                  {glassesList?.map(glass => (
                    <tr
                      className={`table-row ${isActive(glass.eventGlassId) ? 'activeRow' : ''}`}
                      onClick={handleToggle(glass.eventGlassId)}
                    >
                      <td>
                        <div className="eventCup-container">
                          <Image
                            className="eventCupsImage align-self-center"
                            src={`data:image/jpeg;base64,${glass.eventGlassId.glass.image}`}
                            rounded
                          />
                        </div>
                      </td>
                      <td>{glass.receptacle}</td>
                      <td>{glass.deliveryAnimation}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
      </Container>
      <Modal.Footer>
        <Button variant="success" onClick={addBtn}>
          {lang.GLOBAL_ADD_BUTTON}
        </Button>
        <Button variant="danger" onClick={() => deleteEventGlass(activeRow)}>
          {lang.GLOBAL_DELETE_BUTTON}
        </Button>
        <Button variant="secondary" onClick={hideBtn}>
          {lang.GLOBAL_RETURN_BUTTON}
        </Button>
      </Modal.Footer>
    </>
  );
};

export default EventGlass;
