import React from 'react';
import { IUser, deleteUser, PermissionOption } from '../../../libraries/users/index';
import { Language } from '../../languages/languageHandler';
import { getLanguageName } from '../../languages/languageUtils';
import { Container, Row, Col, Modal, Button, Form } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import './styles.scss';

interface DetailsProps {
  user: IUser;
  toggleUpdate: () => void;
  hideBtn: () => void;
  lang: Language;
  permissionOptions: PermissionOption[];
}

const DetailsContent = ({ permissionOptions, user, toggleUpdate, hideBtn, lang }: DetailsProps) => {
  const deleteActiveUser = () => {
    if (user !== null && window.confirm(lang?.USER_DELETE_CONFIRM)) {
      deleteUser(user.uid);

      window.location.reload();
    }
  };
  console.log('DetailsContent permissionOptions', permissionOptions);

  return (
    <>
      <Container>
        <Row className="mb-2">
          <Col>
            <div className="modal-heading">
              <h5>{lang.USER_INFO}</h5>
            </div>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <h6>{lang.EVENT_DETAILS_GENERAL}</h6>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <p>
              <Icon.Building />
              &nbsp; {user.entid}
            </p>
          </Col>
          <Col>
            <p>
              <Icon.PersonFill />
              &nbsp; {user.uid}
            </p>
          </Col>
          <Col>
            <p>
              <Icon.PersonBadgeFill />
              &nbsp; {user.card}
            </p>
          </Col>
        </Row>
        <Row className="mb-2" xs={3}>
          <Col>
            <p>
              <Icon.Translate />
              &nbsp; {getLanguageName(user.lang)}
            </p>
          </Col>
          <Col>
            <p>
              <Icon.Globe />
              &nbsp; {user.region}
            </p>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <h6>{lang.USER_CONTACT_INFO} </h6>
          </Col>
        </Row>
        <Row className="mb-2" xs={3}>
          <Col>
            <p>
              <Icon.EnvelopeFill />
              &nbsp; {user.email}
            </p>
          </Col>
          <Col>
            <p>
              <Icon.TelephoneFill />
              &nbsp; {user.mobile}
            </p>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <div className="modal-heading">
              <h5>{lang.USER_PERMISSIONS}</h5>
            </div>
          </Col>
        </Row>
        <div className="mb-3 permissionsRow">
          {permissionOptions &&
            permissionOptions.map(po => {
              return (
                <Form.Group as={Col} controlId="formGridPermissions" key={po.langKey}>
                  <Form.Check type="checkbox" checked={po.selected} label={lang[po.langKey]} readOnly />
                </Form.Group>
              );
            })}
        </div>
      </Container>
      <Modal.Footer>
        <Button className="updateBtn" onClick={toggleUpdate}>
          {lang.GLOBAL_UPDATE_BUTTON}
        </Button>
        <Button variant="danger" onClick={deleteActiveUser}>
          {lang.GLOBAL_DELETE_BUTTON}
        </Button>
        <Button variant="secondary" onClick={hideBtn}>
          {lang.GLOBAL_RETURN_BUTTON}
        </Button>
      </Modal.Footer>
    </>
  );
};

export default DetailsContent;
