import { ReactNode } from "react"
import { Sidebar } from "."

import './sidebar.scss'
import { collapse } from "../../client-redux/sidebarHandle";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../client-redux/reducers";

export const WithNavBar = ({children} : {children: ReactNode}) => {
    const sidebarCollapsed = useSelector<AppState, boolean>(state => state.sidebar.isCollapsed);
    const dispatch = useDispatch();

    const closeNavBar = () => {
        if(!sidebarCollapsed){
            dispatch(collapse())
        }
    }

    return <>
            <Sidebar/>
            <div className="content" onClick={closeNavBar}>
                {children}
            </div>
        </>
}