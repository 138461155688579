import React from 'react';
import { Modal } from 'react-bootstrap';
import './styles.scss';

const ModalComponent = props => {
  return (
    <Modal show={props.show} onHide={props.handleClick} animation={props.animation} size={props.size} centered>
      <Modal.Header className="modal-header" closeButton closeVariant="white">
        <Modal.Title>
          <h2>{props.title}</h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.content}</Modal.Body>
    </Modal>
  );
};

export default ModalComponent;
