import React, { useEffect, useState } from 'react';
import { IUser } from '../../../libraries/users';
import { Language } from '../../languages/languageHandler';
import { Button, Col, Row, Table } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import UserPicker from './UserPicker';

interface UserSectionFormProps {
  lang: Language;
  addToUserList: (e: any) => void;
  deleteFromUserList: (e: any) => void;
  userList: IUser[];
  toggleOpenUserForm: (openUserForm: boolean) => void;
  openUserForm: boolean;
}

const UserSectionForm = ({
  lang,
  addToUserList,
  deleteFromUserList,
  userList,
  toggleOpenUserForm,
  openUserForm
}: UserSectionFormProps) => {
  const [userData, setUserData] = useState<IUser[]>([]);

  useEffect(() => {
    setUserData(userList);
  }, [userList, openUserForm]);

  return (
    <>
      {!openUserForm && (
        <>
          <Row className="mb-4">
            <Col>
              <Button size="sm" onClick={() => toggleOpenUserForm(true)}>
                <Icon.PlusLg />
              </Button>
            </Col>
          </Row>
          <div className="distribution-details-table">
            <Table size="md">
              <thead>
                <tr>
                  <th id="uidColumn">ID</th>
                  <th id="userNameColumn">{lang?.GLOBAL_NAME}</th>
                  <th id="deleteColumn"></th>
                </tr>
              </thead>
              <tbody>
                {userData.map((user, index) => (
                  <tr key={user.uid} className="distribution-sections-table-body-row">
                    <td>{user.uid}</td>
                    <td>{user.name}</td>
                    <td>
                      <Button
                        className="distribution-sections-table-delet-btn"
                        onClick={() => deleteFromUserList(index)}
                      >
                        <Icon.Trash />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </>
      )}
      {openUserForm && <UserPicker addToUsersList={addToUserList} lang={lang} />}
    </>
  );
};

export default UserSectionForm;
