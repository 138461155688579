import { ApiResponse, zps_ea_war } from "../zps";
import axios from "axios";

const LANGUAGE_BASE_URL = `${zps_ea_war}/language`;

export interface ILanguage {
    langid: number;
    name: string;
    nativeName: string;
    iso639_1: string;
}

export interface ILanguageResponse extends ApiResponse {
    languages: ILanguage[];
}

export const fetchLanguages = async () => {
    const response = await axios.get(LANGUAGE_BASE_URL);
    return response.data;
}