/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import './styles.scss';
import * as Icon from 'react-bootstrap-icons';
import {
  deleteDistributionById,
  fetchDistributionList,
  IDistribution,
  IDistributionListResponse
} from '../../../libraries/distributionList';
import { useSelector } from 'react-redux';
import { AppState } from '../../client-redux/reducers';
import { Language } from '../../languages/languageHandler';
import { Button, Form, Table } from 'react-bootstrap';
import ShowDistributionList from './ShowDistributionList';
import { fetchPermissions, PermissionOptionResponse } from '../../../libraries/users';
import NewDistribution from './NewDistribution';
import PaginationComponent from '../pagination/PaginationComponent';

const DistributionList = () => {
  const lang = useSelector<AppState, Language | undefined>(state => state.lang.lang);

  const [offset, _setOffset] = useState<number>(0);
  const [limit, setLimit] = useState<number>(15);

  const [totalRows, setTotalRows] = useState<boolean>(true);

  const [pageNumber, setPageNumber] = useState<number>(0);

  const [message, setMessage] = useState<IDistributionListResponse>();
  const [distributionList, setDistributionList] = useState<IDistribution[]>([]);
  const [statusCode, setStatusCode] = useState<number>();

  const [noResults, setNoResults] = useState<boolean>(false);

  const [activeRow, setActiveRow] = useState<number | null>(null);
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const [openNew, setOpenNew] = useState<boolean>(false);
  const [openDetails, setOpenDetails] = useState<boolean>(false);

  const [permissionOptions, setPermissionOptions] = useState<PermissionOptionResponse>();

  useEffect(() => {
    fetchPermissions().then(response => {
      setPermissionOptions(response);
    });
  }, []);

  useEffect(() => {
    fetchDistributionList(offset, limit, totalRows).then(response => {
      if (response.statusOper.code === 0) {
        setStatusCode(response.statusOper.code);
        setMessage(response);
        setDistributionList(response.list);
      }
    });
  }, [limit, offset, totalRows]);

  const loadData = () => {
    fetchDistributionList(offset, limit, totalRows).then(response => {
      if (response.statusOper.code === 0) {
        setStatusCode(response.statusOper.code);
        setMessage(response);
        setDistributionList(response.list);
      }
    });
  };

  const resultsSelect = (e: any) => {
    setLimit(e.target.value);
  };

  const isActive = (distributionId: number): boolean => {
    if (!activeRow) return false;

    return distributionId === activeRow;
  };

  const changePage = (n: number) => {
    if (n < pageNumber) {
      _setOffset(offset - limit);
    } else if (n > pageNumber) {
      _setOffset(offset + limit);
    }
    setPageNumber(n);
  };

  const toggleNewDistributionModal = () => {
    setOpenNew(!openNew);
  };

  const handleToggle =
    (distributionId: number | undefined): (() => void) =>
    () => {
      if (distributionId) {
        setActiveRow(distributionId);
        setIsSelected(!isSelected);
        setOpenDetails(!openDetails);
      }
    };

  const toggleDetailsModal = () => {
    setOpenDetails(!openDetails);
    setIsSelected(!isSelected);
  };

  const deleteFromDistributionList = (id: number) => {
    deleteDistributionById(id).then(response => {
      if (response.data.statusOper.code === 0) {
        loadData();
      }
    });
  };

  return (
    <div className="index-container">
      {activeRow && lang && permissionOptions && (
        <ShowDistributionList
          distributionId={activeRow}
          openDetails={openDetails}
          hideBtn={toggleDetailsModal}
          lang={lang}
          permissionOptions={permissionOptions}
        />
      )}
      {lang && permissionOptions && (
        <NewDistribution
          isNew={openNew}
          hideBtn={toggleNewDistributionModal}
          lang={lang}
          permissionOptions={permissionOptions}
          haveChanged={loadData}
        />
      )}
      <div className="top-index-section">
        <div className="top-left">
          <h2>{lang?.DISTRIBUTION_LIST} </h2>
        </div>
        <div className="top-right">
          <button className="btn btn-md btn-success btn-table add-btn" onClick={toggleNewDistributionModal}>
            <Icon.Plus />
            &nbsp;{lang?.GLOBAL_ADD_BUTTON}&nbsp;
          </button>
        </div>
      </div>
      <div className="content-section">
        <div className="top-content-section">
          <div className="results-section">
            <span>{lang?.GLOBAL_SHOWING} </span>
            <Form.Select className="results-select" size="sm" onChange={e => resultsSelect(e)}>
              <option value="15">15</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </Form.Select>
            <span> {lang?.GLOBAL_RESULTS}</span>
          </div>
        </div>
        <div className="index-table">
          <Table size="md" className="data-table">
            <thead className="table-head">
              <tr className="table-head-row">
                <th id="idColumn">ID</th>
                <th id="distributionEventColumn">Evento</th>
                <th id="deleteDistributionColumn"></th>
              </tr>
            </thead>
            <tbody>
              {statusCode === 9004 && (
                <tr>
                  <td colSpan={12} style={{ textAlign: 'center' }}>
                    Sem resultados
                  </td>
                </tr>
              )}
              {distributionList.map((distribution, index) => (
                <tr
                  key={index}
                  className={`table-row ${distribution.id && isActive(distribution.id) ? 'activeRow' : ''}`}
                >
                  <td onClick={handleToggle(distribution.id)}>{distribution.id}</td>
                  <td onClick={handleToggle(distribution.id)}>{distribution.eventDescription}</td>
                  <td>
                    <Button
                      className="distribution-sections-table-delet-btn"
                      onClick={() => deleteFromDistributionList(distribution.id)}
                    >
                      <Icon.Trash />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div className="pagination-section">
            <PaginationComponent
              total={message?.totalRows}
              itemsPerPage={limit}
              currentPage={pageNumber}
              onPageChange={changePage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DistributionList;
