import React from 'react';
import { IAd } from '../../../libraries/advertising/AdvertisingService';
import * as Icon from 'react-bootstrap-icons';
import './styles.scss';
import { Col, Container, Row, Modal, Button, Ratio, Image } from 'react-bootstrap';
import { Language } from '../../languages/languageHandler';

interface DetailsProps {
  ad: IAd;
  toggleUpdate: () => void;
  deleteAd: () => void;
  hideBtn: () => void;
  lang: Language;
}

const DetailsContent = ({ ad, toggleUpdate, hideBtn, deleteAd, lang }: DetailsProps) => {
  function getExtension(filename: string, file: string) {
    const extension: string = filename.split('.')[1];

    if (extension === 'jpeg' || extension === 'jpg' || extension === 'png') {
      return (
        <Ratio aspectRatio="16x9">
          <Image id="table-video" className="align-middle" src={`data:image/${extension};base64,${file}`} />
        </Ratio>
      );
    } else if (extension === 'mp4') {
      return (
        <Ratio aspectRatio="16x9">
          <video id="table-video" src={`data:video/webm;base64,${file}`} controls />
        </Ratio>
      );
    }
  }

  return (
    <>
      <Container>
        <Row className="mb-2">
          <Col>
            <div className="modal-heading">
              <h5>{lang.GLOBAL_CONTENT}</h5>
            </div>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>{getExtension(ad.fileName, ad.file)}</Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <h6>{lang.ADVERTISING_DURATION}</h6>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <p className="iconCol">
              <Icon.StopwatchFill /> {ad.time} {lang.ADVERTISING_SECS}
            </p>
          </Col>
        </Row>
      </Container>

      <Modal.Footer>
        <Button className="updateBtn" onClick={toggleUpdate}>
          {lang.GLOBAL_UPDATE_BUTTON}
        </Button>
        <Button variant="danger" onClick={deleteAd}>
          {lang.Eliminar}
        </Button>
        <Button variant="secondary" onClick={hideBtn}>
          {lang.GLOBAL_RETURN_BUTTON}
        </Button>
      </Modal.Footer>
    </>
  );
};

export default DetailsContent;
