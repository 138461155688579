import { useEffect, useState } from 'react';

export const formatAmount = (amount: number) => {
  return formatCentAmount(amount);
};

export const formatCentAmount = (amount: number) => {
  if (!amount || amount === 0) return '0,00 €';
  return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(amount * 0.01);
};

export const formatDecimalAmount = (amount: number) => {
  if (!amount || amount === 0) return '0,00 €';
  return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(amount);
};

export const formatAmount2 = (amount: any, currency?: any, showDecimal?: boolean) => {
  if (isNaN(amount) /* if not number*/) {
    amount = +amount;
  }

  if (currency === undefined) currency = 'EUR';

  if (showDecimal === undefined) showDecimal = true;

  let res = '0,00';
  if (!isNaN(amount) && currency !== undefined) {
    let sign = amount < 0 ? '-' : '';
    amount = amount.toString().replace('-', '');
    if (showDecimal) {
      if (amount.length > 2) {
        let cents = amount.substr(amount.length - 2, 2);
        let euro = amount.substr(0, amount.length - 2);
        res = euro + ',' + cents;
      } else if (amount.length > 1) res = '0,' + amount;
      else res = '0,0' + amount;
    } else {
      if (amount.length > 2) res = amount.substr(0, amount.length - 2);
      else res = '0';
    }
    return `${sign}${res} ${currency === 'EUR' ? '€' : ''}`;
  }
  return '';
};

interface WindowSize {
  width: number;
  height: number;
}

export function useWindowSize(): WindowSize {
  const [size, setSize] = useState<WindowSize>({
    width: 0,
    height: 0
  });

  useEffect(() => {
    function updateSize() {
      setSize({
        width: window.screen.width,
        height: window.screen.height
      });
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return size;
}

export const capitalizeWords = (str: string) => {
  return str
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};
