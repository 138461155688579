import React from 'react';
import { Col, Container, Row, Table } from 'react-bootstrap';
import './styles.scss';

interface EmailSectionProps {
  emails: string[];
}

const EmailSection = ({ emails }: EmailSectionProps) => {
  return (
    <>
      <Container>
        <Row className="mb-2">
          <Col>
            <div className="distribution-details-table">
              <Table size="sm">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Email</th>
                  </tr>
                </thead>
                <tbody>
                  {emails.map((email, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{email}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default EmailSection;
