import React, { FormEvent, useRef, useState } from 'react';
import { createAd } from '../../../libraries/advertising/AdvertisingService';
import AdsForm from './AdsForm';
import ModalComponent from '../modal/ModalComponent';
import './styles.scss';
import { useQueryClient } from 'react-query';
import { Language } from '../../languages/languageHandler';

interface ModalProps {
  isNew: boolean;
  hideBtn: () => void;
  lang: Language;
}

const NewAdvertisement = ({ isNew, hideBtn, lang }: ModalProps) => {
  const queryClient = useQueryClient();

  const uploadedFile = useRef<any>(null);
  const fileUploader = useRef<any>(null);

  const [loadedFile, setLoadedFile] = useState<string>();
  const [filename, setFilename] = useState<string>();

  const handleFileUpload = (e: any) => {
    const [file] = e.target.files;

    if (file !== null || file !== undefined) {
      const reader = new FileReader();

      const { current } = uploadedFile;

      current.file = file;

      console.log(current.file.name);

      reader.onload = (e: ProgressEvent<FileReader>) => {
        if (e.target) {
          current.src = e.target.result;

          if (reader.result) {
            const base64String: string = reader.result.toString().replace('data:', '').replace(/^.+,/, '');

            setLoadedFile(base64String);
          }
        }
      };
      setFilename(current.file.name);
      reader.readAsDataURL(file);
    }
  };

  async function submitAdvertisement(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const { adId, time } = e.target as typeof e.target & {
      adId: { value: number };
      time: { value: number };
    };

    if (loadedFile !== undefined && filename !== undefined) {
      console.log(filename);
      await createAd({
        adId: adId.value,
        file: loadedFile,
        fileName: filename,
        time: time.value
      });
    }

    queryClient.invalidateQueries('ads');

    hideBtn();
  }

  return (
    <>
      <ModalComponent
        show={isNew}
        handleClick={hideBtn}
        animation={true}
        size={'lg'}
        title={lang.ADVERTISING_NEW}
        content={
          <>
            <AdsForm
              lang={lang}
              isNew={true}
              adAttributes={null}
              submitForm={e => submitAdvertisement(e)}
              handleFileUpload={handleFileUpload}
              fileUploader={fileUploader}
              uploadedFile={uploadedFile}
              cancelBtn={hideBtn}
            />
          </>
        }
      />
    </>
  );
};

export default NewAdvertisement;
