import React, { FormEvent } from 'react';
import { newCategory } from '../../../libraries/glass/GlassService';
import { Language } from '../../languages/languageHandler';
import ModalComponent from '../modal/ModalComponent';
import CategoryForm from './CategoryForm';
import './styles.scss';

interface ModalProps {
  isNew: boolean;
  hideBtn: () => void;
  lang: Language;
}

const NewCategory = ({ isNew, hideBtn, lang }: ModalProps) => {
  async function submitCategory(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const { description } = e.target as typeof e.target & {
      description: { value: string };
    };

    newCategory({
      categoryId: 0,
      description: description.value
    });

    hideBtn();
  }

  return (
    <>
      <ModalComponent
        show={isNew}
        handleClick={hideBtn}
        animation={true}
        size={'sm'}
        title={lang?.CATEGORY_NEW}
        content={
          <>
            <CategoryForm
              lang={lang}
              isNew={true}
              categoryAttributes={null}
              submitForm={e => submitCategory(e)}
              cancelBtn={hideBtn}
            />
          </>
        }
      />
    </>
  );
};

export default NewCategory;
