import { FormEvent, useEffect, useState } from 'react';
import './styles.scss';
import { AppState } from '../../client-redux/reducers';
import { Language } from '../../languages/languageHandler';
import {
  fetchEidsInMovementList,
  fetchEventsInMovementList,
  fetchGlassesInMovementsList,
  fetchDonationsInMovementsList,
  IProcessedGlass,
  fetchFilteredMovements
} from '../../../libraries/processedGlass/index';
import { useSelector } from 'react-redux';
import { Form, Table, Row, Col, Button } from 'react-bootstrap';
import moment from 'moment';
import { IGlass } from '../../../libraries/glass/GlassService';
import { IDonation } from '../../../libraries/donation/DonationService';
import { IEvent } from '../../../libraries/events/EventService';
import { Page } from '../../../libraries/zps';
import PaginationComponent from '../pagination/PaginationComponent';

function Movements() {
  const lang = useSelector<AppState, Language | undefined>(state => state.lang.lang);

  const [statusCode, setStatusCode] = useState<number>();
  const [page, setPage] = useState<Page<IProcessedGlass>>();
  const [pageNumber, setPageNumber] = useState<number>(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pageSize, setPageSize] = useState<number>(10);
  const [selectedInitialDate, setSelectedInitialDate] = useState<string>(
    moment().startOf('day').format('YYYY-MM-DDThh:mm:ss')
  );
  const [selectedEndDate, setSelectedEndDate] = useState<string>(moment().format('YYYY-MM-DDThh:mm:ss'));
  const [eidList, setEidList] = useState<number[]>([]);
  const [eventList, setEventList] = useState<IEvent[]>([]);
  const [glassList, setGlassList] = useState<IGlass[]>([]);
  const [donationList, setDonationList] = useState<IDonation[]>([]);
  const [selectedEid, setSelectedEid] = useState<number | undefined>();
  const [selectedStatus, setSelectedStatus] = useState<string | undefined>();
  const [selectedEventId, setSelectedEventId] = useState<number | undefined>();
  const [selectedGlassId, setSelectedGlassId] = useState<string | undefined>();
  const [selectedDonationId, setSelectedDonationId] = useState<number | undefined>();

  const [movements, setMovements] = useState<IProcessedGlass[] | undefined>();

  const changePage = (n: number) => {
    setPageNumber(n);
  };

  const resultsSelect = (e: any) => {
    setPageSize(e.target.value);
  };

  useEffect(() => {
    fetchFilteredMovements(
      pageNumber,
      pageSize,
      selectedInitialDate,
      selectedEndDate,
      selectedEid,
      selectedStatus,
      selectedEventId,
      selectedGlassId,
      selectedDonationId
    ).then(response => {
      setStatusCode(response.data.statusOper.code);
      setPage(response.data.page);
      setMovements(response.data.page.content);
    });
  }, [
    pageNumber,
    pageSize,
    selectedDonationId,
    selectedEid,
    selectedEndDate,
    selectedEventId,
    selectedGlassId,
    selectedInitialDate,
    selectedStatus
  ]);

  useEffect(() => {
    fetchEidsInMovementList().then(response => {
      setEidList(response);
    });

    fetchEventsInMovementList().then(response => {
      setEventList(response);
    });

    fetchGlassesInMovementsList().then(response => {
      setGlassList(response);
    });

    fetchDonationsInMovementsList().then(response => {
      setDonationList(response);
    });
  }, []);

  const transaction = (code: any, entityName: any) => {
    if (code) return '1€';

    if (entityName) return entityName;
  };

  const submitSearch = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const { eid, status, eventId, glassCode, donationId, beginning, end } = e.target as typeof e.target & {
      eid: { value: number };
      status: { value: string };
      eventId: { value: number };
      glassCode: { value: string };
      donationId: { value: number };
      beginning: { value: string };
      end: { value: string };
    };

    eid.value ? setSelectedEid(eid.value) : setSelectedEid(undefined);

    status.value ? setSelectedStatus(status.value) : setSelectedStatus(undefined);

    eventId.value ? setSelectedEventId(eventId.value) : setSelectedEventId(undefined);

    glassCode.value ? setSelectedGlassId(glassCode.value) : setSelectedGlassId(undefined);

    donationId.value ? setSelectedDonationId(donationId.value) : setSelectedDonationId(undefined);

    if (beginning.value) {
      setSelectedInitialDate(beginning.value);
    }
    if (end.value) {
      setSelectedEndDate(end.value);
    }

    setPageNumber(0);
  };

  return (
    <div className="index-container">
      <div className="top-index-section">
        <div className="top-left">
          <h2>{lang?.MOVEMENTS}</h2>
        </div>
      </div>
      <div className="content-section">
        <div className="top-content-section">
          <div id="movements-search-section">
            <Form className="search-form" onSubmit={e => submitSearch(e)}>
              <Row>
                <Col lg={1}>
                  <Form.Select size="sm" name="eid">
                    <option key={-1} value="" className="selectPlaceholder">
                      {lang?.EQUIPMENT_ID}
                    </option>
                    {eidList.map((eid, index) => (
                      <option key={index} value={eid}>
                        {eid}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
                <Col lg={1}>
                  <Form.Select size="sm" name="status">
                    <option key={-1} value="" className="selectPlaceholder">
                      {lang?.PROCESSED_GLASS_STATUS}
                    </option>
                    <option value="SUCCESS">{lang?.SUCCESS}</option>
                    <option value="CANCELLED">{lang?.CANCELLED}</option>
                    <option value="ERROR">{lang?.ERROR}</option>
                  </Form.Select>
                </Col>
                <Col lg={2}>
                  <Form.Select size="sm" name="eventId">
                    <option key={-1} value="" className="selectPlaceholder">
                      {lang?.GLOBAL_EVENT}
                    </option>
                    {eventList.map((event, index) => (
                      <option key={index} value={event.eventId}>
                        {event.name}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
                <Col lg={1}>
                  <Form.Select size="sm" name="glassCode">
                    <option key={-1} value="" className="selectPlaceholder">
                      {lang?.GLASS}
                    </option>
                    {glassList.map((glass, index) => (
                      <option key={index} value={glass.glassCode}>
                        {glass.glassCode}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
                <Col lg={2}>
                  <Form.Select size="sm" name="donationId">
                    <option key={-1} value="" className="selectPlaceholder">
                      {lang?.DONATION_ENTITY}
                    </option>
                    {donationList.map((donation, index) => (
                      <option key={index} value={donation.donationId}>
                        {donation.name}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
                <Col>
                  <Form.Label>{lang?.EVENT_BEGIN_DATE_PLACEHOLDER}</Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    size="sm"
                    type="datetime-local"
                    name="beginning"
                    defaultValue={moment().startOf('day').format('YYYY-MM-DDT00:00:00')}
                  />
                </Col>
                <Col>
                  <Form.Label>{lang?.EVENT_END_DATE_PLACEHOLDER}</Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    size="sm"
                    type="datetime-local"
                    name="end"
                    defaultValue={moment().format('YYYY-MM-DDT23:59')}
                  />
                </Col>
                <Col>
                  <Button size="sm" type="submit">
                    {lang?.GLOBAL_SEARCH}
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
          <div className="results-section">
            <span>{lang?.GLOBAL_SHOWING} </span>
            <Form.Select className="results-select" size="sm" onChange={e => resultsSelect(e)}>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </Form.Select>
            <span> {lang?.GLOBAL_RESULTS}</span>
          </div>
        </div>
        <div className="index-table">
          <Table size="md" className="data-table">
            <thead className="table-head">
              <tr className="table-head-row">
                <th id="eidColumn">{lang?.EQUIPMENT_ID}</th>
                <th id="periodColumn">{lang?.PROCESSED_GLASS_PERIOD}</th>
                <th id="pgIdColumn">{lang?.PROCESSED_GLASS_ID}</th>
                <th id="dtColumn">{lang?.PROCESSED_GLASS_DATE_TIME}</th>
                <th id="statusColumn">{lang?.PROCESSED_GLASS_STATUS}</th>
                <th id="errorColumn">{lang?.PROCESSED_GLASS_ERROR}</th>
                <th id="eventColumn">{lang?.GLOBAL_EVENT}</th>
                <th id="glassCodeColumn">{lang?.GLASS}</th>
                <th id="refundTypeColumn">{lang?.PROCESSED_GLASS_REFUND_TYPE}</th>
                <th id="entityDonationColumn"></th>
                <th id="cycleColumn">{lang?.EQUIPMENT_CYCLE}</th>
                <th id="tubeColumn">{lang?.PROCESSED_GLASS_TUBE}</th>
              </tr>
            </thead>
            <tbody>
              {statusCode === 9004 && (
                <tr>
                  <td colSpan={12} style={{ textAlign: 'center' }}>
                    Sem resultados
                  </td>
                </tr>
              )}
              {movements?.map((movement, idx) => (
                <tr key={idx} className="table-row">
                  <td>{movement.id.eid}</td>
                  <td>{movement.id.period}</td>
                  <td>{movement.id.id}</td>
                  <td>{moment(movement.dateTime).format('DD/MM/YYYY HH:mm')}</td>
                  <td>{lang?.[movement.status]}</td>
                  <td>{lang?.[movement.error]}</td>
                  <td>{movement.event?.name}</td>
                  <td>{movement.glass?.glassCode}</td>
                  <td>{movement.refundType && lang?.[movement.refundType]}</td>
                  <td>{transaction(movement.code, movement.donation?.name)}</td>
                  <td>{movement.cycle}</td>
                  <td>{movement.tube}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div className="pagination-section">
            <PaginationComponent
              total={page?.totalElements}
              itemsPerPage={pageSize}
              currentPage={pageNumber}
              onPageChange={changePage}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
export default Movements;
