import React, { useState } from 'react';
import { useQuery, UseQueryResult } from 'react-query';
import EventService, { IEvent } from '../../../libraries/events/EventService';
import DetailsContent from './DetailsContent';
import UpdateContent from './UpdateContent';
import ModalComponent from '../modal/ModalComponent';
import './styles.scss';
import { Language } from '../../languages/languageHandler';
import { SingleRecordResponse } from '../../../libraries/zps';

interface ModalProps {
  eventId: number;
  isOpen: boolean;
  hideBtn: () => void;
  lang: Language;
}

const EventDetails = ({ isOpen, hideBtn, eventId, lang }: ModalProps) => {
  const { data }: UseQueryResult<SingleRecordResponse<IEvent>> = useQuery(['event', eventId], () =>
    EventService.getEventById(eventId)
  );

  const [showDetails, setShowDetails] = useState<boolean>(true);
  const [showUpdate, setShowUpdate] = useState<boolean>(false);

  function toggleUpdate() {
    setShowDetails(!showDetails);
    setShowUpdate(!showUpdate);
  }

  return (
    <>
      <ModalComponent
        show={isOpen}
        handleClick={hideBtn}
        animation={true}
        title={data?.record.name}
        content={
          <>
            {showDetails && data !== undefined && (
              <DetailsContent lang={lang} event={data.record} toggleUpdate={toggleUpdate} hideBtn={hideBtn} />
            )}
            {showUpdate && data !== undefined && (
              <UpdateContent lang={lang} event={data.record} toggleUpdate={toggleUpdate} />
            )}
          </>
        }
        size={'xl'}
      />
    </>
  );
};

export default EventDetails;
