import moment from 'moment';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { ITransaction } from '../../../libraries/transactions';
import { Language } from '../../languages/languageHandler';
import { capitalizeWords, formatAmount2 } from '../../utils/script';
import ModalComponent from '../modal/ModalComponent';

interface ShowTransactionProps {
  transaction: ITransaction;
  openDetails: boolean;
  hideBtn: () => void;
  lang: Language;
}

const ShowTransaction = ({ transaction, openDetails, hideBtn, lang }: ShowTransactionProps) => {
  return (
    <>
      <ModalComponent
        show={openDetails}
        handleClick={hideBtn}
        animation={true}
        title={lang.TRANSACTION_DETAILS}
        size={'lg'}
        content={
          <>
            <Container>
              <Row>
                <Col>
                  <Row>Delegação</Row>
                  <Row>{transaction.entid}</Row>
                </Col>
                <Col>
                  <Row>{lang.EQUIPMENT_ID}</Row>
                  <Row>{transaction.eid}</Row>
                </Col>
                <Col>
                  <Row>{lang.PROCESSED_GLASS_PERIOD}</Row>
                  <Row>{transaction.period}</Row>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Row>{lang.ALER_ERRORCODE}</Row>
                  <Row>{transaction.code}</Row>
                </Col>
                <Col>
                  <Row>{lang.PROCESSED_GLASS_DATE_TIME}</Row>
                  <Row>{moment(transaction.dateTime).format('DD-MM-YYYY HH:mm:ss')}</Row>
                </Col>
                <Col>
                  <Row>{lang.TRANSACTION_AMOUNT}</Row>
                  <Row>{formatAmount2(transaction.value)}</Row>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Row>{lang.TRANSACTION_RECEIVED}</Row>
                  <Row>{formatAmount2(transaction.valueRec)}</Row>
                </Col>
                <Col>
                  <Row>{lang.TRANSACTION_DISPENSED}</Row>
                  <Row>{formatAmount2(transaction.valueDis)}</Row>
                </Col>
                <Col>
                  <Row>{lang.TRANSACTION_PAYMENT_METHOD}</Row>
                  <Row>{capitalizeWords(transaction.valueType)}</Row>
                </Col>
              </Row>
              <Row md={1}>
                <Col>{lang.TRANSACTION_TYPE}</Col>
                <Col>{transaction.type}</Col>
              </Row>
            </Container>
          </>
        }
      />
    </>
  );
};

export default ShowTransaction;
