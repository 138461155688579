import moment from 'moment';
import React, { useState, useEffect, FormEvent } from 'react';
import { Button, Col, Container, Form, Row, Table } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import { useSelector } from 'react-redux';
import { fetchBankTransactionList, IBankTransactionResponse } from '../../../libraries/bankTransactions';
import { AppState } from '../../client-redux/reducers';
import { Language } from '../../languages/languageHandler';
import Header from '../ui/Header';

const BankTransactions = () => {
  const lang = useSelector<AppState, Language | undefined>(state => state.lang.lang);

  const [beginDate, setBeginDate] = useState<string>(moment().startOf('day').format('YYYY-MM-DDT00:00:00.000'));
  const [endDate, setEndDate] = useState<string>(moment().format('YYYY-MM-DDT23:59:59.000'));
  const [entid, setEntid] = useState<number>(0);
  const [sid, setSid] = useState<number>(0);

  const [message, setMessage] = useState<IBankTransactionResponse>();
  const [noResults, setNoResults] = useState<boolean>(false);

  useEffect(() => {
    fetchBankTransactionList(`${beginDate}Z`, `${endDate}Z`, entid, sid).then(response => {
      if (response.statusOper.code === 0) {
        setNoResults(false);
        setMessage(response);
      } else {
        setNoResults(true);
      }
    });
  }, [beginDate, endDate, entid, sid]);

  const getBankTransactionList = () => {
    fetchBankTransactionList(`${beginDate}Z`, `${endDate}Z`, entid, sid).then(response => {
      if (response.statusOper.code === 0) {
        setNoResults(false);
        setMessage(response);
      } else {
        setNoResults(true);
      }
    });
  };

  const submitSearch = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    getBankTransactionList();
  };

  const displayResults = () => {
    if (noResults) {
      return (
        <>
          <tr>
            <td colSpan={16} style={{ textAlign: 'center' }}>
              Sem resultados
            </td>
          </tr>
        </>
      );
    }
    if (message) {
      return (
        <>
          {message.transactions.map((transaction, index) => (
            <tr key={index}>
              <td>{transaction.entid}</td>
              <td>{transaction.eid}</td>
              <td>{transaction.period}</td>
              <td>{transaction.code}</td>
              <td>{transaction.refID?.code}</td>
              <td>{transaction.depositor}</td>
              <td>{transaction.account}</td>
              <td>{transaction.dateTime}</td>
              <td>{transaction.type}</td>
              <td>{transaction.value}</td>
              <td>{transaction.envTotals}</td>
              <td>{transaction.status}</td>
              <td>{}</td>
              <td>{transaction.hostTransactionID}</td>
              <td>
                <Icon.FilePdf />
              </td>
            </tr>
          ))}
        </>
      );
    }
  };

  return (
    <Container fluid>
      <Header pageTitle={lang?.BANK_TRANSACTIONS} />
      <Form onSubmit={e => submitSearch(e)}>
        <Row className="mt-2 align-items-center">
          <Col lg="auto" md={2} sm="auto" xs={12}>
            <Form.Label className="equipmentSearchLabel">Entidade</Form.Label>
          </Col>
          <Col lg="auto" md={4} sm="auto" xs={12}>
            <Form.Select size="sm" name="entid" onChange={e => setEntid(Number(e.target.value))}>
              <option value={9999}>Zarph Test</option>
            </Form.Select>
          </Col>
          <Col lg="auto" md={2} sm="auto" xs={12} className="mt-2 mt-sm-0 ml-auto">
            <Form.Label className="equipmentSearchLabel">Delegação</Form.Label>
          </Col>
          <Col lg="auto" md={4} sm="auto" xs={12}>
            <Form.Select size="sm" name="sid" onChange={e => setSid(Number(e.target.value))}>
              <option value={0}>Todas</option>
              <option value={1}>HQ</option>
              <option value={2}>Demo 1</option>
              <option value={10}>Odivelas</option>
            </Form.Select>
          </Col>
          <Col lg="auto" md={2} sm="auto" xs={12} className="mt-2 mt-sm-0 ml-auto">
            <Form.Label className="equipmentSearchLabel">{lang?.EVENT_BEGIN_DATE_PLACEHOLDER}</Form.Label>
          </Col>
          <Col lg="auto" md={4} sm="auto" xs={12} className="mt-md-2">
            <Form.Control
              size="sm"
              type="datetime-local"
              name="beginning"
              defaultValue={moment().startOf('day').format('YYYY-MM-DDT00:00:00')}
              onChange={e => setBeginDate(`${e.target.value}:00.000`)}
            />
          </Col>
          <Col lg="auto" md={2} sm="auto" xs={12} className="mt-2 mt-md-2 mt-sm-0 ml-auto">
            <Form.Label className="equipmentSearchLabel">{lang?.EVENT_END_DATE_PLACEHOLDER}</Form.Label>
          </Col>
          <Col lg="auto" md={4} sm="auto" xs={12} className="mt-md-2 ml-auto">
            <Form.Control
              size="sm"
              type="datetime-local"
              name="end"
              defaultValue={moment().format('YYYY-MM-DDT23:59')}
              onChange={e => setEndDate(`${e.target.value}:00.000`)}
            />
          </Col>
          <Col lg="auto" xs={12} md="auto" className="mt-4 mt-md-2 mt-sm-0 ml-auto">
            <Button type="submit" className="w-100" size="sm">
              {lang?.GLOBAL_SEARCH}
            </Button>
          </Col>
        </Row>
      </Form>
      <Row className="mt-e">
        <Col>
          <Table responsive striped hover size="md" className="data-table">
            <thead className="table-head">
              <tr className="table-head-row">
                <th className="enterEquipPeriod-column">Delegação</th>
                <th className="enterEquipPeriod-column">{lang?.EQUIPMENT_ID}</th>
                <th className="enterEquipPeriod-column">{lang?.PROCESSED_GLASS_PERIOD}</th>
                <th className="enterEquipPeriod-column">Código</th>
                <th className="enterEquipPeriod-column">M.D.</th>
                <th className="enterEquipPeriod-column">Depositante</th>
                <th className="enterEquipPeriod-column">Conta</th>
                <th className="enterEquipPeriod-column">{`${lang?.PROCESSED_GLASS_DATE_TIME}`}</th>
                <th className="enterEquipPeriod-column">Tipo</th>
                <th className="enterEquipPeriod-column">Montante</th>
                <th className="enterEquipPeriod-column">Total</th>
                <th className="enterEquipPeriod-column">Estado</th>
                <th className="enterEquipPeriod-column">Cofre</th>
                <th className="enterEquipPeriod-column">ID Externo</th>
                <th className="enterEquipPeriod-column"></th>
              </tr>
            </thead>
            <tbody>{displayResults()}</tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};

export default BankTransactions;
